const actions = {
  DISABLED_PAYMENTMETHOD: 'paymentmethods/DISABLED',
  CLOSED_PAYMENTMETHOD: 'paymentmethods/CLOSED',
  LOADING_PAYMENTMETHODS: 'paymentmethods/LOADING_PAYMENTMETHODS',
  LOADING_PAYMENTMETHOD: 'paymentmethods/LOADING_PAYMENTMETHOD',
  CLEAN_ALL_PAYMENTMETHOD: 'paymentmethods/CLEAN_ALL',
  CLEAN_PAYMENTMETHODS: 'paymentmethods/CLEAN_PAYMENTMETHODS',
  CLEAN_PAYMENTMETHOD: 'paymentmethods/CLEAN_PAYMENTMETHOD',
  GET_ALL_PAYMENTMETHOD: 'paymentmethods/GET_ALL',
  FETCH_ALL_PAYMENTMETHOD: 'paymentmethods/FETCH_ALL',
  GET_PAYMENTMETHOD: 'paymentmethods/GET',
  FETCH_PAYMENTMETHOD: 'paymentmethods/FETCH',
  CREATE_PAYMENTMETHOD: 'paymentmethods/CREATE',
  CHANGE_PAYMENTMETHOD_PRINCIPAL: 'paymentmethods/CHANGE_PRINCIPAL',
  DELETE_PAYMENTMETHOD: 'paymentmethods/DELETE_PAYMENTMETHOD',
};

export default actions;
