const actions = {
  DISABLED_APIKEY: 'apikey/DISABLED',
  CLOSED_APIKEY: 'apikey/CLOSED',
  LOADING_APIKEYS: 'apikey/LOADING_APIKEYS',
  LOADING_APIKEY: 'apikey/LOADING_APIKEY',
  CLEAN_ALL_APIKEY: 'apikey/CLEAN_ALL',
  CLEAN_APIKEYS: 'apikey/CLEAN_APIKEYS',
  CLEAN_APIKEY: 'apikey/CLEAN_APIKEY',
  GET_ALL_APIKEY: 'apikey/GET_ALL',
  FETCH_ALL_APIKEY: 'apikey/FETCH_ALL',
  GET_APIKEY: 'apikey/GET',
  FETCH_APIKEY: 'apikey/FETCH',
  CREATE_APIKEY: 'apikey/CREATE',
  UPDATE_APIKEY: 'apikey/UPDATE',
  DELETE_APIKEY: 'apikey/DELETE',
};

export default actions;
