import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';

const users = new Crud('user');

export function* GET_ALL_USER() {
  yield put({ type: actions.LOADING_USERS });
  const info = yield users.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_USER,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_USER({ id }) {
  yield put({ type: actions.LOADING_USER });
  const info = yield users.Get(`one/${id}`);
  if (info) {
    yield put({
      type: actions.GET_USER,
      payload: info,
      loading: false,
    });
  }
}

export function* CREATE_USER({ data }) {
  yield put({ type: actions.CLOSED_USER, payload: false });
  yield put({ type: actions.DISABLED_USER, payload: true });
  const info = yield users.Post(data);
  yield put({ type: actions.DISABLED_USER, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_USER, payload: true });
    yield GET_ALL_USER();
  }
}

export function* UPDATE_USER({ data, user }) {
  yield put({ type: actions.CLOSED_USER, payload: false });
  yield put({ type: actions.DISABLED_USER, payload: true });
  const info = yield users.Put(data);
  yield put({ type: actions.DISABLED_USER, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_USER, payload: true });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...user,
        db: (info?.data || user?.db),
      },
    });
  }
}

export function* DELETE_USER({ id }) {
  yield users.Delete(id);
  yield GET_ALL_USER();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_USER, GET_ALL_USER),
    takeEvery(actions.FETCH_USER, GET_USER),
    takeEvery(actions.CREATE_USER, CREATE_USER),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.DELETE_USER, DELETE_USER),
  ]);
}
