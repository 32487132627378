import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import JSZip from 'jszip';
import { save } from 'save-file';
import { notification } from 'antd';
import {
  all, put, select, takeEvery,
} from 'redux-saga/effects';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from './actions';
import Crud from '../../services/fetch';
import { getLocale } from '../../helpers';
import { nomadaUtilDomain } from '../../helpers/env';
import downloadXLS from '../../helpers/xls';

import { getLocalInvoiceFilterFormat } from '../../views/billing/Invoice/helpers';

import StampInvoice from '../../genericComponents/CustomAnimations/StampInvoice';
import Loading from '../../genericComponents/CustomAnimations/Loading';
import CancelInvoice from '../../genericComponents/CustomAnimations/CancelInvoice';
import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';
import SendMailItem from '../../genericComponents/CustomAnimations/SendMailItem';
import CustomErrorMails from '../../genericComponents/CustomErrorMails';

const pdf = new Crud('public', false, nomadaUtilDomain);
const invoice = new Crud('invoice');
const draftInvoice = new Crud('invoice/draft');
const stampInvoice = new Crud('invoice/stamp');
const cancelInvoice = new Crud('invoice/cancel');
const ppdInvoice = new Crud('invoice/ppd');

const stamping = { content: <StampInvoice text="billing.stamp.waiting" />, footer: null };
const payment = { content: <Loading text="billing.payment.waiting" />, footer: null };
const genericLoading = { content: <Loading text="generic.loading" />, footer: null };
const cloneInvoice = { content: <StampInvoice text="invoice.clone.waiting" />, footer: null };
const downloadPDF = { content: <StampInvoice text="billing.generate.pdf" />, footer: null };
const downloadZIP = { content: <StampInvoice text="billing.generate.zip" />, footer: null };
const cancelStamp = { content: <CancelInvoice text="billing.stamp.cancel.waiting" />, footer: null };
const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };
const sendmailWaiting = { content: <SendMailItem text="message.sendmail.waiting" />, footer: null };

const logEventInvoice = sendLogEvent(logEvent, analytics);

export function* GET_ALL_INVOICE({ filter }) {
  draftInvoice.Cancel();
  draftInvoice.SetCancelToken();
  yield put({ type: actions.CLEAN_ALL_INVOICE });
  yield put({ type: actions.LOADING_INVOICES, payload: true });
  const info = yield draftInvoice.PostUrl('all', filter);
  if (!info) {
    yield put({ type: actions.LOADING_INVOICES, payload: false });
  } else {
    const { error } = info;
    if (error !== 'AbortError') {
      yield put({
        type: actions.GET_ALL_INVOICE,
        payload: info,
        loading: false,
      });
    }
  }
}

export function* GET_INVOICE({ id, querys }) {
  yield put({ type: actions.CLEAN_ALL_INVOICE });
  yield put({ type: actions.LOADING_INVOICE, payload: true });
  let query = querys.get('type');
  // console.log({ query });
  query = query ? `?type=${query}` : '';
  const info = yield invoice.Get(`one/${id}${query}`);
  if (info?.data) {
    yield put({
      type: actions.GET_INVOICE,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_INVOICE, payload: false });
  }
}

export function* GET_ALL_INVOICE_STAMP({ filter }) {
  stampInvoice.Cancel();
  stampInvoice.SetCancelToken();
  yield put({ type: actions.CLEAN_ALL_INVOICE });
  yield put({ type: actions.LOADING_INVOICES_STAMP, payload: true });
  const info = yield stampInvoice.PostUrl('all', filter);
  if (!info) {
    yield put({ type: actions.LOADING_INVOICES_CANCEL, payload: false });
  } else {
    const { error } = info;
    if (error !== 'AbortError') {
      yield put({
        type: actions.GET_ALL_INVOICE_STAMP,
        payload: info,
        loading: false,
      });
    }
  }
}

export function* GET_ALL_INVOICE_CANCEL({ filter }) {
  cancelInvoice.Cancel();
  cancelInvoice.SetCancelToken();
  yield put({ type: actions.CLEAN_ALL_INVOICE });
  yield put({ type: actions.LOADING_INVOICES_CANCEL, payload: true });
  const info = yield cancelInvoice.PostUrl('all', filter);
  if (!info) {
    yield put({ type: actions.LOADING_INVOICES_CANCEL, payload: false });
  } else {
    const { error } = info;
    if (error !== 'AbortError') {
      yield put({
        type: actions.GET_ALL_INVOICE_CANCEL,
        payload: info,
        loading: false,
      });
    }
  }
}

export function* GET_ALL_INVOICE_PPD({ filter }) {
  ppdInvoice.Cancel();
  ppdInvoice.SetCancelToken();
  yield put({ type: actions.CLEAN_ALL_INVOICE });
  yield put({ type: actions.LOADING_INVOICES_PPD, payload: true });
  const info = yield ppdInvoice.PostUrl('all', filter);
  if (!info) {
    yield put({ type: actions.LOADING_INVOICES_CANCEL, payload: false });
  } else {
    const { error } = info;
    if (error !== 'AbortError') {
      yield put({
        type: actions.GET_ALL_INVOICE_PPD,
        payload: info,
        loading: false,
      });
    }
  }
}

export function* GET_ALL_INVOICE_PPD_COMPLEMENTS({ filter }) {
  ppdInvoice.Cancel();
  ppdInvoice.SetCancelToken();
  yield put({ type: actions.CLEAN_ALL_INVOICE });
  yield put({ type: actions.LOADING_INVOICES_COMPLEMENTS, payload: true });
  const info = yield ppdInvoice.PostUrl('complements/all', filter);
  if (!info) {
    yield put({ type: actions.LOADING_INVOICES_COMPLEMENTS, payload: false });
  } else {
    const { error } = info;
    if (error !== 'AbortError') {
      yield put({
        type: actions.GET_ALL_INVOICE_PPD_COMPLEMENTS,
        payload: info,
        loading: false,
      });
    }
  }
}

function getStampUrl(id) {
  return `version/${id}`;
}

export function* CREATE_INVOICE({
  data, timbrar, txtSuccess, txtStampSuccess, txtInitStamp, callback, fromCancel, modal, invoiceType,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  const info = yield invoice.Post(data);
  const id = _.get(info, 'data.id', null);
  if (info) {
    logEventInvoice('create_invoice', {
      ...analyticEventParams,
      invoiceID: id,
    });
    if (!timbrar) {
      if (callback) callback();
      const filter = getLocalInvoiceFilterFormat();
      yield GET_ALL_INVOICE({ filter });
    }
    if (txtSuccess) notification.success({ message: txtSuccess });
    if (txtInitStamp && timbrar && id) notification.info({ message: txtInitStamp });
  }
  if (info && timbrar && id) {
    if (modal) modal.confirm(stamping);
    const stamp = yield stampInvoice.PostUrl(getStampUrl(id));
    if (modal) modal.destroy();
    if (txtStampSuccess) notification.success({ message: txtStampSuccess });
    if (callback) callback(id, invoiceType);
    // else yield GET_ALL_INVOICE();
    if (stamp) {
      console.log(stamp);
      logEventInvoice('invoice_create_stamp', {
        ...analyticEventParams,
        invoiceID: id,
      });
    }
  }
  if (fromCancel) fromCancel(id);
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* UPDATE_INVOICE({
  data, timbrar, txtSuccess, txtStampSuccess, txtInitStamp, callback, modal, invoiceType,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  const info = yield invoice.Put(data);
  if (info) {
    logEventInvoice('update_invoice', {
      ...analyticEventParams,
      invoiceID: data?.id,
    });
    if (!timbrar) {
      if (callback) callback();
      const filter = getLocalInvoiceFilterFormat();
      yield GET_ALL_INVOICE({ filter });
    }
    if (txtSuccess) notification.success({ message: txtSuccess });
    if (txtInitStamp && timbrar) notification.info({ message: txtInitStamp });
  }
  if (info && timbrar) {
    if (modal) modal.confirm(stamping);
    const stamp = yield stampInvoice.PostUrl(getStampUrl(data.id));
    if (modal) modal.destroy();
    if (txtStampSuccess) notification.success({ message: txtStampSuccess });
    if (callback) callback(data.id, invoiceType);
    // else yield GET_ALL_INVOICE();
    if (stamp) {
      console.log(stamp);
      logEventInvoice('update_stamp_invoice', {
        ...analyticEventParams,
        invoiceID: data?.id,
      });
    }
  }
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* DELETE_INVOICE({
  id, modal, ok, isDraft,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(deleteWaiting);
  const del = yield invoice.Delete(id);
  if (modal) modal.destroy();
  if (del) {
    logEventInvoice('delete_invoice', {
      ...analyticEventParams,
      invoiceID: id,
    });
    const filter = getLocalInvoiceFilterFormat();
    const {
      rankOfDate, limit, page, ...restFilter
    } = filter;
    const newFilter = isDraft ? { ...restFilter } : filter;
    yield GET_ALL_INVOICE({ filter: newFilter });
  }
  if (ok) ok();
}

export function* CANCEL_INVOICE({
  id, callback, modal, ok,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(cancelStamp);
  const resp = yield cancelInvoice.PostUrl(id);
  if (resp) {
    logEventInvoice('cancel_invoice', {
      ...analyticEventParams,
      invoiceID: id,
    });
  }
  if (modal) modal.destroy();
  if (callback) callback();
  const filter = getLocalInvoiceFilterFormat();
  yield GET_ALL_INVOICE_STAMP({ filter });
  if (ok) ok();
}

export function* CANCEL_INVOICE_PPD({
  id, callback, modal, ok, paymentComplement,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(cancelStamp);
  const resp = yield ppdInvoice.PostUrl(`cancel/${id}`);
  if (resp) {
    logEventInvoice('cancel_ppd', {
      ...analyticEventParams,
      invoicePPD: id,
    });
  }
  if (modal) modal.destroy();
  if (callback) callback();
  const filter = getLocalInvoiceFilterFormat();
  if (paymentComplement) yield GET_ALL_INVOICE_PPD_COMPLEMENTS({ filter });
  else yield yield GET_ALL_INVOICE_PPD({ filter });
  if (ok) ok();
}

export function* MOTIVE_CANCEL_INVOICE({
  id, data, ok, modal, paymentComplement,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_INVOICE_CANCEL, payload: true });
  if (modal) modal.confirm(cancelStamp);
  const resp = yield invoice.PostUrl(`motive-cancel/${id}`, data);
  if (resp) {
    logEventInvoice('cancel_invoice_motive', {
      ...analyticEventParams,
      invoiceID: id,
    });
  }
  const filter = getLocalInvoiceFilterFormat();
  yield put({ type: actions.DISABLED_INVOICE_CANCEL, payload: false });
  if (ok) ok();
  if (modal) modal.destroy();
  if (paymentComplement) yield GET_ALL_INVOICE_PPD_COMPLEMENTS({ filter });
  else yield GET_ALL_INVOICE_STAMP({ filter });
}

export function* MOTIVE_CANCEL_INVOICE_PPD({
  id, data, ok, modal, paymentComplement,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_INVOICE_CANCEL, payload: true });
  if (modal) modal.confirm(cancelStamp);
  const resp = yield ppdInvoice.PostUrl(`motive-cancel/${id}`, data);
  if (resp) {
    logEventInvoice('cancel_ppd_motive', {
      ...analyticEventParams,
      invoicePPD: id,
    });
  }
  const filter = getLocalInvoiceFilterFormat();
  yield put({ type: actions.DISABLED_INVOICE_CANCEL, payload: false });
  if (ok) ok();
  if (modal) modal.destroy();
  if (paymentComplement) yield GET_ALL_INVOICE_PPD_COMPLEMENTS({ filter });
  else yield GET_ALL_INVOICE_PPD({ filter });
}

function getStampPagosUrl() {
  return 'new/version';
}

function getManyStampPagosUrl() {
  return 'new/many/version';
}

export function* CREATE_MANY_INVOICE_PPD({
  data, modal, callback,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(stamping);
  const info = yield ppdInvoice.PostUrl(getManyStampPagosUrl(), data);
  if (info) {
    logEventInvoice('create_ppd_many_stamp', {
      ...analyticEventParams,
      invoicePPD: info?.id,
    });
  }
  if (modal) modal.destroy();
  if (callback) callback(info);
}

export function* CREATE_INVOICE_PPD({
  data, modal, callback, isPUE, isStamp, paymentComplement,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  if (modal) modal.confirm(isPUE ? payment : stamping);
  const info = yield ppdInvoice.PostUrl(getStampPagosUrl(), data);
  if (modal) modal.destroy();
  if (callback && info?.data) {
    logEventInvoice('create_ppd_stamp', {
      ...analyticEventParams,
      invoicePPD: info?.id,
    });
    const { doctoRelacionado = {}, fechaPago, ...rest } = (info.data || {});
    callback({
      ...rest,
      ...doctoRelacionado,
      fechaPago: moment(fechaPago),
    });
  }
  const filter = getLocalInvoiceFilterFormat();
  if (paymentComplement) yield GET_ALL_INVOICE_PPD_COMPLEMENTS({ filter });
  else if (isStamp) yield GET_ALL_INVOICE_STAMP({ filter });
  else yield GET_ALL_INVOICE_PPD({ filter });
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* UPDATE_INVOICE_PPD({
  data, modal, callback, isPUE, isStamp, paymentComplement,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  if (modal) modal.confirm(isPUE ? payment : stamping);
  const info = yield ppdInvoice.PutUrl(getStampPagosUrl(), data);
  if (modal) modal.destroy();
  if (callback && info?.data) {
    logEventInvoice('update_ppd_stamp', {
      ...analyticEventParams,
      invoicePPD: info?.id,
    });
    const { doctoRelacionado = {}, fechaPago, ...rest } = (info.data || {});
    callback({
      ...rest,
      ...doctoRelacionado,
      fechaPago: moment(fechaPago),
    });
  }
  const filter = getLocalInvoiceFilterFormat();
  if (paymentComplement) yield GET_ALL_INVOICE_PPD_COMPLEMENTS({ filter });
  else if (isStamp) yield GET_ALL_INVOICE_STAMP({ filter });
  else yield GET_ALL_INVOICE_PPD({ filter });
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* DELETE_INVOICE_PPD({
  id, modal, ok, isStamp, paymentComplement,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(deleteWaiting);
  const del = yield ppdInvoice.Delete(id);
  if (modal) modal.destroy();
  if (del) {
    logEventInvoice('delete_ppd', {
      ...analyticEventParams,
      invoicePPD: id,
    });
    const filter = getLocalInvoiceFilterFormat();
    if (paymentComplement) yield GET_ALL_INVOICE_PPD_COMPLEMENTS({ filter });
    else if (isStamp) yield GET_ALL_INVOICE_STAMP({ filter });
    else yield GET_ALL_INVOICE_PPD({ filter });
  }
  if (ok) ok();
}

export function* JOIN_INVOICE({
  data, callback, modal, ok, filter = {},
}) {
  if (modal) modal.confirm(genericLoading);
  const join = yield invoice.PostUrl('join', {
    ids: data,
    ...filter,
  });
  if (modal) modal.destroy();
  if (join) {
    if (callback) callback();
    const filterInvoice = getLocalInvoiceFilterFormat();
    yield GET_ALL_INVOICE({ filter: filterInvoice });
  }
  if (ok) ok();
}

export function* SPLIT_INVOICE({
  id, callback, modal, ok,
}) {
  if (modal) modal.confirm(genericLoading);
  const split = yield invoice.PostUrl(`split/${id}`);
  if (modal) modal.destroy();
  if (split) {
    if (callback) callback();
    const filter = getLocalInvoiceFilterFormat();
    yield GET_ALL_INVOICE({ filter });
  }
  if (ok) ok();
}

export function* GET_STAMP_PDF({
  fileName, data, modal,
}) {
  if (modal) modal.confirm(downloadPDF);
  const buffer = yield pdf.PostBuffer('html-to-pdf', data);
  if (modal) modal.destroy();
  save(buffer, `${fileName}.pdf`);
}

export function* GET_STAMP_ZIP({
  fileName, data, modal, xml,
}) {
  if (modal) modal.confirm(downloadZIP);
  const zip = new JSZip();
  const folder = zip.folder(fileName);
  folder.file(`${fileName}.xml`, xml);
  const buffer = yield pdf.PostBuffer('html-to-pdf', data);
  folder.file(`${fileName}.pdf`, buffer);
  const generate = yield zip.generateAsync({ type: 'blob' });
  save(generate, `${fileName}.zip`);
  if (modal) modal.destroy();
}

export function* GET_MASIVE_STAMP_ZIP({
  isPPD, modal, fileName,
}) {
  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  if (modal) modal.confirm(downloadZIP);
  const filter = getLocalInvoiceFilterFormat();
  const info = yield invoice.PostUrl('download-xml', {
    ...(filter || {}),
    ppd: isPPD || undefined,
    stamp: !isPPD || undefined,
  });
  if (Array.isArray(info?.data) && info?.data?.length > 0) {
    const zip = new JSZip();
    const folder = zip.folder();
    (info?.data || []).forEach((inv) => {
      const {
        receiver, issuer, stamp, pagos,
      } = inv;
      const fileFolder = `${issuer.taxId}/${receiver.taxId}`;
      folder.file(`${fileFolder}/${stamp?.uuid}.xml`, stamp?.xml);
      if (pagos && Array.isArray(pagos) && pagos.length > 0) {
        pagos.forEach((pago) => {
          const { stamp: pStamp } = pago;
          if (pStamp) {
            folder.file(
              `${fileFolder}/${stamp?.uuid}/${pStamp?.uuid}.xml`,
              pStamp?.xml,
            );
          }
        });
      }
    });
    const generate = yield zip.generateAsync({ type: 'blob' });
    save(generate, `${fileName}.zip`);
  } else {
    notification.warn({ message: getLocale('zip.message.error') });
  }
  if (modal) modal.destroy();
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* GET_MASIVE_STAMP_ZIPXMLPDF({
  isPPD, modal, fileName,
}) {
  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  if (modal) modal.confirm(downloadZIP);
  const filter = getLocalInvoiceFilterFormat();
  const info = yield invoice.PostUrl('download-xml', {
    ...(filter || {}),
    ppd: isPPD || undefined,
    stamp: !isPPD || undefined,
  });
  if (Array.isArray(info?.data) && info?.data?.length > 0) {
    const zip = new JSZip();
    const folder = zip.folder();
    // eslint-disable-next-line no-restricted-syntax
    for (const inv of info?.data) {
      const {
        receiver, issuer, stamp, pagos, id,
      } = inv;

      const fileFolder = `${issuer.taxId}/${receiver.taxId}`;
      folder.file(`${fileFolder}/${stamp?.uuid}.xml`, stamp?.xml);

      const buffer = yield pdf.PostBuffer('html-to-pdf', {
        url: `${global.domain}/html/invoice/${id}`,
      });
      folder.file(`${fileFolder}/${stamp?.uuid}.pdf`, buffer);

      if (pagos && Array.isArray(pagos) && pagos.length > 0) {
        const promisesPagos = pagos.map(pago => new Promise(async (resPago) => {
          const { stamp: pStamp, id: idPago } = pago;
          if (pStamp) {
            folder.file(
              `${fileFolder}/${stamp?.uuid}/${pStamp?.uuid}.xml`,
                pStamp?.xml,
            );

            const bufferPago = await pdf.PostBuffer('html-to-pdf', {
              url: `${global.domain}/html/pagos/${idPago}`,
            });
            folder.file(`${fileFolder}/${stamp?.uuid}/${pStamp?.uuid}.pdf`, bufferPago);
          }
          resPago(true);
        }));
        yield Promise.allSettled(promisesPagos);
      }
    }

    const generate = yield zip.generateAsync({ type: 'blob' });
    save(generate, `${fileName}.zip`);
  } else {
    notification.warn({ message: getLocale('zip.message.error') });
  }
  if (modal) modal.destroy();
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* CLONE_INVOICE({
  data, modal, to, ok,
}) {
  if (modal) modal.confirm(cloneInvoice);
  const info = yield invoice.PostUrl('clone', data);
  const newID = _.get(info, 'data', null);
  if (modal) modal.destroy();
  if (info) {
    const filter = getLocalInvoiceFilterFormat();
    GET_ALL_INVOICE({ filter });
  }
  if (to && newID) to(newID);
  if (ok) ok();
}

export function* SEND_INVOICE({
  data, url, modal, ok,
}) {
  if (modal) modal.confirm(sendmailWaiting);
  const info = yield invoice.PostUrl(url, { mails: data });
  if (info && modal) {
    modal.confirm({
      content: <CustomErrorMails info={info?.data || {}} />,
    });
  } else if (modal) modal.destroy();
  if (ok) ok();
}

export function* DOWNLOAD_REPORT_PPD({
  modal, filter, ok,
}) {
  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  if (modal) modal.confirm(downloadZIP);
  const info = yield ppdInvoice.PostUrl('report', filter);
  if (modal) modal.destroy();
  if (info?.data) {
    const columns = [
      { header: 'Fecha pago', key: 'fechaPago', width: 50 },
      { header: 'Folio', key: 'batch', width: 50 },
      { header: 'RFC emisor', key: 'rfcEmisor', width: 50 },
      { header: 'Emisor', key: 'emisor', width: 50 },
      { header: 'Monto pago', key: 'montoPago', width: 50 },
      { header: 'UUID', key: 'uuid', width: 50 },
      { header: 'Saldo anterior', key: 'impSaldoAnt', width: 50 },
      { header: 'Pagado', key: 'impPagado', width: 50 },
      { header: 'Saldo insoluto', key: 'impSaldoInsoluto', width: 50 },
      { header: 'UUID DR', key: 'idDocumento', width: 50 },
      { header: 'Fecha timbrado', key: 'fechaTimbrado', width: 50 },
      { header: 'DR folio', key: 'folio', width: 50 },
      { header: 'RFC receptor', key: 'rfcReceptor', width: 50 },
      { header: 'Receptor', key: 'receptor', width: 50 },
      { header: 'Forma de pago', key: 'formaDePago', width: 50 },
      { header: 'Cancelada', key: 'cancelada', width: 50 },
      { header: 'CtaOrdenante', key: 'ctaOrdenante', width: 50 },
      { header: 'BancoCtaOrdenante', key: 'bancoCtaOrdenante', width: 50 },
      { header: 'CtaBeneficiario', key: 'ctaBeneficiario', width: 50 },
      { header: 'BancoCtaBeneficiario', key: 'bancoCtaBeneficiario', width: 50 },
    ];
    const newData = (info?.data || []).reduce((acc, rep) => {
      const { doctoRelacionado, ...rest } = rep;
      const newValues = { ...rest, ...doctoRelacionado };
      return [
        ...acc,
        Object.keys(newValues).reduce((acck, key) => ({
          ...acck,
          [key]: newValues[key],
        }), {}),
      ];
    }, []);
    downloadXLS({
      columns,
      data: newData,
      worksheetName: 'Reporte PPD',
      fileName: `reporte-ppd-${moment().format('LL')}`,
    });
  }
  if (ok) ok();
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* DOWNLOAD_REPORT_CANCELED({
  modal, filter, ok,
}) {
  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  if (modal) modal.confirm(downloadZIP);
  const info = yield cancelInvoice.PostUrl('report/excel', filter);
  if (modal) modal.destroy();
  if (info?.data) {
    const columns = [
      { header: 'Emisor', key: 'issuer', width: 100 },
      { header: 'Nombre del cliente', key: 'receiver', width: 100 },
      { header: 'Fecha cancelación', key: 'cancelDate', width: 50 },
      { header: 'Folio', key: 'folio', width: 50 },
      { header: 'Tipo de factura', key: 'invoiceType', width: 50 },
      { header: 'Método de pago', key: 'paymentMethod', width: 50 },
      { header: 'UUID', key: 'uuid', width: 100 },
      { header: 'Importe', key: 'monto', width: 100 },
      { header: 'Fecha factura', key: 'stampDate', width: 100 },
      { header: 'Motivo cancelación', key: 'motive', width: 100 },
    ];
    const newData = (info?.data || []).reduce((acc, rep) => ([
      ...acc,
      Object.keys(rep).reduce((acck, key) => ({
        ...acck,
        [key]: rep[key],
      }), {}),
    ]), []);
    downloadXLS({
      columns,
      data: newData,
      worksheetName: 'Reporte Facturas Canceladas',
      fileName: `reporte-canceladas-${moment().format('LL')}`,
    });
  }
  if (ok) ok();
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export function* DOWNLOAD_REPORT_PAYMENT_CANCELED({
  modal, filter, ok,
}) {
  yield put({ type: actions.DISABLED_INVOICE, payload: true });
  if (modal) modal.confirm(downloadZIP);
  const info = yield ppdInvoice.PostUrl('complements/report', filter);
  if (modal) modal.destroy();
  if (info?.data) {
    const columns = [
      { header: 'Emisor', key: 'issuer', width: 100 },
      { header: 'Nombre del cliente', key: 'receiver', width: 100 },
      { header: 'Folio', key: 'folio', width: 50 },
      { header: 'Monto', key: 'monto', width: 50 },
      { header: 'Fecha cancelación', key: 'cancelDate', width: 50 },
      { header: 'Tipo de factura', key: 'invoiceType', width: 50 },
      { header: 'Método de pago', key: 'paymentMethod', width: 50 },
      { header: 'UUID', key: 'uuid', width: 100 },
    ];
    const newData = (info?.data || []).reduce((acc, rep) => ([
      ...acc,
      Object.keys(rep).reduce((acck, key) => ({
        ...acck,
        [key]: rep[key],
      }), {}),
    ]), []);
    downloadXLS({
      columns,
      data: newData,
      worksheetName: 'Reporte Facturas Complementos Pagos',
      fileName: `reporte-complementos-pagos-${moment().format('LL')}`,
    });
  }
  if (ok) ok();
  yield put({ type: actions.DISABLED_INVOICE, payload: false });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_INVOICE, GET_ALL_INVOICE),
    takeEvery(actions.FETCH_INVOICE, GET_INVOICE),
    takeEvery(actions.FETCH_ALL_INVOICE_STAMP, GET_ALL_INVOICE_STAMP),
    takeEvery(actions.FETCH_ALL_INVOICE_CANCEL, GET_ALL_INVOICE_CANCEL),
    takeEvery(actions.FETCH_ALL_INVOICE_PPD, GET_ALL_INVOICE_PPD),
    takeEvery(actions.FETCH_ALL_INVOICE_PPD_COMPLEMENTS, GET_ALL_INVOICE_PPD_COMPLEMENTS),
    takeEvery(actions.CREATE_INVOICE, CREATE_INVOICE),
    takeEvery(actions.UPDATE_INVOICE, UPDATE_INVOICE),
    takeEvery(actions.DELETE_INVOICE, DELETE_INVOICE),
    takeEvery(actions.CANCEL_INVOICE, CANCEL_INVOICE),
    takeEvery(actions.MOTIVE_CANCEL_INVOICE, MOTIVE_CANCEL_INVOICE),
    takeEvery(actions.CANCEL_INVOICE_PPD, CANCEL_INVOICE_PPD),
    takeEvery(actions.MOTIVE_CANCEL_INVOICE_PPD, MOTIVE_CANCEL_INVOICE_PPD),
    takeEvery(actions.JOIN_INVOICE, JOIN_INVOICE),
    takeEvery(actions.SPLIT_INVOICE, SPLIT_INVOICE),
    takeEvery(actions.CREATE_MANY_INVOICE_PPD, CREATE_MANY_INVOICE_PPD),
    takeEvery(actions.CREATE_INVOICE_PPD, CREATE_INVOICE_PPD),
    takeEvery(actions.UPDATE_INVOICE_PPD, UPDATE_INVOICE_PPD),
    takeEvery(actions.DELETE_INVOICE_PPD, DELETE_INVOICE_PPD),
    takeEvery(actions.GET_STAMP_PDF, GET_STAMP_PDF),
    takeEvery(actions.GET_STAMP_ZIP, GET_STAMP_ZIP),
    takeEvery(actions.GET_MASIVE_STAMP_ZIP, GET_MASIVE_STAMP_ZIP),
    takeEvery(actions.GET_MASIVE_STAMP_ZIPXMLPDF, GET_MASIVE_STAMP_ZIPXMLPDF),
    takeEvery(actions.CLONE_INVOICE, CLONE_INVOICE),
    takeEvery(actions.SEND_INVOICE, SEND_INVOICE),
    takeEvery(actions.DOWNLOAD_REPORT_PPD, DOWNLOAD_REPORT_PPD),
    takeEvery(actions.DOWNLOAD_REPORT_CANCELED, DOWNLOAD_REPORT_CANCELED),
    takeEvery(actions.DOWNLOAD_REPORT_PAYMENT_CANCELED, DOWNLOAD_REPORT_PAYMENT_CANCELED),
  ]);
}
