import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  apikeys: {
    count: 0, data: [], byID: {}, loading: false,
  },
  apikey: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_APIKEY:
      return initialState;
    case actions.DISABLED_APIKEY:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_APIKEY:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_APIKEYS:
      return {
        ...state,
        apikeys: {
          ...initialState.apikeys,
          loading: true,
        },
      };
    case actions.LOADING_APIKEY:
      return {
        ...state,
        apikey: {
          ...initialState.apikey,
          loading: true,
        },
      };
    case actions.CLEAN_APIKEYS:
      return {
        ...state,
        apikeys: {},
        loading: initialState.apikeys,
      };
    case actions.CLEAN_APIKEY:
      return {
        ...state,
        apikey: initialState.apikey,
      };
    case actions.GET_APIKEY:
      return {
        ...state,
        apikey: action.payload,
      };
    case actions.GET_ALL_APIKEY:
      return {
        ...state,
        apikeys: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(apikey => ({
            ...apikey,
            key: apikey.id,
          })),
        },
      };
    default:
      return state;
  }
}
