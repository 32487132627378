import edder from './edder';

const version = [
  edder,
].join(' | ');

const versionGlobal = '1.7.7';

console.log();
console.log(`%c${version}`, 'font-weight: bold; font-size: xx-small;');
console.log(`%cVersión global: [${versionGlobal}]`, 'font-weight: bold; font-size: xx-small;');
console.log();

export default versionGlobal;
