import React from 'react';
import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';

import LoadingItem from '../../genericComponents/CustomAnimations/Loading';

const statusWaiting = { content: <LoadingItem text="message.status.waiting" />, footer: null };
const principalWaiting = { content: <LoadingItem text="message.principal.waiting" />, footer: null };

const products = new Crud('product');

export function* GET_ALL_PUBLIC_PRODUCT() {
  yield put({ type: actions.LOADING_PRODUCTS });
  const info = yield products.Get('public');
  if (info) {
    yield put({
      type: actions.GET_ALL_PUBLIC_PRODUCT,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_ALL_PRODUCT() {
  yield put({ type: actions.LOADING_PRODUCTS });
  const info = yield products.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_PRODUCT,
      payload: info,
      loading: false,
    });
  }
}

export function* CREATE_PRODUCT({ data }) {
  yield put({ type: actions.CLOSED_PRODUCT, payload: false });
  yield put({ type: actions.DISABLED_PRODUCT, payload: true });
  const info = yield products.Post(data);
  yield put({ type: actions.DISABLED_PRODUCT, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_PRODUCT, payload: true });
    yield GET_ALL_PRODUCT();
  }
}

export function* UPDATE_PRODUCT({ data }) {
  yield put({ type: actions.CLOSED_PRODUCT, payload: false });
  yield put({ type: actions.DISABLED_PRODUCT, payload: true });
  const info = yield products.Put(data);
  yield put({ type: actions.DISABLED_PRODUCT, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_PRODUCT, payload: true });
    yield GET_ALL_PRODUCT();
  }
}

export function* CHANGE_STATUS({
  id, modal, ok,
}) {
  if (modal) modal.confirm(statusWaiting);
  const info = yield products.PutUrl(`change-status/${id}`);
  if (modal) modal.destroy();
  if (info) {
    yield GET_ALL_PRODUCT();
  }
  if (ok) ok();
}
export function* CHANGE_PRINCIPAL({
  id, modal, ok,
}) {
  if (modal) modal.confirm(principalWaiting);
  const info = yield products.PutUrl(`change-principal/${id}`);
  if (modal) modal.destroy();
  if (info) {
    yield GET_ALL_PRODUCT();
  }
  if (ok) ok();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_PUBLIC_PRODUCT, GET_ALL_PUBLIC_PRODUCT),
    takeEvery(actions.FETCH_ALL_PRODUCT, GET_ALL_PRODUCT),
    takeEvery(actions.CREATE_PRODUCT, CREATE_PRODUCT),
    takeEvery(actions.UPDATE_PRODUCT, UPDATE_PRODUCT),
    takeEvery(actions.CHANGE_STATUS, CHANGE_STATUS),
    takeEvery(actions.CHANGE_PRINCIPAL, CHANGE_PRINCIPAL),
  ]);
}
