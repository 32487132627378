import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  conciliaciones: {
    count: 0, data: [], byID: {}, loading: false,
  },
  conciliacion: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_CONCILIACIONES:
      return initialState;
    case actions.DISABLED_CONCILIACION:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_CONCILIACION:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_CONCILIACIONES:
      return {
        ...state,
        conciliaciones: {
          ...initialState.clients,
          loading: true,
        },
      };
    case actions.LOADING_CONCILIACION:
      return {
        ...state,
        conciliacion: {
          ...initialState.conciliacion,
          loading: true,
        },
      };
    case actions.CLEAN_CONCILIACIONES:
      return {
        ...state,
        conciliaciones: {},
        loading: initialState.conciliaciones,
      };
    case actions.CLEAN_CONCILIACION:
      return {
        ...state,
        conciliacion: initialState.conciliacion,
      };
    case actions.GET_CONCILIACION:
      return {
        ...state,
        conciliacion: action.payload,
      };
    case actions.GET_ALL_CONCILIACIONES:
      return {
        ...state,
        conciliaciones: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(conciliacion => ({
            ...conciliacion,
            key: conciliacion.id,
          })),
        },
      };
    default:
      return state;
  }
}
