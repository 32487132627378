import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import {
  DownOutlined,
  PlusCircleOutlined,
  FileAddOutlined,
  FileSyncOutlined,
  HistoryOutlined,
} from '@ant-design/icons';

const options = [
  { value: '/billing/draft/add', title: 'topBar.billing.add', icon: <FileAddOutlined /> },
  { value: '/billing/canceled', title: 'topBar.billing.fromCanceled', icon: <FileSyncOutlined /> },
  {
    value: '/billing/ppd',
    title: 'topBar.billing.paymentComplement',
    icon: <HistoryOutlined />,
  },
];

const AddBilling = ({ intl, history }) => {
  const menu = (
    <Menu
      onClick={({ key }) => {
        history.push(key);
      }}
    >
      {options.map(({ value, title, icon }) => (
        <Menu.Item key={value} icon={icon}>
          {intl.formatMessage({ id: title })}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Button type="primary" shape="round">
        <PlusCircleOutlined /> {intl.formatMessage({ id: 'topBar.billing.title' })} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default AddBilling;
