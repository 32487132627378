const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOGIN_TOKEN: 'user/LOGIN_TOKEN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  REQUEST_CHANGE_PASSWORD: 'user/REQUEST_CHANGE_PASSWORD',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  CHANGE_EMAIL: 'user/CHANGE_EMAIL',
};

export default actions;
