import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Timeline, Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function CustomErrorMails({ info }) {
  const { error, success, files = {} } = info;
  const { xmlError, pdfError, zipError } = files;
  return (
    <>
      {Array.isArray(error) && error.length > 0 && (
        <>
          <Title level={5}><FormattedMessage id="error.mail.message" /></Title>
          <Timeline>
            {
              error.map(({ email, error: err }) => (
                <Timeline.Item key={email} color="red">
                  {email} - {err}
                </Timeline.Item>
              ))
            }
          </Timeline>
        </>
      )}
      {Array.isArray(success) && success.length > 0 && (
        <>
          <Title level={5}><FormattedMessage id="success.mail.message" /></Title>
          <Timeline>
            {
              success.map(email => (
                <Timeline.Item key={email} color="green">
                  {email}
                </Timeline.Item>
              ))
            }
          </Timeline>
        </>
      )}
      {xmlError && (
        <Paragraph>
          <b><FormattedMessage id="xml.mail.message" />:</b> {xmlError}
        </Paragraph>
      )}
      {pdfError && (
        <Paragraph>
          <b><FormattedMessage id="pdf.mail.message" />:</b> {pdfError}
        </Paragraph>
      )}
      {zipError && (
        <Paragraph>
          <b><FormattedMessage id="zip.mail.message" />:</b> {zipError}
        </Paragraph>
      )}
    </>
  );
}
