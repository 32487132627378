import React from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
// import FavPages from './FavPages'
import Search from './Search';
// import IssuesHistory from './IssuesHistory'
// import ProjectManagement from './ProjectManagement'
import LanguageSwitcher from './LanguageSwitcher';
import CompanySwitcher from './CompanySwitcher';
// import Actions from './Actions'
import UserMenu from './UserMenu';

import AddBilling from '../../../../views/topBar/AddBilling';
import BuyStamps from '../../../../views/topBar/BuyStamps';

import useIsCurrentCompanyOwner from '../../../../hooks/company/useIsCurrentCompanyOwner';

import style from './style.module.scss';
import NPS from '../../../../views/bottom/NPS';

const TopBar = ({ intl }) => {
  const history = useHistory();
  const isOwner = useIsCurrentCompanyOwner();
  return (
    <>
      <div
        className={style.topbar}
      >
        {/* <div className="mr-4">
          <FavPages intl={intl} FormattedMessage={FormattedMessage} />
        </div> */}
        <div className="d-none d-sm-block mr-4" style={{ marginTop: '5px' }}>
          <NPS />
        </div>
        <div className="mr-4">
          <CompanySwitcher intl={intl} />
        </div>
        <div className="mr-auto">
          {/* <Search intl={intl} FormattedMessage={FormattedMessage} /> */}
        </div>
        {/* <div className="mr-4 d-none d-md-block">
          <IssuesHistory intl={intl} FormattedMessage={FormattedMessage} />
        </div>
        <div className="mb-0 mr-auto d-xl-block d-none">
          <ProjectManagement intl={intl} FormattedMessage={FormattedMessage} />
        </div> */}
        {
        isOwner && (
          <div className="mr-4 d-none d-sm-block">
            <BuyStamps
              intl={intl}
              history={history}
              FormattedMessage={FormattedMessage}
            />
          </div>
        )
      }
        <div className="mr-4 d-none d-sm-block">
          <AddBilling intl={intl} history={history} FormattedMessage={FormattedMessage} />
        </div>
        <div className="mr-4 d-none d-sm-block">
          <LanguageSwitcher />
        </div>
        {/* <div className="mr-4 d-none d-sm-block">
          <Actions intl={intl} FormattedMessage={FormattedMessage} />
        </div> */}
        <div className="">
          <UserMenu intl={intl} FormattedMessage={FormattedMessage} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        { isOwner && (
          <div className="mr-4 d-block d-sm-none">
            <BuyStamps
              intl={intl}
              history={history}
              FormattedMessage={FormattedMessage}
              showTitle={false}
            />
          </div>
        )}
        <div className="mr-4 d-block d-sm-none">
          <AddBilling intl={intl} history={history} FormattedMessage={FormattedMessage} />
        </div>
      </div>
    </>
  );
};

export default injectIntl(TopBar);
