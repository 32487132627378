
export default {
  Tasa: 'Tasa',
  Cuota: 'Cuota',
  Rango: 'Rango',
  Fijo: 'Fijo',
  codeISR: '001',
  IVA: 'IVA',
  ISR: 'ISR',
  IEPS: 'IEPS',
  retencion: {
    value: 'retencion',
    label: 'Retención',
  },
  traslado: {
    value: 'traslado',
    label: 'Traslado',
  },
};
