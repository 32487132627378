import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  products: {
    count: 0, data: [], byID: {}, loading: false,
  },
  product: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_PRODUCT:
      return initialState;
    case actions.DISABLED_PRODUCT:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_PRODUCT:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_PRODUCTS:
      return {
        ...state,
        products: {
          ...initialState.products,
          loading: true,
        },
      };
    case actions.LOADING_PRODUCT:
      return {
        ...state,
        product: {
          ...initialState.product,
          loading: true,
        },
      };
    case actions.CLEAN_PRODUCTS:
      return {
        ...state,
        products: {},
        loading: initialState.products,
      };
    case actions.CLEAN_PRODUCT:
      return {
        ...state,
        product: initialState.product,
      };
    case actions.GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case actions.GET_ALL_PUBLIC_PRODUCT:
    case actions.GET_ALL_PRODUCT:
      return {
        ...state,
        products: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action?.payload?.data || []), 'id'),
          data: (action?.payload?.data || []).map(product => ({
            ...product,
            features: (product?.features || []).reduce((acc, feature) => [...acc, { feature }], []),
            key: product.id,
          })),
        },
      };
    default:
      return state;
  }
}
