const actions = {
  CLEAN_ALL_IMPORTING_SAT: 'importingsat/CLEAN_ALL_IMPORTING_SAT',
  DISABLED_REQUEST: 'importingsat/DISABLED',
  CLOSED_REQUEST: 'importingsat/CLOSED',
  LOADING_REQUESTS: 'importingsat/LOADING_REQUESTS',
  LOADING_REQUEST: 'importingsat/LOADING_REQUEST',
  CLEAN_REQUESTS: 'importingsat/CLEAN_REQUESTS',
  CLEAN_REQUEST: 'importingsat/CLEAN_REQUEST',
  GET_REQUEST: 'importingsat/GET_REQUEST',
  FETCH_REQUEST: 'importingsat/FETCH_REQUEST',
  GET_ALL_REQUEST: 'importingsat/GET_ALL_REQUEST',
  FETCH_ALL_REQUEST: 'importingsat/FETCH_ALL_REQUEST',
  CREATE_REQUEST: 'importingsat/CREATE',
  UPDATE_REQUEST: 'importingsat/UPDATE',
  DELETE_REQUEST: 'importingsat/DELETE',
  LOADING_INVOICES_REQ: 'importingsat/LOADING_INVOICES',
  LOADING_INVOICE_REQ: 'importingsat/LOADING_INVOICE',
  CLEAN_INVOICES_REQ: 'importingsat/CLEAN_INVOICES',
  CLEAN_INVOICE_REQ: 'importingsat/CLEAN_INVOICE',
  GET_ALL_XML_SAT: 'importingsat/GET_ALL_XML_SAT',
  GET_INVOICE_REQ: 'importingsat/GET_INVOICE',
  FETCH_INVOICE_REQ: 'importingsat/FETCH_INVOICE',
  GET_ALL_INVOICES_REQ: 'importingsat/GET_ALL_INVOICES',
  FETCH_ALL_INVOICES_REQ: 'importingsat/FETCH_ALL_INVOICES',
  GET_ALL_INVOICES_REQ_LOADING: 'importingsat/GET_ALL_INVOICES_REQ_LOADING',
  SET_AUTO_REQUEST: 'importingsat/SET_AUTO_REQUEST',
  CLONE_INVOICE_FROM_SAT: 'importingsat/CLONE_INVOICE_FROM_SAT',
};

export default actions;
