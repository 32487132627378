import _ from 'lodash';
import actions from './actions';

const cleanData = {
  count: 0, data: [], byID: {}, loading: false,
};

const initialState = {
  close: false,
  disabled: false,
  issuers: { ...cleanData },
  issuer: { loading: false },
  purchaseHistory: { ...cleanData },
  creditsIssuer: { loading: false },
  creditsAllIssuers: { ...cleanData },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_ISSUER:
      return initialState;
    case actions.DISABLED_ISSUER:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_ISSUER:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_ISSUERS:
      return {
        ...state,
        issuers: {
          ...initialState.issuers,
          loading: true,
        },
      };
    case actions.LOADING_ISSUER:
      return {
        ...state,
        issuer: {
          ...initialState.issuer,
          loading: true,
        },
      };
    case actions.LOADING_CREDITS_ALL_ISSUERS:
      return {
        ...state,
        creditsAllIssuers: {
          ...initialState.creditsAllIssuers,
          loading: true,
        },
      };
    case actions.LOADING_CREDITS_ISSUER:
      return {
        ...state,
        creditsIssuer: {
          ...initialState.creditsIssuer,
          loading: true,
        },
      };
    case actions.LOADING_PURCHASE_HISTORY:
      return {
        ...state,
        purchaseHistory: {
          ...initialState.purchaseHistory,
          loading: true,
        },
      };
    case actions.CLEAN_ISSUERS:
      return {
        ...state,
        issuers: {},
        loading: initialState.issuers,
      };
    case actions.CLEAN_ISSUER:
      return {
        ...state,
        issuer: initialState.issuer,
      };
    case actions.GET_ISSUER:
      return {
        ...state,
        issuer: {
          ...action.payload,
          loading: false,
        },
      };
    case actions.GET_ALL_ISSUER:
      return {
        ...state,
        issuers: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(({
            password, certFile, keyFile, ...issuer
          }) => ({
            ...issuer,
            key: issuer.id,
            responsible: issuer.responsible || [],
            series: issuer.series || [],
            certificates: [],
          })),
        },
      };
    case actions.GET_CREDITS_ISSUER:
      return {
        ...state,
        creditsIssuer: action.payload,
      };
    case actions.GET_CREDITS_ALL_ISSUERS:
      return {
        ...state,
        creditsAllIssuers: {
          ...action.payload,
          byID: {},
          loading: false,
          data: (action.payload.data || []).map(credits => ({
            ...credits,
            key: credits.id,
          })),
        },
      };
    case actions.GET_PURCHASE_HISTORY:
      return {
        ...state,
        purchaseHistory: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
        },
      };
    default:
      return state;
  }
}
