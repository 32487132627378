const actions = {
  DISABLED_INVOICE: 'invoice/DISABLED',
  DISABLED_INVOICE_CANCEL: 'invoice/DISABLED_CANCEL',
  CLOSED_INVOICE: 'invoice/CLOSED',
  LOADING_INVOICES: 'invoice/LOADING_INVOICES',
  LOADING_INVOICES_STAMP: 'invoice/LOADING_INVOICES_STAMP',
  LOADING_INVOICES_CANCEL: 'invoice/LOADING_INVOICES_CANCEL',
  LOADING_INVOICES_PPD: 'invoice/LOADING_INVOICES_PPD',
  LOADING_INVOICES_COMPLEMENTS: 'invoice/LOADING_INVOICES_COMPLEMENTS',
  LOADING_INVOICE: 'invoice/LOADING_INVOICE',
  CLEAN_ALL_INVOICE: 'invoice/CLEAN_ALL',
  CLEAN_INVOICES: 'invoice/CLEAN_INVOICES',
  CLEAN_INVOICE: 'invoice/CLEAN_INVOICE',
  GET_ALL_INVOICE: 'invoice/GET_ALL',
  FETCH_ALL_INVOICE: 'invoice/FETCH_ALL',
  GET_INVOICE: 'invoice/GET',
  FETCH_INVOICE: 'invoice/FETCH',
  GET_ALL_INVOICE_STAMP: 'invoice/GET_ALL_STAMP',
  FETCH_ALL_INVOICE_STAMP: 'invoice/FETCH_ALL_STAMP',
  GET_INVOICE_STAMP: 'invoice/GET_STAMP',
  FETCH_INVOICE_STAMP: 'invoice/FETCH_STAMP',
  GET_ALL_INVOICE_CANCEL: 'invoice/GET_ALL_CANCEL',
  FETCH_ALL_INVOICE_CANCEL: 'invoice/FETCH_ALL_CANCEL',
  GET_ALL_INVOICE_PPD: 'invoice/GET_ALL_PPD',
  FETCH_ALL_INVOICE_PPD: 'invoice/FETCH_ALL_PPD',
  GET_ALL_INVOICE_PPD_COMPLEMENTS: 'invoice/GET_ALL_INVOICE_PPD_COMPLEMENTS',
  FETCH_ALL_INVOICE_PPD_COMPLEMENTS: 'invoice/FETCH_ALL_INVOICE_PPD_COMPLEMENTS',
  CREATE_INVOICE: 'invoice/CREATE',
  UPDATE_INVOICE: 'invoice/UPDATE',
  DELETE_INVOICE: 'invoice/DELETE',
  CANCEL_INVOICE: 'invoice/CANCEL',
  MOTIVE_CANCEL_INVOICE: 'invoice/MOTIVE_CANCEL',
  CANCEL_INVOICE_PPD: 'invoice/CANCEL_PPD',
  MOTIVE_CANCEL_INVOICE_PPD: 'invoice/MOTIVE_CANCEL_PPD',
  JOIN_INVOICE: 'invoice/JOIN',
  SPLIT_INVOICE: 'invoice/SPLIT',
  CREATE_MANY_INVOICE_PPD: 'invoice/CREATE_MANY_INVOICE_PPD',
  CREATE_INVOICE_PPD: 'invoice/CREATE_PPD',
  UPDATE_INVOICE_PPD: 'invoice/UPDATE_PPD',
  DELETE_INVOICE_PPD: 'invoice/DELETE_PPD',
  GET_STAMP_PDF: 'invoice/GET_STAMP_PDF',
  GET_STAMP_ZIP: 'invoice/GET_STAMP_ZIP',
  SET_PAGINATION: 'invoice/SET_PAGINATION',
  GET_MASIVE_STAMP_ZIP: 'invoice/GET_MASIVE_STAMP_ZIP',
  GET_MASIVE_STAMP_ZIPXMLPDF: 'invoice/GET_MASIVE_STAMP_ZIPXMLPDF',
  CLONE_INVOICE: 'invoice/CLONE_INVOICE',
  SEND_INVOICE: 'invoice/SEND_INVOICE',
  DOWNLOAD_REPORT_PPD: 'invoice/DOWNLOAD_REPORT_PPD',
  DOWNLOAD_REPORT_CANCELED: 'invoice/DOWNLOAD_REPORT_CANCELED',
  DOWNLOAD_REPORT_PAYMENT_CANCELED: 'invoice/DOWNLOAD_REPORT_PAYMENT_CANCELED',
};

export default actions;
