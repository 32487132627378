import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user/reducers';
import menu from './menu/reducers';
import settings from './settings/reducers';
import config from './config/reducers';
import issuer from './issuer/reducers';
import client from './client/reducers';
import catalogs from './catalogs/reducers';
import userDB from './userDB/reducers';
import invoice from './invoice/reducers';
import apikey from './apikey/reducers';
import company from './company/reducers';
import webhooks from './webhooks/reducers';
import bankaccounts from './bankaccounts/reducers';
import product from './product/reducers';
import paymentmethod from './paymentmethod/reducers';
import conciliaciones from './conciliaciones/reducers';
import importingsat from './importingsat/reducers';
import conceptdescriptions from './conceptdescriptions/reducers';

export default history => combineReducers({
  router: connectRouter(history),
  user,
  menu,
  settings,
  config,
  issuer,
  client,
  catalogs,
  userDB,
  invoice,
  apikey,
  company,
  webhooks,
  bankaccounts,
  product,
  paymentmethod,
  conciliaciones,
  importingsat,
  conceptdescriptions,
});
