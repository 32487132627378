import { useSelector } from 'react-redux';

/**
 * @returns {boolean} true when user is owner of current company, false otherwise.
 */
export default function useIsCurrentCompanyOwner() {
  const isOwner = useSelector((state) => {
    const company = state.company.company.data;
    const user = state.user.db;

    return company && user ? company.ownerId === user.id : false;
  });

  return isOwner;
}
