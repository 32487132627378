import { notification } from 'antd';
import ExcelJS from 'exceljs';
import { save } from 'save-file';

export default async function downloadXLS({
  data,
  columns,
  fileName,
  worksheetName,
  conditional,
}) {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(worksheetName);

    worksheet.columns = Array.isArray(columns) && columns.length > 0 ? columns : [];

    if (conditional) worksheet.addConditionalFormatting(conditional);

    if (Array.isArray(data) && data.length > 0) {
      data.forEach((row) => {
        if (typeof row === 'object' && Object.keys(row).length > 0) worksheet.addRow(row);
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    await save(buffer, `${fileName}.xlsx`);
  } catch ({ message }) {
    notification.error({ message });
  }
}
