const actions = {
  DISABLED_USER: 'user/DISABLED',
  CLOSED_USER: 'user/CLOSED',
  LOADING_USERS: 'user/LOADING_USERS',
  LOADING_USER: 'user/LOADING_USER',
  CLEAN_ALL_USER: 'user/CLEAN_ALL',
  CLEAN_USERS: 'user/CLEAN_USERS',
  CLEAN_USER: 'user/CLEAN_USER',
  GET_ALL_USER: 'user/GET_ALL',
  FETCH_ALL_USER: 'user/FETCH_ALL',
  GET_USER: 'user/GET',
  FETCH_USER: 'user/FETCH',
  CREATE_USER: 'user/CREATE',
  UPDATE_USER: 'user/UPDATE',
  DELETE_USER: 'user/DELETE',
};

export default actions;
