import _ from 'lodash';
import actions from './actions';

const cleanCompany = { count: 0, data: [], loading: false };

const initialState = {
  close: false,
  disabled: false,
  companies: {
    owner: cleanCompany,
    me: cleanCompany,
  },
  company: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_COMPANY:
      return initialState;
    case actions.DISABLED_COMPANY:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_COMPANY:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_COMPANIES:
      return {
        ...state,
        companies: {
          ...state.companies,
          owner: {
            ...initialState.companies.owner,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_COMPANIES_ME:
      return {
        ...state,
        companies: {
          ...state.companies,
          me: {
            ...initialState.companies.me,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_COMPANY:
      return {
        ...state,
        company: {
          ...initialState.company,
          loading: action.payload,
        },
      };
    case actions.CLEAN_COMPANIES:
      return {
        ...state,
        companies: {},
        loading: initialState.companies,
      };
    case actions.CLEAN_COMPANY:
      return {
        ...state,
        company: initialState.company,
      };
    case actions.GET_COMPANY:
      return {
        ...state,
        company: {
          ...action.payload,
          data: action.payload.data || [],
        },
      };
    case actions.GET_ALL_COMPANY:
      return {
        ...state,
        companies: {
          ...state.companies,
          owner: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []),
          },
        },
      };
    case actions.GET_ALL_COMPANY_ME:
      return {
        ...state,
        companies: {
          ...state.companies,
          me: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []),
          },
        },
      };
    default:
      return state;
  }
}
