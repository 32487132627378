import { all, put, takeEvery } from 'redux-saga/effects';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from './actions';
import Crud from '../../services/fetch';

const company = new Crud('company');

const logEventCompany = sendLogEvent(logEvent, analytics);

export function* GET_ALL_COMPANY() {
  // yield put({ type: actions.CLEAN_ALL_COMPANY });
  yield put({ type: actions.LOADING_COMPANIES, payload: true });
  const info = yield company.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_COMPANY,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_COMPANIES, payload: false });
  }
}

export function* GET_COMPANY({ id }) {
  // yield put({ type: actions.CLEAN_ALL_COMPANY });
  yield put({ type: actions.LOADING_COMPANY, payload: true });
  const info = yield company.Get(`one/${id}`);
  if (info) {
    yield put({
      type: actions.GET_COMPANY,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_COMPANY, payload: false });
  }
}

export function* GET_ALL_COMPANY_ME() {
  // yield put({ type: actions.CLEAN_ALL_COMPANY });
  yield put({ type: actions.LOADING_COMPANIES_ME, payload: true });
  const info = yield company.Get('my');
  if (info) {
    yield put({
      type: actions.GET_ALL_COMPANY_ME,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_COMPANIES_ME, payload: false });
  }
}

export function* CREATE_COMPANY({ data }) {
  yield put({ type: actions.CLOSED_COMPANY, payload: false });
  yield put({ type: actions.DISABLED_COMPANY, payload: true });
  const info = yield company.Post(data);
  yield put({ type: actions.DISABLED_COMPANY, payload: false });
  if (info) {
    logEventCompany('create_company', data);
    yield put({ type: actions.CLOSED_COMPANY, payload: true });
    yield GET_ALL_COMPANY();
    yield GET_ALL_COMPANY_ME();
  }
}

export function* UPDATE_COMPANY({ data }) {
  yield put({ type: actions.CLOSED_COMPANY, payload: false });
  yield put({ type: actions.DISABLED_COMPANY, payload: true });
  const info = yield company.Put(data);
  yield put({ type: actions.DISABLED_COMPANY, payload: false });
  if (info) {
    logEventCompany('update_company', data);
    yield put({ type: actions.CLOSED_COMPANY, payload: true });
    yield GET_COMPANY(data);
    yield GET_ALL_COMPANY();
  }
}

export function* DELETE_COMPANY({ id }) {
  const resp = yield company.Delete(id);
  if (resp) {
    logEventCompany('delete_company', {
      companyID: id,
    });
  }
  yield GET_ALL_COMPANY();
}

export function* INVITATION_COMPANY({ data }) {
  yield put({ type: actions.CLOSED_COMPANY, payload: false });
  yield put({ type: actions.DISABLED_COMPANY, payload: true });
  const info = yield company.PostUrl('invite', data);
  yield put({ type: actions.DISABLED_COMPANY, payload: false });
  if (info) {
    logEventCompany('invitation_company', data);
    yield put({ type: actions.CLOSED_COMPANY, payload: true });
    yield GET_ALL_COMPANY();
  }
}

export function* EXIT_COMPANY({ id }) {
  const resp = yield company.PostUrl('exit', { id });
  if (resp) {
    logEventCompany('exit_company', {
      companyID: id,
    });
  }
  yield GET_ALL_COMPANY_ME();
  yield GET_ALL_COMPANY();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_COMPANY, GET_ALL_COMPANY),
    takeEvery(actions.FETCH_COMPANY, GET_COMPANY),
    takeEvery(actions.FETCH_ALL_COMPANY_ME, GET_ALL_COMPANY_ME),
    takeEvery(actions.CREATE_COMPANY, CREATE_COMPANY),
    takeEvery(actions.UPDATE_COMPANY, UPDATE_COMPANY),
    takeEvery(actions.DELETE_COMPANY, DELETE_COMPANY),
    takeEvery(actions.EXIT_COMPANY, EXIT_COMPANY),
    takeEvery(actions.INVITATION_COMPANY, INVITATION_COMPANY),
  ]);
}
