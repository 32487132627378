import React from 'react';
import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';

import LoadingItem from '../../genericComponents/CustomAnimations/Loading';
import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';

const principalWaiting = { content: <LoadingItem text="message.paymentmethod.principal.waiting" />, footer: null };
const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };

const paymentmethods = new Crud('payment-method');

export function* GET_ALL_PAYMENTMETHOD({ ok }) {
  yield put({ type: actions.LOADING_PAYMENTMETHODS });
  const info = yield paymentmethods.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_PAYMENTMETHOD,
      payload: info,
      loading: false,
    });
    if (ok) ok();
  }
}

export function* CREATE_PAYMENTMETHOD({ data, ok }) {
  yield put({ type: actions.CLOSED_PAYMENTMETHOD, payload: false });
  yield put({ type: actions.DISABLED_PAYMENTMETHOD, payload: true });
  const info = yield paymentmethods.Post(data);
  yield put({ type: actions.DISABLED_PAYMENTMETHOD, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_PAYMENTMETHOD, payload: true });
    yield GET_ALL_PAYMENTMETHOD({});
    if (ok) ok();
  }
}

export function* CHANGE_PAYMENTMETHOD_PRINCIPAL({
  id, modal, ok,
}) {
  if (modal) modal.confirm(principalWaiting);
  const info = yield paymentmethods.PutUrl(`change-principal/${id}`);
  if (modal) modal.destroy();
  if (info) {
    yield GET_ALL_PAYMENTMETHOD({});
  }
  if (ok) ok();
}

export function* DELETE_PAYMENTMETHOD({
  id, modal, ok,
}) {
  if (modal) modal.confirm(deleteWaiting);
  const info = yield paymentmethods.Delete(id);
  if (modal) modal.destroy();
  if (info) {
    yield GET_ALL_PAYMENTMETHOD({});
  }
  if (ok) ok();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_PAYMENTMETHOD, GET_ALL_PAYMENTMETHOD),
    takeEvery(actions.CREATE_PAYMENTMETHOD, CREATE_PAYMENTMETHOD),
    takeEvery(actions.CHANGE_PAYMENTMETHOD_PRINCIPAL, CHANGE_PAYMENTMETHOD_PRINCIPAL),
    takeEvery(actions.DELETE_PAYMENTMETHOD, DELETE_PAYMENTMETHOD),
  ]);
}
