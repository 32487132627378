import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  users: { count: 0, data: [], loading: false },
  user: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_USER:
      return initialState;
    case actions.DISABLED_USER:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_USER:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_USERS:
      return {
        ...state,
        users: {
          ...initialState.users,
          loading: true,
        },
      };
    case actions.LOADING_USER:
      return {
        ...state,
        user: {
          ...initialState.user,
          loading: true,
        },
      };
    case actions.CLEAN_USERS:
      return {
        ...state,
        users: {},
        loading: initialState.users,
      };
    case actions.CLEAN_USER:
      return {
        ...state,
        user: initialState.user,
      };
    case actions.GET_ALL_USER:
      return {
        ...state,
        users: {
          ...action.payload,
          loading: false,
          data: (action.payload.data || []).map(user => ({
            ...user,
            key: user.id,
          })),
        },
      };
    default:
      return state;
  }
}
