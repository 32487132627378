import { notification } from 'antd';
import { getToken, logout } from '../firebase';

const sesionClose = () => {
  logout();
  window.location.reload();
};

export class CRUD {
  constructor(url = '', hasToken = true, globalDomain, firebaseError) {
    this.url = url;
    this.hasToken = hasToken;
    this.globalDomain = globalDomain;
    this.controller = undefined;
    this.signal = undefined;
  }

  getDomain(url = '') {
    const globalDomain = this.globalDomain || global.domain;
    return `${globalDomain}/${this.url}/${url}`;
  }

  SetCancelToken() {
    const controller = new AbortController();
    const { signal } = controller;
    this.controller = controller;
    this.signal = signal;
  }

  Cancel() {
    if (this?.controller && this?.controller?.abort) this.controller.abort();
  }

  CleanCancelToken() {
    this.controller = undefined;
    this.signal = undefined;
  }

  async sendRequest({
    url, info, method, buffer, showError = true, headers = {}, formData = false,
  }) {
    try {
      let token = {};
      if (this.hasToken) {
        let company = {};
        const tokenCompany = window.localStorage.getItem('app.companyID');
        if (tokenCompany) {
          company = { Company: tokenCompany };
        }
        // let tkn = window.localStorage.getItem('app.token');
        // if (!tkn) {
        const tkn = await getToken();
        if (tkn) {
          window.localStorage.setItem('app.token', tkn);
        }
        // }
        // console.log(tkn);
        token = {
          ...company,
          ...(tkn ? { Authorization: `Bearer ${tkn}` } : {}),
        };
      }
      const signal = this.signal ? { signal: this.signal } : {};
      let body;
      let jsonApp = {};
      if (formData) {
        body = new FormData();
        Object.keys(info).forEach((key) => {
          if (info[key]) {
            body.append(key, info[key]);
          }
        });
      } else if (info && typeof info === 'object') {
        jsonApp = { 'Content-Type': 'application/json; charset=utf-8' };
        body = JSON.stringify(info);
      }
      const response = await fetch(this.getDomain(url), {
        ...signal,
        method,
        body,
        headers: {
          ...jsonApp,
          ...headers,
          ...token,
        },
      });
      if (!response.ok) {
        if (response?.status === 401) {
          sesionClose();
          throw new Error(`${response?.status} - ${response?.message}}`);
        }
        const { status, error } = await response.json();
        if (status === 401) {
          sesionClose();
        }
        throw new Error(`${status} - ${error.message} ${error.info || ''}`);
      }
      if (buffer) {
        return response.blob();
      }
      const { status, error, ...rest } = await response.json();
      return rest;
    } catch ({ name, message }) {
      if (name === 'AbortError') {
        this.CleanCancelToken();
        console.error('fetch', message);
        return { error: 'AbortError' };
      }
      if (showError) notification.error({ message });
      else throw new Error(message);
      return false;
    }
  }

  async Get(url, showError, headers) {
    return this.sendRequest({
      url,
      showError,
      headers,
      method: 'get',
    });
  }

  async Post(info, showError, headers, formData = false) {
    return this.sendRequest({
      info,
      showError,
      headers,
      method: 'post',
      formData,
    });
  }

  async PostUrl(url, info, showError, headers, formData = false) {
    return this.sendRequest({
      url,
      info,
      showError,
      headers,
      method: 'post',
      formData,
    });
  }

  async PostBuffer(url, info, showError, headers) {
    return this.sendRequest({
      url,
      info,
      showError,
      headers,
      buffer: true,
      method: 'post',
    });
  }

  async Put(info, showError, headers) {
    return this.sendRequest({
      info,
      showError,
      headers,
      method: 'put',
    });
  }

  async PutUrl(url, info, showError, headers) {
    return this.sendRequest({
      url,
      info,
      showError,
      headers,
      method: 'put',
    });
  }

  async Delete(url, showError, headers) {
    return this.sendRequest({
      url,
      showError,
      headers,
      method: 'delete',
    });
  }

  async PostFD(info, showError, headers) {
    return this.sendRequest({
      info,
      showError,
      headers,
      method: 'post',
      formData: true,
    });
  }

  async PostUrlFD(url, info, showError, headers) {
    return this.sendRequest({
      url,
      info,
      showError,
      headers,
      method: 'post',
      formData: true,
    });
  }

  async PostBufferFD(url, info, showError, headers) {
    return this.sendRequest({
      url,
      info,
      showError,
      headers,
      formData: true,
      buffer: true,
      method: 'post',
    });
  }

  async PutFD(info, showError, headers) {
    return this.sendRequest({
      info,
      showError,
      headers,
      formData: true,
      method: 'put',
    });
  }

  async PutUrlFD(url, info, showError, headers) {
    return this.sendRequest({
      url,
      info,
      showError,
      headers,
      formData: true,
      method: 'put',
    });
  }

  async DeleteFD(url, showError, headers) {
    return this.sendRequest({
      url,
      showError,
      headers,
      formData: true,
      method: 'delete',
    });
  }
}

export default CRUD;
