const actions = {
  DISABLED_PRODUCT: 'products/DISABLED',
  CLOSED_PRODUCT: 'products/CLOSED',
  LOADING_PRODUCTS: 'products/LOADING_PRODUCTS',
  LOADING_PRODUCT: 'products/LOADING_PRODUCT',
  CLEAN_ALL_PRODUCT: 'products/CLEAN_ALL',
  CLEAN_PRODUCTS: 'products/CLEAN_PRODUCTS',
  CLEAN_PRODUCT: 'products/CLEAN_PRODUCT',
  GET_ALL_PRODUCT: 'products/GET_ALL',
  FETCH_ALL_PRODUCT: 'products/FETCH_ALL',
  GET_ALL_PUBLIC_PRODUCT: 'products/GET_ALL_PUBLIC',
  FETCH_ALL_PUBLIC_PRODUCT: 'products/FETCH_ALL_PUBLIC',
  GET_PRODUCT: 'products/GET',
  FETCH_PRODUCT: 'products/FETCH',
  CREATE_PRODUCT: 'products/CREATE',
  UPDATE_PRODUCT: 'products/UPDATE',
  CHANGE_STATUS: 'products/CHANGE_STATUS',
  CHANGE_PRINCIPAL: 'products/CHANGE_PRINCIPAL',
};

export default actions;
