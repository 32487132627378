
export const catalogTipoFactor = [
  { claveTipoFactor: 'Tasa' },
  { claveTipoFactor: 'Cuota' },
  { claveTipoFactor: 'Exento' },
];

export const getCatalogTipoFactorByID = catalogTipoFactor.reduce((acc, { claveTipoFactor }) => ({
  ...acc, [claveTipoFactor]: claveTipoFactor,
}));

export const catalogTipoCadena = [
  { code: '1', description: 'SPEI' },
];

export const getCatalogTipoCadenaByID = catalogTipoCadena.reduce((acc, cat) => ({
  ...acc, [cat.code]: cat,
}), {});

export const catalogFecuencia = [
  { claveFrecuencia: 'Mensual' },
  { claveFrecuencia: 'Quincenal' },
  { claveFrecuencia: 'Catorcenal' },
  { claveFrecuencia: 'Decenal' },
  { claveFrecuencia: 'Semanal' },
  { claveFrecuencia: 'Semanal-Extraordinaria' },
];

export const getCatalogFecuencia = catalogFecuencia.reduce((acc, { claveFrecuencia }) => ({
  ...acc, [claveFrecuencia]: claveFrecuencia,
}), {});

export const catalogStatusPago = [
  { value: 'Pagada' },
  { value: 'Pendiente' },
];

export const getCatalogStatusPagoByID = catalogStatusPago.reduce((acc, { value }) => ({
  ...acc, [value]: value,
}), {});

export const catalogStatusPaymentComplement = [
  { value: 'Vigente' },
  { value: 'Cancelada' },
];

export const getCatalogStatusPaymentComplementByID = catalogStatusPaymentComplement.reduce((acc, { value }) => ({
  ...acc, [value]: value,
}), {});

export const extraordinariaKey = 'Extraordinaria';
export const finiquitoKey = 'Finiquito';
export const aguinaldoKey = 'Aguinaldo';
export const provisionKey = 'Provision';
export const costoSocialKey = 'Costo Social';
export const segurosKey = 'Seguros';
export const semanaKey = 'SM';
export const decenaKey = 'DC';
export const catorcenaKey = 'CT';
export const quincenaKey = 'Q';
export const mesKey = 'M';

export const catalogoPeriodType = [
  { label: extraordinariaKey, value: extraordinariaKey },
  { label: finiquitoKey, value: finiquitoKey },
  { label: aguinaldoKey, value: aguinaldoKey },
  { label: provisionKey, value: provisionKey },
  { label: costoSocialKey, value: costoSocialKey },
  { label: segurosKey, value: segurosKey },
  { label: 'Semanal', value: semanaKey },
  { label: 'Decenal', value: decenaKey },
  { label: 'Catorcenal', value: catorcenaKey },
  { label: 'Quincenal', value: quincenaKey },
  { label: 'Mensual', value: mesKey },
];

export const getCatalogoPeriodTypeByValue = catalogoPeriodType.reduce((acc, { value }) => ({
  ...acc, [value]: value,
}), {});

export const catalogosPeriodosOrdinariosByKey = {
  [semanaKey]: getBasicCatalogs(53),
  [decenaKey]: getBasicCatalogs(38),
  [catorcenaKey]: getBasicCatalogs(28),
  [quincenaKey]: getBasicCatalogs(24),
  [mesKey]: getBasicCatalogs(12),
};

function getBasicCatalogs(count) {
  let cat = [];
  let init = 1;
  while (init <= count) {
    let value = init.toString();
    if (value.length < 2) {
      value = `0${init}`;
    }
    cat = [...cat, { value, label: value }];
    init += 1;
  }
  return cat;
}
