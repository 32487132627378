import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
// import jwtoken from 'jsonwebtoken';
import { notification } from 'antd';
import { history } from 'index';
import * as firebase from 'services/firebase';
import * as jwt from 'services/jwt';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from '../actions';
import { getLocale } from '../../helpers';
import {
  GET_ALL_USE_CFDI,
  GET_ALL_REGIMEN_FISCAL,
  GET_ALL_CURRENCY,
  GET_ALL_FORMA_PAGO,
  GET_ALL_METODO_PAGO,
  GET_ALL_TIPO_COMPROBANTE,
  GET_ALL_IMPUESTO,
  GET_ALL_TIPO_RELACION,
  GET_ALL_TASA_CUOTA,
  GET_ALL_TIPOS_UNIDADES,
  GET_ALL_BANKS,
  GET_ALL_MOTIVE_CANCELED,
  GET_ALL_EXPORTACIONES,
  GET_ALL_OBJETO_IMP,
  GET_ALL_MESES,
  GET_ALL_PERIODICIDAD,
} from '../catalogs/sagas';
import {
  GET_COMPANY,
} from '../company/sagas';
import Crud from '../../services/fetch';
// import tawkto from '../../tawkto';

const keyCompany = 'app.companyID';

const userAuth = new Crud('user');
const user = new Crud('cloud/user', false);
const resetPassword = new Crud('cloud/user/reset-password', false);
const changeEmail = new Crud('user/change-mail');

const logEventUser = sendLogEvent(logEvent, analytics);

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    loginWithCustomToken: firebase.loginWithCustomToken,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
};

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { authProvider: autProviderName } = yield select(state => state.settings);
  const success = yield call(mapAuthProviders[autProviderName].login, email, password);
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });

    const userInfo = yield select(state => state.user);
    const analyticEventParams = {
      companyID: userInfo?.companyID,
      firebaseUserID: userInfo?.id,
      stampiUserID: userInfo?.db?.id,
    };
    logEventUser('login', { ...analyticEventParams });

    yield history.push('/');
    notification.success({
      message: getLocale('login.successfully.message'),
      description: getLocale('login.successfully.description'),
    });
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGIN_TOKEN({ token }) {
  window.localStorage.clear();
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { authProvider: autProviderName } = yield select(state => state.settings);
  const success = yield call(mapAuthProviders[autProviderName].loginWithCustomToken, token);
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });

    const userInfo = yield select(state => state.user);
    const analyticEventParams = {
      companyID: userInfo?.companyID,
      firebaseUserID: userInfo?.id,
      stampiUserID: userInfo?.db?.id,
    };
    logEventUser('login-token', { ...analyticEventParams });

    yield history.push('/');
    notification.success({
      message: getLocale('login.successfully.message'),
      description: getLocale('login.successfully.description'),
    });
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* REGISTER({ payload }) {
  const { email: mail, ...rest } = payload;
  const data = { mail, ...rest };
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield user.Post(data);
  // const { authProvider } = yield select(state => state.settings);
  // const success = yield call(mapAuthProviders[authProvider].register, email, password, name);
  if (response?.data?.data) {
    // yield put({
    //   type: 'user/LOAD_CURRENT_ACCOUNT',
    // });
    const { mail: email } = (response?.data?.data || {});
    logEventUser('new_user', { email });
    yield put({
      payload: {
        ...payload,
        email,
      },
      type: 'user/LOGIN',
    });
    yield history.push('/');
    notification.success({
      message: getLocale('register.successfully.message'),
      description: getLocale('register.successfully.description'),
    });
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { authProvider } = yield select(state => state.settings);
  const response = yield call(mapAuthProviders[authProvider].currentAccount);
  if (response) {
    const {
      id, email, displayName: name, avatar, role,
    } = response;
    const { data } = yield userAuth.Get('me');
    const tokenCompany = window.localStorage.getItem(keyCompany);
    let companyID;
    if (tokenCompany) {
      const { data: companyDataID } = yield userAuth.Get('company/decoded');
      companyID = companyDataID;
    } else if (!tokenCompany && data?.companyId) {
      companyID = data?.companyId;
      const { data: token } = yield userAuth.Get(`company/${companyID}`);
      if (token) {
        window.localStorage.setItem(keyCompany, token);
      } else {
        yield put({ type: 'user/LOGOUT' });
      }
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        companyID,
        db: data,
        authorized: true,
      },
    });

    // tawkto(
    //   '615789d725797d7a8901dc07/1fguv4sjl',
    //   name,
    //   email,
    //   id,
    // );
    yield all([
      GET_ALL_USE_CFDI(),
      GET_ALL_REGIMEN_FISCAL(),
      GET_ALL_CURRENCY(),
      GET_ALL_FORMA_PAGO(),
      GET_ALL_METODO_PAGO(),
      GET_ALL_TIPO_COMPROBANTE(),
      GET_ALL_IMPUESTO(),
      GET_ALL_TIPO_RELACION(),
      GET_ALL_TASA_CUOTA(),
      GET_ALL_TIPOS_UNIDADES(),
      GET_ALL_BANKS(),
      GET_ALL_MOTIVE_CANCELED(),
      GET_ALL_EXPORTACIONES(),
      GET_ALL_OBJETO_IMP(),
      GET_ALL_MESES(),
      GET_ALL_PERIODICIDAD(),
      companyID ? GET_COMPANY({ id: companyID }) : () => {},
    ]);
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* REQUEST_CHANGE_PASSWORD({ payload }) {
  const { email } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { data } = yield resetPassword.Post({ mail: email });
  if (data?.successEmail) {
    yield history.push('/auth/login');
    notification.success({
      message: getLocale('login.resetPassword.confirm.message'),
      description: getLocale('login.resetPassword.confirm.description'),
    });
  }
  if (data?.ErrorEmail) {
    let formatMessage = '';
    (data?.ErrorEmail || []).forEach(({ email: mail, error }) => {
      formatMessage += `${formatMessage !== '' ? `${formatMessage},` : ''} ${mail}: ${error}`;
    });
    notification.error({
      message: getLocale('error.message'),
      description: formatMessage,
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* RESET_PASSWORD({ payload, hash }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { data } = yield resetPassword.PostUrl(hash, payload);
  if (data?.successEmail) {
    yield history.push('/auth/login');
    notification.success({
      message: getLocale('login.resetPassword.success.message'),
      description: getLocale('login.resetPassword.success.description'),
    });
  }
  if (data?.ErrorEmail) {
    let formatMessage = '';
    (data?.ErrorEmail || []).forEach(({ email: mail, error }) => {
      formatMessage += `${formatMessage !== '' ? `${formatMessage},` : ''} ${mail}: ${error}`;
    });
    notification.error({
      message: getLocale('error.message'),
      description: formatMessage,
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* CHANGE_EMAIL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { data } = yield changeEmail.Post(payload);
  if (data?.successEmail) {
    notification.success({
      message: getLocale('user.email.success.message'),
      description: getLocale('user.email.success.description'),
    });
  }
  if (data?.ErrorEmail) {
    let formatMessage = '';
    (data?.ErrorEmail || []).forEach(({ email: mail, error }) => {
      formatMessage += `${formatMessage !== '' ? `${formatMessage},` : ''} ${mail}: ${error}`;
    });
    notification.error({
      message: getLocale('error.message'),
      description: formatMessage,
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings);
  yield call(mapAuthProviders[authProvider].logout);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  });
  window.localStorage.clear();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_TOKEN, LOGIN_TOKEN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.REQUEST_CHANGE_PASSWORD, REQUEST_CHANGE_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.CHANGE_EMAIL, CHANGE_EMAIL),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
