import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  clients: {
    count: 0, data: [], byID: {}, loading: false,
  },
  client: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_CLIENT:
      return initialState;
    case actions.DISABLED_CLIENT:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_CLIENT:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_CLIENTS:
      return {
        ...state,
        clients: {
          ...initialState.clients,
          loading: true,
        },
      };
    case actions.LOADING_CLIENT:
      return {
        ...state,
        client: {
          ...initialState.client,
          loading: true,
        },
      };
    case actions.CLEAN_CLIENTS:
      return {
        ...state,
        clients: {},
        loading: initialState.clients,
      };
    case actions.CLEAN_CLIENT:
      return {
        ...state,
        client: initialState.client,
      };
    case actions.GET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case actions.GET_ALL_CLIENT:
      return {
        ...state,
        clients: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(client => ({
            ...client,
            key: client.id,
          })),
        },
      };
    default:
      return state;
  }
}
