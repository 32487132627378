import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import actions from '../../../../../redux/actions';
import Crud from '../../../../../services/fetch';

const keyCompany = 'app.companyID';
const { Option } = Select;

const userAuth = new Crud('user');

const CompanySwitcher = ({
  intl, companies, dispatch, user,
}) => {
  const { me } = companies;

  const [loading, setLoading] = useState(false);
  const [companyID, setCompanyID] = useState(false);

  function getData() {
    dispatch({ type: actions.FETCH_ALL_COMPANY_ME });
  }

  function setCompany() {
    if (user?.companyID) {
      setCompanyID(user?.companyID);
    }
  }

  useEffect(getData, []);
  useEffect(setCompany, [user?.companyID]);

  return (
    <Select
      showSearch
      value={companyID}
      disabled={loading || me?.loading}
      loading={loading || me?.loading}
      style={{ width: '200px' }}
      placeholder={intl.formatMessage({ id: 'company.select' })}
      onChange={(key) => {
        (async () => {
          setLoading(true);
          setCompanyID(key);
          const company = (me?.data || []).filter(({ id }) => id === key);
          if (!company[0]) return;
          const { id } = company[0];
          const { data: token } = await userAuth.Get(`company/${id}`);
          if (token) {
            window.localStorage.removeItem('app.invoice.filter');
            window.localStorage.setItem(keyCompany, token);
            window.location.reload();
          } else {
            dispatch({ type: actions.LOGOUT });
          }
        })();
      }}
    >
      {(me?.data || []).map(({ name, id }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = ({ company, user, dispatch }) => ({
  user,
  dispatch,
  companies: company.companies,
});

export default connect(mapStateToProps)(CompanySwitcher);
