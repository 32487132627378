import React from 'react';
import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';

import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';

const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };

const bankaccounts = new Crud('bankaccounts');

export function* GET_ALL_BANK_ACCOUNT({ typeAccount, id }) {
  yield put({ type: actions.LOADING_BANK_ACCOUNTS });
  const info = yield bankaccounts.Get(`${typeAccount}/${id}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_BANK_ACCOUNT,
      typeAccount,
      payload: info,
      loading: false,
    });
  }
}

export function* CREATE_BANK_ACCOUNT({ data, typeAccount }) {
  yield put({ type: actions.CLOSED_BANK_ACCOUNT, payload: false });
  yield put({ type: actions.DISABLED_BANK_ACCOUNT, payload: true });
  const info = yield bankaccounts.Post(data);
  yield put({ type: actions.DISABLED_BANK_ACCOUNT, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_BANK_ACCOUNT, payload: true });
    yield GET_ALL_BANK_ACCOUNT({
      typeAccount,
      id: data[`${typeAccount}ID`],
    });
  }
}

export function* UPDATE_BANK_ACCOUNT({ data, typeAccount }) {
  yield put({ type: actions.CLOSED_BANK_ACCOUNT, payload: false });
  yield put({ type: actions.DISABLED_BANK_ACCOUNT, payload: true });
  const info = yield bankaccounts.Put(data);
  yield put({ type: actions.DISABLED_BANK_ACCOUNT, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_BANK_ACCOUNT, payload: true });
    yield GET_ALL_BANK_ACCOUNT({
      typeAccount,
      id: data[`${typeAccount}ID`],
    });
  }
}

export function* DELETE_BANK_ACCOUNT({
  id, typeAccount, idAccount, modal, ok,
}) {
  if (modal) modal.confirm(deleteWaiting);
  const info = yield bankaccounts.Delete(id);
  if (modal) modal.destroy();
  if (info) {
    yield GET_ALL_BANK_ACCOUNT({
      typeAccount,
      id: idAccount,
    });
  }
  if (ok) ok();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_BANK_ACCOUNT, GET_ALL_BANK_ACCOUNT),
    takeEvery(actions.CREATE_BANK_ACCOUNT, CREATE_BANK_ACCOUNT),
    takeEvery(actions.UPDATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT),
    takeEvery(actions.DELETE_BANK_ACCOUNT, DELETE_BANK_ACCOUNT),
  ]);
}
