import React from 'react';
import style from './style.module.scss';
import { getCopyright, getNameApp, getSubNameApp } from '../../../../helpers';

const Footer = () => (
  <div className={style.footer}>
    <div className={style.footerInner}>
      <a href="#" target="_blank" rel="noopener noreferrer" className={style.logo}>
        {getNameApp.toUpperCase()}
        <span />
      </a>
      <br />
      <p className="mb-0">
        Copyright © {getCopyright} Nómada |{' '}
        <a href="#" target="_blank" rel="noopener noreferrer">
          {getSubNameApp}
        </a>
      </p>
    </div>
  </div>
);

export default Footer;
