import React from 'react';
import {
  all, put, takeEvery, select,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from './actions';
import Crud from '../../services/fetch';

import Loading from '../../genericComponents/CustomAnimations/Loading';
import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';

const genericLoading = { content: <Loading text="generic.loading" />, footer: null };
const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };

const conciliaciones = new Crud('conciliaciones');

const logEventClient = sendLogEvent(logEvent, analytics);

export function* GET_ALL_CONCILIACIONES({ filter }) {
  yield put({ type: actions.LOADING_CONCILIACIONES });
  let params = '';
  if (filter) {
    params = '?';
    params += new URLSearchParams(filter).toString();
  }
  conciliaciones.Cancel();
  conciliaciones.SetCancelToken();
  const info = yield conciliaciones.Get(`all${params}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_CONCILIACIONES,
      payload: info,
      loading: false,
    });
  }
}

// export function* GET_CONCILIACION({ id }) {
//   yield put({ type: actions.LOADING_CONCILIACION });
//   const info = yield conciliaciones.Get(`one/${id}`);
//   if (info) {
//     yield put({
//       type: actions.GET_CONCILIACION,
//       payload: info,
//       loading: false,
//     });
//   }
// }

export function* UPDATE_CONCILIACION({ data, filter, close }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CONCILIACION, payload: false });
  yield put({ type: actions.DISABLED_CONCILIACION, payload: true });
  const info = yield conciliaciones.Put(data);
  yield put({ type: actions.DISABLED_CONCILIACION, payload: false });
  if (info) {
    logEventClient('update_conciliacion', {
      ...analyticEventParams,
      conciliacionID: data.id,
    });
    yield put({ type: actions.CLOSED_CONCILIACION, payload: true });
    yield put({ type: actions.FETCH_ALL_CONCILIACIONES, filter });
    if (close) close();
  }
}

export function* ADD_CONCILIACION_CARGO({ data, filter, close }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CONCILIACION, payload: false });
  yield put({ type: actions.DISABLED_CONCILIACION, payload: true });
  const info = yield conciliaciones.PutUrl('addCargo', data);
  yield put({ type: actions.DISABLED_CONCILIACION, payload: false });
  if (info) {
    logEventClient('update_conciliacion', {
      ...analyticEventParams,
      conciliacionID: data.id,
    });
    yield put({ type: actions.CLOSED_CONCILIACION, payload: true });
    yield put({ type: actions.FETCH_ALL_CONCILIACIONES, filter });
    if (close) close();
  }
}

export function* CONCILIACION_UPDATE_PUES({ modal, refresh }) {
  if (modal) modal.confirm(genericLoading);
  yield put({ type: actions.DISABLED_CONCILIACION, payload: true });
  const info = yield conciliaciones.PutUrl('pue-update');
  if (modal) modal.destroy();
  if (info) {
    if (refresh) refresh();
    notification.success({ message: info?.data || 'Ok' });
  }
  yield put({ type: actions.DISABLED_CONCILIACION, payload: false });
}

export function* DELETE_CONCILIACION({
  id, modal, filter, ok,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(deleteWaiting);
  const del = yield conciliaciones.Delete(id);
  if (modal) modal.destroy();
  if (del) {
    yield put({ type: actions.FETCH_ALL_CONCILIACIONES, filter });
  }
  if (ok) ok();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_CONCILIACIONES, GET_ALL_CONCILIACIONES),
    // takeEvery(actions.FETCH_CONCILIACION, GET_CONCILIACION),
    takeEvery(actions.UPDATE_CONCILIACION, UPDATE_CONCILIACION),
    takeEvery(actions.DELETE_CONCILIACION, DELETE_CONCILIACION),
    takeEvery(actions.ADD_CONCILIACION_CARGO, ADD_CONCILIACION_CARGO),
    takeEvery(actions.CONCILIACION_UPDATE_PUES, CONCILIACION_UPDATE_PUES),
  ]);
}
