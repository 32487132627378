import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  requests: {
    count: 0, data: [], byID: {}, loading: false, autoRequest: {},
  },
  request: { loading: false },
  invoices: {
    count: 0, data: [], byID: {}, loading: false,
  },
  invoice: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_IMPORTING_SAT:
      return initialState;
    case actions.DISABLED_REQUEST:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_REQUEST:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_REQUESTS:
      return {
        ...state,
        requests: {
          ...initialState.requests,
          loading: true,
        },
      };
    case actions.LOADING_REQUEST:
      return {
        ...state,
        request: {
          ...initialState.request,
          loading: true,
        },
      };
    case actions.CLEAN_REQUESTS:
      return {
        ...state,
        requests: {},
        loading: initialState.requests,
      };
    case actions.CLEAN_REQUEST:
      return {
        ...state,
        request: initialState.client,
      };
    case actions.GET_REQUEST:
      return {
        ...state,
        request: action.payload,
      };
    case actions.GET_ALL_REQUEST:
      return {
        ...state,
        requests: {
          ...initialState.requests,
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(request => ({
            ...request,
            key: request.id,
          })),
        },
      };
    case actions.LOADING_INVOICES_REQ:
      return {
        ...state,
        invoices: {
          ...initialState.invoices,
          loading: action.payload,
        },
      };
    case actions.LOADING_INVOICE_REQ:
      return {
        ...state,
        invoice: {
          ...initialState.invoice,
          loading: true,
        },
      };
    case actions.CLEAN_INVOICES_REQ:
      return {
        ...state,
        invoices: {},
        loading: initialState.invoices,
      };
    case actions.CLEAN_INVOICE_REQ:
      return {
        ...state,
        invoice: initialState.client,
      };
    case actions.GET_INVOICE_REQ:
      return {
        ...state,
        invoice: action.payload,
      };
    case actions.GET_ALL_INVOICES_REQ:
      return {
        ...state,
        invoices: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(invoice => ({
            ...invoice,
            key: invoice.id,
          })),
        },
      };
    case actions.SET_AUTO_REQUEST: {
      let autoRequest = {};
      if (action.payload.value) {
        autoRequest = {
          ...state.requests.autoRequest,
          [action.payload.id]: action.payload.value,
        };
      } else {
        autoRequest = { ...state.requests.autoRequest };
        delete autoRequest[action.payload.id];
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          autoRequest,
        },
      };
    }
    default:
      return state;
  }
}
