import React from 'react';
import { Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import spanish from '../locales/es-MX';
import english from '../locales/en-US';
import enums from './enums';
import { selectFilterPropsV2 } from './select';

export const roundTwo = value => Number(Number(value).toFixed(2));

export const getVersionCancelacionSAT = (issuer = {}) => {
  const { cancelacionVersion } = issuer;
  return cancelacionVersion;
};

export function GetStringFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result.split(',')[1]);
    };
    reader.readAsDataURL(file);
  });
}

export function normFile(e) {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
}

export const setTitlePage = (view = '') => (company = '') => {
  window.title = `${company}${
    company && company.trim() !== '' && view && view.trim() !== '' ? ' - ' : ''
  }${view}`;
};

export const getNameApp = getLocale('app.name');

export const getSubNameApp = getLocale('app.nickname');

export const getAppFull = `${getNameApp} ${getSubNameApp}`;

export const getAppTitle = `${getNameApp} ${getSubNameApp}`;

export const getCopyright = `2021 - ${new Date().getFullYear()}`;

function validInfo(getLanguages, getLanguage, id) {
  return getLanguages[getLanguage][id] ? getLanguages[getLanguage][id] : id;
}

export function getLocale(id) {
  const mx = 'es-MX';
  const getLanguage = JSON.parse(localStorage.getItem('app.settings.locale')) || mx;
  const getLanguages = {
    [mx]: spanish.messages,
    'en-US': english.messages,
  };
  return getLanguages[getLanguage] ? validInfo(getLanguages, getLanguage, id) : getLanguages[mx][id];
}

export const defaultPaths = {
  initialPath: '/billing/draft',
  error404: '/auth/404',
};

export const gridOneField = { xs: 24 };

export const gridTwoFields = { xs: 24, sm: 12 };

export const gridThreeFields = { xs: 24, md: 8 };

export const gridFourFields = { xs: 24, sm: 12, md: 6 };

export const styleInputNumber = { width: '100%', textAlign: 'right' };

export const propsInputCurrency = {
  min: 0,
  step: 1,
  formatter: value => `$ ${value}`,
  parser: value => value.replace('$', ''),
  style: styleInputNumber,
};

export const propsSelect = {
  allowClear: true,
  showSearch: true,
  ...selectFilterPropsV2,
};

export const propsSelectByOptions = {
  allowClear: true,
  showSearch: true,
  ...selectFilterPropsV2,
};

export const isEnter = (action) => {
  if (typeof action === 'object' && Object.keys(action).length > 0) {
    const { key, keyCode } = action;
    return key === 'Enter' || keyCode === 13;
  }
  return false;
};

export const getPorcentaje = (value, isImpuesto) => {
  const valor = Number(value);
  if (Number.isNaN(valor)) return 0;
  if (isImpuesto) return (valor * 100);
  return (valor * 100).toFixed(2);
};

export const getPorcDescuento = (descuento, importe) => ((Number(descuento) / Number(importe)) * 100).toFixed(2);

export const getDescuento = (decimalPorcentaje, importe) => (Number(importe) * (Number(decimalPorcentaje) / 100)).toFixed(2);

export function setSelectFocus(referencia) {
  if (referencia?.current?.focus) {
    referencia.current.focus();
  }
  if (referencia?.current?.select) {
    referencia.current.select();
  }
}

export function getTotal(info) {
  let total = 0;
  let totalRetenciones = 0;
  let totalTrasladados = 0;
  if (Array.isArray(info) && info.length > 0) {
    info.forEach((concepto) => {
      const {
        impuestos = {}, cantidad, valorUnitario, descuento = 0,
      } = concepto;
      // console.log('valor unitario', valorUnitario);
      const importe = (Number(cantidad) * Number(valorUnitario));
      // console.log('importe', importe);
      total += (Number(importe) - Number(descuento));
      const { retenciones = [], traslados = [] } = impuestos;
      const { desglose: dgRetencion } = getImporteImpuestos(retenciones, {
        ...concepto, importe: Number(valorUnitario),
      }, false);
      dgRetencion.forEach(({ importeImpuesto }) => {
        totalRetenciones += Number(importeImpuesto);
      });
      const { desglose: dgTraslado } = getImporteImpuestos(traslados, {
        ...concepto, importe: Number(valorUnitario),
      }, false);
      dgTraslado.forEach(({ importeImpuesto }) => {
        totalTrasladados += Number(importeImpuesto);
      });
    });
  }
  // console.log('total', total, totalRetenciones);
  const totalAll = (
    Number(total)
      + Number(totalTrasladados)
      - Number(totalRetenciones)
  );
  return (Math.round(totalAll * 100) / 100).toFixed(2);
}

export const callbackButtons = ({
  id, type, onClick, disabled, icon, ...rest
}) => (
  <Col key={id}>
    <Button {...rest} type={type} disabled={disabled} onClick={onClick}>
      { icon && icon }
      {' '}
      <FormattedMessage id={id} />
    </Button>
  </Col>
);

export function calcImporte({
  cantidad,
  importe,
  descuento,
  valorTasaOcuota,
}) {
  // console.log({
  //   cantidad,
  //   importe,
  //   descuento,
  //   valorTasaOcuota,
  // });
  const cero = 0;
  const newImporte = ((((Number(importe)) - Number(descuento)) * Number(cantidad)) * (Number(valorTasaOcuota)));
  // console.log('calcImporte', newImporte);
  return Number.isNaN(newImporte) ? cero.toFixed(2) : newImporte.toFixed(2);
}

export function getImporteIEPS({
  importe,
  descuento,
  tipoFactor,
  nombreImpuesto,
  valorTasaOcuota,
}, callback) {
  if (nombreImpuesto === enums.IEPS && callback) {
    callback(getImpuesto({
      importe,
      descuento,
      tipoFactor,
      valorTasaOcuota,
    }));
  }
}

export function getImporteIVA({
  importe,
  descuento,
  tipoFactor,
  nombreImpuesto,
  valorTasaOcuota,
}, callback) {
  if (nombreImpuesto === enums.IVA && callback) {
    callback(getImpuestoInverso({
      importe,
      descuento,
      tipoFactor,
      valorTasaOcuota,
    }));
  }
}

export function getImporteImpuesto({
  tipoImpuesto,
  nombreImpuesto,
  valorTasaOcuota,
}, concepto, inverso) {
  const {
    valorUnitario, importeImpuesto, descuento, cantidad,
  } = concepto;
  const importe = inverso ? importeImpuesto : valorUnitario;
  let hasDiscount = false;
  let impuesto = importe;
  const getIEPS = nombreImpuesto === enums.IVA && !inverso;
  const getIVA = nombreImpuesto === enums.IEPS && inverso;

  const newConcepts = tipoImpuesto === enums.retencion.value
    ? (concepto?.impuestos?.retenciones || [])
    : (concepto?.impuestos?.traslados || []);

  // console.log('-- Importe impuesto', {
  //   tipoImpuesto,
  //   nombreImpuesto,
  //   valorTasaOcuota,
  // }, concepto, inverso);

  if (getIEPS && newConcepts.length > 1) {
    newConcepts.forEach(({
      tipoFactor: tfc,
      nombreImpuesto: ni,
      valorTasaOcuota: vtoc,
    }) => {
      getImporteIEPS({
        importe,
        descuento,
        tipoFactor: tfc,
        nombreImpuesto: ni,
        valorTasaOcuota: vtoc,
      }, ({ importeConImpuesto }) => {
        hasDiscount = true;
        impuesto = importeConImpuesto;
      });
    });
  }

  if (getIVA && newConcepts.length > 1) {
    newConcepts.forEach(({
      tipoFactor: tfc,
      nombreImpuesto: ni,
      valorTasaOcuota: vtoc,
    }) => {
      getImporteIVA({
        importe,
        descuento,
        tipoFactor: tfc,
        nombreImpuesto: ni,
        valorTasaOcuota: vtoc,
      }, ({ impuesto: imp }) => {
        impuesto = imp;
        hasDiscount = true;
      });
    });
  }

  return calcImporte({
    cantidad,
    valorTasaOcuota,
    importe: impuesto,
    descuento: hasDiscount ? 0 : descuento,
  });
}

export const getSymbolTasa = factor => (factor === enums.Tasa ? ' %' : '');

export const getSymbolCuota = factor => (factor === enums.Cuota ? '$ ' : '');

export const getValorImpuestoTasaOcuota = ({ tipoFactor, valorTasaOcuota = 0 }, isImpuesto) => (tipoFactor === enums.Tasa
  ? getPorcentaje(valorTasaOcuota, isImpuesto)
  : Number(valorTasaOcuota));

export function iteraImpuestos(callback, concepto, inverso) {
  return ({
    impuesto,
    tipoFactor,
    tipoImpuesto,
    nombreImpuesto,
    valorTasaOcuota,
  }) => {
    const tasa = getSymbolTasa(tipoFactor);
    const valorImpuesto = getValorImpuestoTasaOcuota({ tipoFactor, valorTasaOcuota }, true);
    const key = `${nombreImpuesto}${tipoFactor === enums.Cuota ? '' : ` ${valorImpuesto}${tasa}`}`;
    if (callback) {
      const newData = {
        key,
        impuesto,
        totalImpuesto: tipoFactor === enums.Cuota
          ? Number(valorTasaOcuota)
          : getImporteImpuesto({
            tipoFactor,
            tipoImpuesto,
            nombreImpuesto,
            valorTasaOcuota,
          }, concepto, inverso),
      };
      callback(newData);
    }
  };
}

export function getTasaOcuota({
  importe,
  descuento,
  tipoFactor,
  valorTasaOcuota,
}) {
  return tipoFactor === enums.Tasa
    ? (Number(importe) - Number(descuento)) * (1 + Number(valorTasaOcuota))
    : (Number(importe) - Number(descuento)) + Number(valorTasaOcuota);
}

export function getImpuesto({
  // descuento,
  tipoFactor,
  importe = 0,
  valorTasaOcuota = 0,
}) {
  const isCuota = tipoFactor === enums.Cuota;
  const newImporte = Number(importe)/*  - Number(descuento) */;
  const impuesto = isCuota
    ? Number(valorTasaOcuota)
    : newImporte * Number(valorTasaOcuota);
  // console.log('- newImporte:', newImporte);
  return {
    impuesto,
    importeConImpuesto: newImporte + Number(impuesto),
  };
}


export function getImpuestoInverso({
  // descuento,
  tipoFactor,
  importe = 0,
  valorTasaOcuota = 0,
}) {
  const isCuota = tipoFactor === enums.Cuota;
  const newImporte = Number(importe)/* - Number(descuento) */;
  const importeSinImpuesto = isCuota
    ? newImporte - Number(valorTasaOcuota)
    : newImporte / (1 + Number(valorTasaOcuota));
  // console.log(isCuota, newImporte, importeSinImpuesto);
  // console.log({
  //   importeSinImpuesto,
  //   impuesto: newImporte - importeSinImpuesto,
  // });
  return {
    importeSinImpuesto,
    impuesto: newImporte - importeSinImpuesto,
  };
}

export function getImporteImpuestoIvaIeps({
  iva,
  ieps,
  importe,
  descuento,
}) {
  const { importeConImpuesto } = getImpuesto({ ...ieps, importe, descuento });
  return getImpuesto({ ...iva, descuento, importe: importeConImpuesto });
}

export function getImporteImpuestoIvaIepsInverso({
  iva,
  ieps,
  importe,
  descuento,
}) {
  const { importeSinImpuesto } = getImpuestoInverso({ ...iva, importe, descuento });
  return getImpuestoInverso({ ...ieps, descuento, importe: importeSinImpuesto });
}

export function getImporteImpuestos(impuestos, concepto, inverso, tipo) {
  const { importe, descuento, cantidad } = concepto;
  // console.log({ concepto });
  let desglose = [];
  let valorImpuestos = {};
  if (impuestos.length < 2) {
    const newImpuesto = impuestos.length === 0
      ? { descuento, importe }
      : { ...impuestos[0], descuento, importe };
    valorImpuestos = inverso
      ? getImpuestoInverso(newImpuesto)
      : getImpuesto(newImpuesto);
    desglose = impuestos.length > 0 ? [
      getInfoImpuesto({
        ...newImpuesto,
        importeImpuesto: roundTwo(valorImpuestos.impuesto * Number(cantidad)),
      }, tipo),
    ] : [];
  }

  if (impuestos.length >= 2) {
    const ieps = impuestos.filter(({ nombreImpuesto }) => nombreImpuesto === enums.IEPS)[0];
    const iva = impuestos.filter(({ nombreImpuesto }) => nombreImpuesto === enums.IVA)[0];
    const isr = impuestos.filter(({ nombreImpuesto }) => nombreImpuesto === enums.ISR)[0];
    console.log({ ieps, iva, isr });
    if (inverso) {
      const { impuesto, importeSinImpuesto } = getImpuestoInverso({ ...iva, importe, descuento });
      valorImpuestos = getImpuestoInverso({ ...ieps, descuento, importe: importeSinImpuesto });

      if (ieps) {
        desglose = [...desglose, getInfoImpuesto({
          ...ieps,
          importeImpuesto: roundTwo(valorImpuestos.impuesto * Number(cantidad)),
        }, tipo)];
      }

      if (iva) {
        desglose = [...desglose, getInfoImpuesto({
          ...iva,
          importeImpuesto: roundTwo(impuesto * Number(cantidad)),
        }, tipo)];
      }

      if (isr) {
        desglose = [...desglose, getInfoImpuesto({
          ...isr,
          importeImpuesto: roundTwo(impuesto * Number(cantidad)),
        }, tipo)];
      }
    } else {
      const { impuesto, importeConImpuesto } = getImpuesto({ ...ieps, importe, descuento });

      let traslados = 0;
      let retenciones = 0;

      if (ieps) {
        desglose = [...desglose, getInfoImpuesto({
          ...ieps,
          importeImpuesto: roundTwo(impuesto * Number(cantidad)),
        }, tipo)];
      }

      if (iva) {
        const vImpuestos = getImpuesto({ ...iva, descuento, importe: importeConImpuesto });
        desglose = [...desglose, getInfoImpuesto({
          ...iva,
          importeImpuesto: roundTwo(vImpuestos.impuesto * Number(cantidad)),
        }, tipo)];

        if (tipo === enums.retencion.value) {
          retenciones += vImpuestos.impuesto;
        } else {
          traslados += vImpuestos.impuesto;
        }
      }

      if (isr) {
        const vImpuestos = getImpuesto({ ...isr, descuento, importe: importeConImpuesto });
        desglose = [...desglose, getInfoImpuesto({
          ...isr,
          importeImpuesto: roundTwo(vImpuestos.impuesto * Number(cantidad)),
        }, tipo)];

        if (tipo === enums.retencion.value) {
          retenciones += vImpuestos.impuesto;
        } else {
          traslados += vImpuestos.impuesto;
        }
      }
    }
  }

  const values = {
    ...valorImpuestos,
    desglose,
  };
  console.log({ values });
  return values;
}

export function getInfoImpuesto({
  tipoFactor,
  nombreImpuesto,
  impuesto = 0,
  valorTasaOcuota = 0,
  importeImpuesto = 0,
}, tipo) {
  const tasa = getSymbolTasa(tipoFactor);
  const valorImpuesto = getValorImpuestoTasaOcuota({ tipoFactor, valorTasaOcuota }, true);
  const key = `${nombreImpuesto}${tipoFactor === enums.Cuota ? '' : ` ${valorImpuesto}${tasa}`} ${tipo?.substring(0, 1)}`;
  const newInfo = {
    key,
    impuesto,
    importeImpuesto,
    tipoFactor,
    tasaOcuota: `${valorTasaOcuota}`,
  };
  return newInfo;
}

export function getImporte({ cantidad = 0, valorUnitario = 0, descuento = 0 }) {
  const resultado = (cantidad * valorUnitario) - descuento;
  return Number.isNaN(resultado) ? 0 : resultado;
}
