import React from 'react';
import { Button } from 'antd';
import { ShopOutlined } from '@ant-design/icons';

const BuyStamps = ({ intl, history, showTitle = true }) => (
  <Button
    shape="round"
    onClick={() => history.push('/stamps/buy')}
    style={{
      background: '#84d9c3',
      borderColor: '#84d9c3',
      color: 'white',
    }}
  >
    <ShopOutlined /> {showTitle && intl.formatMessage({ id: 'topBar.buy.stamps' })}
  </Button>
);

export default BuyStamps;
