const actions = {
  DISABLED_BANK_ACCOUNT: 'bankAccounts/DISABLED',
  CLOSED_BANK_ACCOUNT: 'bankAccounts/CLOSED',
  LOADING_BANK_ACCOUNTS: 'bankAccounts/LOADING_BANK_ACCOUNTS',
  LOADING_BANK_ACCOUNT: 'bankAccounts/LOADING_BANK_ACCOUNT',
  CLEAN_ALL_BANK_ACCOUNT: 'bankAccounts/CLEAN_ALL',
  CLEAN_BANK_ACCOUNTS: 'bankAccounts/CLEAN_BANK_ACCOUNTS',
  CLEAN_BANK_ACCOUNT: 'bankAccounts/CLEAN_BANK_ACCOUNT',
  GET_ALL_BANK_ACCOUNT: 'bankAccounts/GET_ALL',
  FETCH_ALL_BANK_ACCOUNT: 'bankAccounts/FETCH_ALL',
  GET_BANK_ACCOUNT: 'bankAccounts/GET',
  FETCH_BANK_ACCOUNT: 'bankAccounts/FETCH',
  CREATE_BANK_ACCOUNT: 'bankAccounts/CREATE',
  UPDATE_BANK_ACCOUNT: 'bankAccounts/UPDATE',
  DELETE_BANK_ACCOUNT: 'bankAccounts/DELETE',
};

export default actions;
