import { all /* , put, call  */ } from 'redux-saga/effects';
import { setTitlePage, getAppFull } from '../../helpers/index';

export function* SET_TITLE(title) {
  yield setTitlePage(getAppFull)(title);
}

export default function* rootSaga() {
  yield all([
    SET_TITLE(), // run once on app load to fetch menu data
  ]);
}
