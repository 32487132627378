// import actions from './actions'

const initialState = {
  configData: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
