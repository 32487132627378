import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Popover,
  Button,
  Rate,
  Image,
  // Affix,
  Space,
  Input,
} from 'antd';
import {
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import Crud from '../../../services/fetch';
import surveyIcon from '../../../assets/icons/survey.svg';
// import sad from '../../../assets/icons/1-sad.svg';
// import neutral from '../../../assets/icons/2-neutral.svg';
// import happy from '../../../assets/icons/3-happy.svg';
// import love from '../../../assets/icons/4-love.svg';

const { TextArea } = Input;

const npsFetch = new Crud('nps');

function Icon({ src }) {
  return (
    <Image
      preview={false}
      width={20}
      src={src}
    />
  );
}

function NPS({
  user, intl, dispatch, openSurvey,
}) {
  const title = intl.formatMessage({ id: 'nps.title' });

  const cleanState = {
    value: 0,
    comments: '',
  };

  const [state, setState] = useState(cleanState);
  const [disabled, setDisabled] = useState(false);
  const [visible, setVisible] = useState(false);

  // const customIcons = {
  //   1: <Icon src={sad} />,
  //   2: <Icon src={neutral} />,
  //   3: <Icon src={happy} />,
  //   4: <Icon src={love} />,
  // };
  const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
  };

  const content = (
    <div style={{ textAlign: 'center' }}>
      <Rate
        defaultValue={state.value}
        disabled={disabled}
        character={({ index }) => customIcons[index + 1]}
        onChange={value => setState((st) => {
          const newValues = { ...st, value };
          sendNPS(newValues);
          return newValues;
        })}
      />
      <hr />
      <TextArea
        rows={4}
        disabled={disabled}
        value={state.comments}
        placeholder={intl.formatMessage({ id: 'nps.comments' })}
        onChange={({ target: { value } }) => {
          setState(st => ({ ...st, comments: value }));
        }}
      />
      <div style={{ marginTop: '10px' }} />
      <Space direction="horizontal">
        <Button
          size="small"
          disabled={disabled}
          onClick={() => {
            setVisible(false);
            dispatch({ type: 'settings/OPEN_SURVEY', payload: false });
          }}
        >
          {intl.formatMessage({ id: 'nps.close' })}
        </Button>
        <Button
          type="primary"
          size="small"
          disabled={disabled}
          onClick={() => sendNPS(state, true)}
        >
          {intl.formatMessage({ id: 'nps.send' })}
        </Button>
      </Space>
    </div>
  );

  async function sendNPS(values, close) {
    setDisabled(true);
    const info = await npsFetch.Post(values);
    if (info) getInfo();
    setDisabled(false);
    if (close) setVisible(false);
    dispatch({ type: 'settings/SET_SURVEY', payload: false });
    dispatch({ type: 'settings/OPEN_SURVEY', payload: false });
  }

  function getInfo() {
    if (user.id) {
      (async () => {
        setDisabled(true);
        try {
          const info = await npsFetch.Get('', false);
          if (info?.data) {
            setState(info.data);
          }
        } catch ({ message }) {
          dispatch({ type: 'settings/SET_SURVEY', payload: true });
          console.error(message);
        }
        setDisabled(false);
      })();
    }
  }

  function setOpen() {
    if (openSurvey) {
      setVisible(true);
    }
  }

  useEffect(getInfo, [user.id]);
  useEffect(setOpen, [openSurvey]);

  return user.id ? (
    <Popover
      visible={visible}
      content={content}
      title={title}
      trigger="hover"
    >
      <Button
        disabled={disabled}
        onMouseEnter={() => setVisible(!disabled)}
        type="primary"
        shape="round"
        icon={<Icon src={surveyIcon} />}
      />
    </Popover>
  ) : <></>;
}

const mapStateToProps = ({ user, dispatch, settings }) => ({
  dispatch,
  user,
  openSurvey: settings.openSurvey,
});

export default injectIntl(connect(mapStateToProps)(NPS));
