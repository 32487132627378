import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import styles from './style.module.scss';

const getLanguages = [
  { value: 'es-MX', abv: 'ES', label: <FormattedMessage id="locale.es-MX" /> },
  { value: 'en-US', abv: 'EN', label: <FormattedMessage id="locale.en-US" /> },
];

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
});

const LanguageSwitcher = ({ dispatch, locale }) => {
  const changeLanguage = ({ key }) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    });
    window.location.reload();
  };
  const language = locale.substr(0, 2);
  const menu = (
    <Menu selectedKeys={[locale]} onClick={changeLanguage}>
      {getLanguages.map(({ value, label, abv }) => (
        <Menu.Item key={value}>
          <span className="text-uppercase font-size-12 mr-2">{abv}</span>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <span className="text-uppercase">{language}</span>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(LanguageSwitcher);
