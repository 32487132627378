// Deprecated: use selectFilterPropsV2 instead
export const selectFilterProps = {
  filterOption: (input, option) => {
    if (Array.isArray(option?.children)) {
      return option?.children?.join(',')?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
    }
    return option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
  },
  filterSort: (optionA, optionB) => {
    if (Array.isArray(optionA?.children)) {
      return optionA.children.join(',').toLowerCase().localeCompare(optionB.children.join(', ').toLowerCase());
    }
    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase());
  },
};

export const selectFilterPropsV2 = {
  filterOption: (input, option) => (option?.children ?? '')?.toLowerCase().includes(input?.toLowerCase()),
  filterSort: (optionA, optionB) => (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase()),
};

export default {};
