import React from 'react';
import {
  all, put, takeEvery, select,
} from 'redux-saga/effects';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from './actions';
import Crud from '../../services/fetch';

import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';

const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };

const clients = new Crud('receiver');

const logEventClient = sendLogEvent(logEvent, analytics);

// url/receiver/all

export function* GET_ALL_CLIENT() {
  yield put({ type: actions.LOADING_CLIENTS });
  const info = yield clients.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_CLIENT,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_CLIENT({ id }) {
  yield put({ type: actions.LOADING_CLIENT });
  const info = yield clients.Get(`one/${id}`);
  if (info) {
    yield put({
      type: actions.GET_CLIENT,
      payload: info,
      loading: false,
    });
  }
}

export function* CREATE_CLIENT({ data }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CLIENT, payload: false });
  yield put({ type: actions.DISABLED_CLIENT, payload: true });
  const info = yield clients.Post(data);
  yield put({ type: actions.DISABLED_CLIENT, payload: false });
  if (info) {
    logEventClient('create_client', {
      ...analyticEventParams,
      clientID: info.id,
    });
    yield put({ type: actions.CLOSED_CLIENT, payload: true });
    yield GET_ALL_CLIENT();
  }
}

export function* UPDATE_CLIENT({ data }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CLIENT, payload: false });
  yield put({ type: actions.DISABLED_CLIENT, payload: true });
  const info = yield clients.Put(data);
  yield put({ type: actions.DISABLED_CLIENT, payload: false });
  if (info) {
    logEventClient('update_client', {
      ...analyticEventParams,
      clientID: data.id,
    });
    yield put({ type: actions.CLOSED_CLIENT, payload: true });
    yield GET_ALL_CLIENT();
  }
}

export function* DELETE_CLIENT({ id, ok, modal }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(deleteWaiting);
  const del = yield clients.Delete(id);
  if (modal) modal.destroy();
  if (del) {
    logEventClient('delete_client', {
      ...analyticEventParams,
      clientID: id,
    });
    yield GET_ALL_CLIENT();
  }
  if (ok) ok();
}

export function* CREATE_CIF_CLIENT({ id, data, getInfo }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_CLIENT, payload: true });
  const info = yield clients.PostUrl(`cif/${id}`, data);
  yield put({ type: actions.DISABLED_CLIENT, payload: false });
  if (info) {
    logEventClient('update_client', {
      ...analyticEventParams,
      clientID: id,
    });
    if (getInfo) { getInfo(data); }
  }
}

export function* UPDATE_CIF_CLIENT({ data }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CLIENT, payload: false });
  yield put({ type: actions.DISABLED_CLIENT, payload: true });
  const info = yield clients.PutUrl('cif', data);
  yield put({ type: actions.DISABLED_CLIENT, payload: false });
  if (info) {
    logEventClient('update_client', {
      ...analyticEventParams,
      clientID: data.id,
    });
    yield put({ type: actions.CLOSED_CLIENT, payload: true });
    yield GET_ALL_CLIENT();
  }
}

export function* UPDATE_CONTACTS_CLIENT({ data }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CLIENT, payload: false });
  yield put({ type: actions.DISABLED_CLIENT, payload: true });
  const info = yield clients.PutUrl('contacts', data);
  yield put({ type: actions.DISABLED_CLIENT, payload: false });
  if (info) {
    logEventClient('update_contacts_client', {
      ...analyticEventParams,
      clientID: data.id,
    });
    yield put({ type: actions.CLOSED_CLIENT, payload: true });
    yield GET_ALL_CLIENT();
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_CLIENT, GET_ALL_CLIENT),
    takeEvery(actions.FETCH_CLIENT, GET_CLIENT),
    takeEvery(actions.CREATE_CLIENT, CREATE_CLIENT),
    takeEvery(actions.UPDATE_CLIENT, UPDATE_CLIENT),
    takeEvery(actions.DELETE_CLIENT, DELETE_CLIENT),
    takeEvery(actions.CREATE_CIF_CLIENT, CREATE_CIF_CLIENT),
    takeEvery(actions.UPDATE_CIF_CLIENT, UPDATE_CIF_CLIENT),
    takeEvery(actions.UPDATE_CONTACTS_CLIENT, UPDATE_CONTACTS_CLIENT),
  ]);
}
