import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  Menu, Dropdown, Avatar, Badge,
} from 'antd';
import styles from './style.module.scss';

const mapStateToProps = ({ user }) => ({ user });

const ProfileMenu = ({ dispatch, user, FormattedMessage }) => {
  const history = useHistory();
  const [count, setCount] = useState(0);

  const logout = (e) => {
    e.preventDefault();
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  const addCount = () => {
    setCount(count);
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item key="1">
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
        </strong>
        {/* <div>
          <strong className="mr-1">
            <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
          </strong>
          Professional
        </div>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role || '—'}
        </div> */}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.email || '—'}
          {/* <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {user.phone || '—'} */}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            history.push('/me');
          }}
        >
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="4">
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  );

  const profilePicture = user?.db?.profilePicture ? {
    src: user?.db?.profilePicture,
  } : { icon: <UserOutlined /> };

  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar {...profilePicture} className={styles.avatar} shape="circle" size="large" />
        </Badge>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
