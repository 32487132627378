
export const onlyUser = 'User';

export const onlyOwner = 'Owner';

export const onlyAdministrator = 'Administrator';

export const allAccess = [
  onlyUser,
  onlyOwner,
  onlyAdministrator,
];
