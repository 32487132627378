import React from 'react';
import _ from 'lodash';
import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';

import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';

const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };

const webhook = new Crud('hook');

export function* GET_ALL_WEBHOOK() {
  // yield put({ type: actions.CLEAN_ALL_WEBHOOK });
  yield put({ type: actions.LOADING_WEBHOOKS, payload: true });
  const info = yield webhook.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_WEBHOOK,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_WEBHOOKS, payload: false });
  }
}

export function* GET_ALL_WEBHOOK_LOG({ id }) {
  // yield put({ type: actions.CLEAN_ALL_WEBHOOK_LOG });
  yield put({ type: actions.LOADING_WEBHOOKS_LOG, payload: true });
  const info = yield webhook.Get(`all-logs/${id}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_WEBHOOK_LOG,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_WEBHOOKS_LOG, payload: false });
  }
}

export function* GET_WEBHOOK({ id }) {
  // yield put({ type: actions.CLEAN_ALL_WEBHOOK });
  yield put({ type: actions.LOADING_WEBHOOK, payload: true });
  const info = yield webhook.Get(`one/${id}`);
  if (info) {
    yield put({
      type: actions.GET_WEBHOOK,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_WEBHOOK, payload: false });
  }
}

export function* CREATE_WEBHOOK({ data }) {
  yield put({ type: actions.DISABLED_WEBHOOK, payload: true });
  const info = yield webhook.Post(data);
  if (info) {
    yield put({ type: actions.CLOSED_WEBHOOK, payload: true });
    yield GET_ALL_WEBHOOK();
  }
  yield put({ type: actions.DISABLED_WEBHOOK, payload: false });
  yield put({ type: actions.CLOSED_WEBHOOK, payload: false });
}

export function* UPDATE_WEBHOOK({ data }) {
  yield put({ type: actions.DISABLED_WEBHOOK, payload: true });
  const info = yield webhook.Put(data);
  if (info) {
    yield put({ type: actions.CLOSED_WEBHOOK, payload: true });
    yield GET_ALL_WEBHOOK();
  }
  yield put({ type: actions.DISABLED_WEBHOOK, payload: false });
  yield put({ type: actions.CLOSED_WEBHOOK, payload: false });
}

export function* DELETE_WEBHOOK({ id, modal, ok }) {
  if (modal) modal.confirm(deleteWaiting);
  const info = yield webhook.Delete(id);
  if (modal) modal.destroy();
  if (info) yield GET_ALL_WEBHOOK();
  if (ok) ok();
}

export function* STATUS_WEBHOOK({ id }) {
  yield webhook.PostUrl(`status/${id}`, {}, false);
  yield GET_ALL_WEBHOOK();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_WEBHOOK, GET_ALL_WEBHOOK),
    takeEvery(actions.FETCH_WEBHOOK, GET_WEBHOOK),
    // takeEvery(actions.FETCH_WEBHOOK_LOG, GET_WEBHOOK_LOG),
    takeEvery(actions.FETCH_ALL_WEBHOOK_LOG, GET_ALL_WEBHOOK_LOG),
    takeEvery(actions.CREATE_WEBHOOK, CREATE_WEBHOOK),
    takeEvery(actions.UPDATE_WEBHOOK, UPDATE_WEBHOOK),
    takeEvery(actions.DELETE_WEBHOOK, DELETE_WEBHOOK),
    takeEvery(actions.STATUS_WEBHOOK, STATUS_WEBHOOK),
  ]);
}
