import React from 'react';
import {
  all, put, takeEvery, select,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from './actions';
import Crud from '../../services/fetch';

import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';
import { toQueryString } from '../../helpers/transformer';

const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };

const issuers = new Crud('issuer');

const logEventIssuer = sendLogEvent(logEvent, analytics);

export function* GET_ALL_ISSUER({ callback, filter = {} }) {
  const params = toQueryString(filter);
  yield put({ type: actions.LOADING_ISSUERS });
  const info = yield issuers.Get(`all${params}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_ISSUER,
      payload: info,
      loading: false,
    });
    if (callback) callback();
  }
}

export function* GET_ISSUER({ id }) {
  yield put({ type: actions.LOADING_ISSUER });
  const info = yield issuers.Get(`one/${id}`);
  if (info) {
    yield put({
      type: actions.GET_ISSUER,
      payload: info,
      loading: false,
    });
  }
}

export function* CREATE_ISSUER({ data }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_ISSUER, payload: false });
  yield put({ type: actions.DISABLED_ISSUER, payload: true });
  const info = yield issuers.Post(data);
  yield put({ type: actions.DISABLED_ISSUER, payload: false });
  if (info) {
    logEventIssuer('create_issuer', {
      ...analyticEventParams,
      issuerID: info.id,
    });
    yield put({ type: actions.CLOSED_ISSUER, payload: true });
    yield GET_ALL_ISSUER({});
  }
}

export function* UPDATE_ISSUER({ data, intl }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_ISSUER, payload: false });
  yield put({ type: actions.DISABLED_ISSUER, payload: true });
  const info = yield issuers.Put(data);
  yield put({ type: actions.DISABLED_ISSUER, payload: false });
  if (info) {
    logEventIssuer('update_issuer', {
      ...analyticEventParams,
      issuerID: data.id,
    });
    notification.success({
      message: intl.formatMessage({ id: 'issuer.title.update.success' }),
      description: intl.formatMessage({ id: 'issuer.description.update.success' }),
    });
    yield put({ type: actions.CLOSED_ISSUER, payload: true });
    yield GET_ALL_ISSUER({});
  }
}

export function* DELETE_ISSUER({ id, modal, ok }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(deleteWaiting);
  const del = yield issuers.Delete(id);
  if (modal) modal.destroy();
  if (del) {
    logEventIssuer('delete_issuer', {
      ...analyticEventParams,
      issuerID: id,
    });
    yield GET_ALL_ISSUER({});
  }
  if (ok) ok();
}

export function* DELETE_ISSUER_CSD({ id, modal, ok }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(deleteWaiting);
  const del = yield issuers.Delete(`csd/${id}`);
  if (modal) modal.destroy();
  if (del) {
    logEventIssuer('delete_issuer_csd', {
      ...analyticEventParams,
      issuerID: id,
    });
    yield GET_ISSUER({ id });
  }
  if (ok) ok();
}

export function* DELETE_ISSUER_FIEL({ id, modal, ok }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (modal) modal.confirm(deleteWaiting);
  const del = yield issuers.Delete(`fiel/${id}`);
  if (modal) modal.destroy();
  if (del) {
    logEventIssuer('delete_issuer_fiel', {
      ...analyticEventParams,
      issuerID: id,
    });
    yield GET_ISSUER({ id });
  }
  if (ok) ok();
}

export function* BUY_BILLING_STAMPS({ values }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_ISSUER, payload: false });
  yield put({ type: actions.DISABLED_ISSUER, payload: true });
  const info = yield issuers.PostUrl('buy-billing-stamps', values);
  yield put({ type: actions.DISABLED_ISSUER, payload: false });
  if (info) {
    logEventIssuer('buy_billing_stamps', {
      ...analyticEventParams,
      buyBillingStampsID: info.id,
    });
    yield put({ type: actions.CLOSED_ISSUER, payload: true });
    yield GET_ALL_ISSUER({});
  }
}

export function* GET_PURCHASE_HISTORY({ id }) {
  yield put({ type: actions.LOADING_PURCHASE_HISTORY });
  const info = yield issuers.Get(`billing-stamps-from-emisor/${id}`);
  if (info) {
    yield put({
      type: actions.GET_PURCHASE_HISTORY,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_CREDITS_ISSUER({ id }) {
  yield put({ type: actions.LOADING_CREDITS_ISSUER });
  const info = yield issuers.Get(`stamps/one/${id}`);
  if (info) {
    yield put({
      type: actions.GET_CREDITS_ISSUER,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_CREDITS_ALL_ISSUERS() {
  yield put({ type: actions.LOADING_CREDITS_ALL_ISSUERS });
  const info = yield issuers.Get('stamps/all');
  if (info) {
    yield put({
      type: actions.GET_CREDITS_ALL_ISSUERS,
      payload: info,
      loading: false,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_ISSUER, GET_ALL_ISSUER),
    takeEvery(actions.FETCH_ISSUER, GET_ISSUER),
    takeEvery(actions.CREATE_ISSUER, CREATE_ISSUER),
    takeEvery(actions.UPDATE_ISSUER, UPDATE_ISSUER),
    takeEvery(actions.DELETE_ISSUER, DELETE_ISSUER),
    takeEvery(actions.DELETE_ISSUER_CSD, DELETE_ISSUER_CSD),
    takeEvery(actions.DELETE_ISSUER_FIEL, DELETE_ISSUER_FIEL),
    takeEvery(actions.BUY_BILLING_STAMPS, BUY_BILLING_STAMPS),
    takeEvery(actions.FETCH_PURCHASE_HISTORY, GET_PURCHASE_HISTORY),
    takeEvery(actions.FETCH_CREDITS_ISSUER, GET_CREDITS_ISSUER),
    takeEvery(actions.FETCH_CREDITS_ALL_ISSUERS, GET_CREDITS_ALL_ISSUERS),
  ]);
}
