export function toQueryString(obj) {
  if (!obj) return '';
  if (Object.keys(obj).length === 0) return '';
  const params = Object.keys(obj).map((key) => {
    let value = obj[key];
    if (typeof value === 'object' && value !== null) {
      value = JSON.stringify(value);
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }).join('&');
  return `?${params}`;
}

export default undefined;
