import {
  all, put, takeEvery, select,
} from 'redux-saga/effects';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from './actions';
import Crud from '../../services/fetch';

const conceptDescription = new Crud('concept-description');

const logEventClient = sendLogEvent(logEvent, analytics);

export function* GET_ALL_CONCEPT_DESCRIPTIONS({ filter }) {
  yield put({ type: actions.LOADING_CONCEPT_DESCRIPTIONS, payload: true });
  let params = '';
  if (filter) {
    params = '?';
    params += new URLSearchParams(filter).toString();
  }
  conceptDescription.Cancel();
  conceptDescription.SetCancelToken();
  const info = yield conceptDescription.Get(`all${params}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_CONCEPT_DESCRIPTIONS,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_CONCEPT_DESCRIPTIONS, payload: false });
  }
}

export function* UPDATE_CONCEPT_DESCRIPTION({ data, filter, close }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CONCEPT_DESCRIPTION, payload: false });
  yield put({ type: actions.DISABLED_CONCEPT_DESCRIPTION, payload: true });
  const info = yield conceptDescription.Put(data);
  yield put({ type: actions.DISABLED_CONCEPT_DESCRIPTION, payload: false });
  if (info) {
    logEventClient('update_concept_descriptions', {
      ...analyticEventParams,
      conciliacionID: data.id,
    });
    yield put({ type: actions.CLOSED_CONCEPT_DESCRIPTION, payload: true });
    yield put({ type: actions.FETCH_ALL_CONCEPT_DESCRIPTIONS, filter });
    if (close) close();
  }
}

export function* ADD_CONCEPT_DESCRIPTION({ data, filter, close }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_CONCEPT_DESCRIPTION, payload: false });
  yield put({ type: actions.DISABLED_CONCEPT_DESCRIPTION, payload: true });
  const info = yield conceptDescription.Post(data);
  yield put({ type: actions.DISABLED_CONCEPT_DESCRIPTION, payload: false });
  if (info) {
    logEventClient('add_concept_descriptions', {
      ...analyticEventParams,
      conciliacionID: data.id,
    });
    yield put({ type: actions.CLOSED_CONCEPT_DESCRIPTION, payload: true });
    yield put({ type: actions.FETCH_ALL_CONCEPT_DESCRIPTIONS, filter });
    if (close) close();
  }
}

export function* DELETE_CONCEPT_DESCRIPTION({ data, filter, close }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.DISABLED_CONCEPT_DESCRIPTION, payload: true });
  const info = yield conceptDescription.Delete(data.id);
  yield put({ type: actions.DISABLED_CONCEPT_DESCRIPTION, payload: false });
  if (info) {
    logEventClient('delete_concept_descriptions', {
      ...analyticEventParams,
      conciliacionID: data.id,
    });
    yield put({ type: actions.FETCH_ALL_CONCEPT_DESCRIPTIONS, filter });
    if (close) close();
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_CONCEPT_DESCRIPTIONS, GET_ALL_CONCEPT_DESCRIPTIONS),
    takeEvery(actions.ADD_CONCEPT_DESCRIPTION, ADD_CONCEPT_DESCRIPTION),
    takeEvery(actions.UPDATE_CONCEPT_DESCRIPTION, UPDATE_CONCEPT_DESCRIPTION),
    takeEvery(actions.DELETE_CONCEPT_DESCRIPTION, DELETE_CONCEPT_DESCRIPTION),
  ]);
}
