const actions = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
  SET_PRIMARY_COLOR: 'settings/SET_PRIMARY_COLOR',
  SET_THEME: 'settings/SET_THEME',
  SET_SURVEY: 'settings/SET_SURVEY',
  OPEN_SURVEY: 'settings/OPEN_SURVEY',
};

export default actions;
