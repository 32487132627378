import _ from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import actions from './actions';

const cleanWebhook = { count: 0, data: [], loading: false };

const initialState = {
  close: false,
  disabled: false,
  webhooks: {
    entities: cleanWebhook,
    logs: cleanWebhook,
  },
  webhook: { loading: false },
  log: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_WEBHOOK:
      return initialState;
    case actions.DISABLED_WEBHOOK:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_WEBHOOK:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_WEBHOOKS:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          entities: {
            ...initialState.webhooks.data,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_WEBHOOKS_LOG:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          logs: {
            ...initialState.webhooks.logs,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_WEBHOOK:
      return {
        ...state,
        webhook: {
          ...initialState.webhook,
          loading: action.payload,
        },
      };
    case actions.CLEAN_WEBHOOKS:
      return {
        ...state,
        webhooks: {},
        loading: initialState.webhooks,
      };
    case actions.CLEAN_WEBHOOK:
      return {
        ...state,
        webhook: initialState.webhook,
      };
    case actions.GET_WEBHOOK:
      return {
        ...state,
        webhook: {
          ...action.payload,
          data: (action.payload.data || {}),
        },
      };
    case actions.GET_WEBHOOK_LOG:
      return {
        ...state,
        log: {
          ...action.payload,
          data: (action.payload.data || {}),
        },
      };
    case actions.GET_ALL_WEBHOOK:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          entities: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []).map(({ id, ...rest }) => ({
              key: id,
              id,
              ...rest,
            })),
          },
        },
      };
    case actions.GET_ALL_WEBHOOK_LOG:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          logs: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []).map(({ id, ...rest }) => ({
              key: id,
              id,
              ...rest,
            })),
          },
        },
      };
    default:
      return state;
  }
}
