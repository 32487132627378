import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  conceptDescriptions: {
    count: 0, data: [], byID: {}, loading: false,
  },
  conceptDescription: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_CONCEPT_DESCRIPTIONS:
      return initialState;
    case actions.DISABLED_CONCEPT_DESCRIPTION:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_CONCEPT_DESCRIPTION:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_CONCEPT_DESCRIPTIONS:
      return {
        ...state,
        conceptDescriptions: {
          ...initialState.conceptDescriptions,
          loading: action.payload,
        },
      };
    case actions.LOADING_CONCEPT_DESCRIPTION:
      return {
        ...state,
        conceptDescription: {
          ...initialState.conceptDescription,
          loading: action.payload,
        },
      };
    case actions.CLEAN_CONCEPT_DESCRIPTIONS:
      return {
        ...state,
        conceptDescriptions: {},
        loading: initialState.conceptDescriptions,
      };
    case actions.CLEAN_CONCEPT_DESCRIPTION:
      return {
        ...state,
        conceptDescription: initialState.conceptDescription,
      };
    case actions.GET_CONCEPT_DESCRIPTION:
      return {
        ...state,
        conceptDescription: action.payload,
      };
    case actions.GET_ALL_CONCEPT_DESCRIPTIONS:
      return {
        ...state,
        conceptDescriptions: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(conceptDescription => ({
            ...conceptDescription,
            key: conceptDescription.id,
          })),
        },
      };
    default:
      return state;
  }
}
