
const rgxOnlyLetters = /^([a-zA-Z-_]+)/g;

export function getEventName(event) {
  let eventName = '';
  const path = window.location.pathname.split('/');
  (path || []).forEach((item) => {
    if (rgxOnlyLetters.test(item)) {
      eventName += `${item.replace(rgxOnlyLetters, '$1')}_`;
    }
  });
  return `${eventName}${event}`;
}

export function sendLogEvent(logEvent, analytics) {
  return (eventName, eventParams) => {
    if (logEvent && analytics) {
      logEvent(analytics, getEventName(eventName), eventParams);
    }
  };
}
