import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import {
  ExclamationCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  Select,
  Space,
  Button,
  Upload,
} from 'antd';

const { Option } = Select;
const { Dragger } = Upload;

export const groupDivider = ['group', 'divider'];

export const handleCleanValues = (catTasaOcuota = []) => {
  const tasaOcuota = catTasaOcuota.find(({ rangoFijo, impuesto, factor }) => (
    rangoFijo === 'Fijo' && impuesto === 'IVA' && factor === 'Tasa'
  ));
  return {
    id: '',
    pdfLanguage: 'es',
    exchangeRate: 1.00,
    fechaExpedicion: moment(),
    conceptos: [{
      key: uuid(),
      descripcion: '',
      descripcionClaveProdServ: '',
      withCfdiRelacionado: false,
      preciosConImpuesto: false,
      // Generar endpoint para obtener valores //
      symbol: 'E48',
      unidad: 'Unidad de servicio',
      descripcionClaveUnidad: 'Unidad de servicio (E48)',
      claveUnidad: 'E48',
      // ------------------------------------ //
      cantidad: 1,
      valorUnitario: 0.00,
      importe: 0.00,
      descuento: 0.00,
      porcDescuento: 0.00,
      objetoImpCode: '02',
      objetoImpDescription: 'Sí objeto de impuesto.',
      impuestos: {
        traslados: [
          {
            key: uuid(),
            tasaOcuota: tasaOcuota?.id || '',
            valorTasaOcuota: 0.16,
            valorTasaOcuotaPorc: 16,
            impuesto: '002',
            nombreImpuesto: 'IVA',
            tipoImpuesto: 'traslado',
            rangoFijo: 'Fijo',
            tipoFactor: 'Tasa',
          },
        ],
      },
    }],
  };
};

// const cleanDecimalCurrency = {
//   min: 0.0001,
//   decimals: 4,
// };

export const cleanOpenSearch = {
  claveProdServ: false,
  claveUnidades: false,
};

export const cleanEmailProps = { visible: false };

export const cleanAdditionalProps = { visible: false };

export const callbackSelect = ({ key, description, code }) => (
  <Option key={key} value={key}>
    {`${description} (${code})`}
  </Option>
);

export const callbackSelectCode = ({ description, code }) => (
  <Option key={code} value={code}>
    {`${description} (${code})`}
  </Option>
);

export const callbackSelectSimple = value => (
  <Option key={value} value={value}>
    {value}
  </Option>
);

export const callbackBusiness = (omitTradeName = false) => ({
  key,
  taxId,
  tradeName,
  clientCode,
  businessName,
}) => {
  const codigo = clientCode ? `(${clientCode}) ` : '';
  const sameName = businessName === tradeName ? businessName : `${businessName} - ${tradeName}`;
  const bussinesFormat = businessName ? sameName : tradeName;
  const name = omitTradeName ? (businessName || tradeName) : bussinesFormat;
  return (
    <Option key={key} value={key}>
      {`${codigo}(${taxId}) ${name}`}
    </Option>
  );
};

export const SelectAddOption = ({
  text,
  menu,
  onClick,
  textEdit,
  onClickEdit,
}) => (
  <div>
    {menu}
    <div
      style={{
        padding: '8px',
        marginTop: '5px',
        display: 'flex',
        flexWrap: 'nowrap',
        borderTop: '1px solid rgba(0,0,0,0.4)',
      }}
    >
      <Space direction="horizontal">
        <Button
          type="primary"
          onClick={onClick}
        >
          <FormattedMessage id={text} />
        </Button>
        <Button
          type="primary"
          onClick={onClickEdit}
        >
          <FormattedMessage id={textEdit} />
        </Button>
      </Space>
    </div>
  </div>
);

export const dragAndDropProps = {
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    // if (status === 'done') {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};

export const openDragAndDropModal = ({ modal, intl }) => {
  modal.confirm({
    okType: 'primary',
    icon: <ExclamationCircleOutlined />,
    title: intl.formatMessage({ id: 'modal.billing.uploadExcel.title' }),
    footer: null,
    content: (
      <Dragger {...dragAndDropProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{intl.formatMessage({ id: 'modal.billing.uploadExcel.dragTitle' })}</p>
      </Dragger>
    ),
  });
};

export const rankOfDate = {
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month'),
};

export const cleanPagination = {
  page: 1,
  limit: 10,
};

export const defaultDatesFilter = { rankOfDate };

export const getLocalInvoiceFilter = () => {
  const filter = window.localStorage.getItem('app.invoice.filter');
  return !filter && Object.keys(filter || {}).length === 0 ? {
    ...rankOfDate, ...cleanPagination,
  } : JSON.parse(filter);
};

export const getLocalInvoiceFilterFormat = () => {
  const filter = getLocalInvoiceFilter();
  const { startDate, endDate, ...rest } = filter;
  return {
    rankOfDate: { startDate, endDate },
    ...rest,
  };
};

export const setLocalInvoiceFilter = (value) => {
  const filter = value || {};
  window.localStorage.setItem('app.invoice.filter', JSON.stringify(filter));
};

export function getInfo(dispatch, type, isNotDraft = true) {
  return (fil, pagination = cleanPagination) => {
    let filter = fil;
    if (!fil) {
      const localFilter = getLocalInvoiceFilter();
      filter = localFilter || (isNotDraft ? { rankOfDate, ...pagination } : {});
    }
    const {
      startDate, endDate, page, limit, ...f
    } = filter;
    const rest = isNotDraft ? { ...pagination, rankOfDate: { startDate, endDate } } : {};
    if (dispatch) dispatch({ type, filter: { ...f, ...rest } });
  };
}

export function getValidateStamp(current) {
  let hours;
  const weekday = moment().isoWeekday();
  switch (weekday) {
    case 2:
    case 1:
      hours = moment().add(-120, 'hours');
      break;
    default:
      hours = moment().add(-72, 'hours');
      break;
  }
  return current && (current <= hours || current > moment());
}
