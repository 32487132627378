const actions = {
  DISABLED_CONCEPT_DESCRIPTION: 'conceptdescriptions/DISABLED',
  CLOSED_CONCEPT_DESCRIPTION: 'conceptdescriptions/CLOSED',
  LOADING_CONCEPT_DESCRIPTIONS: 'conceptdescriptions/LOADING_CONCEPT_DESCRIPTIONS',
  LOADING_CONCEPT_DESCRIPTION: 'conceptdescriptions/LOADING_CONCEPT_DESCRIPTION',
  CLEAN_ALL_CONCEPT_DESCRIPTIONS: 'conceptdescriptions/CLEAN_ALL',
  CLEAN_CONCEPT_DESCRIPTIONS: 'conceptdescriptions/CLEAN_CONCEPT_DESCRIPTIONS',
  CLEAN_CONCEPT_DESCRIPTION: 'conceptdescriptions/CLEAN_CONCEPT_DESCRIPTION',
  GET_ALL_CONCEPT_DESCRIPTIONS: 'conceptdescriptions/GET_ALL',
  FETCH_ALL_CONCEPT_DESCRIPTIONS: 'conceptdescriptions/FETCH_ALL',
  GET_CONCEPT_DESCRIPTION: 'conceptdescriptions/GET',
  FETCH_CONCEPT_DESCRIPTION: 'conceptdescriptions/FETCH',
  ADD_CONCEPT_DESCRIPTION: 'conceptdescriptions/ADD',
  UPDATE_CONCEPT_DESCRIPTION: 'conceptdescriptions/UPDATE',
  DELETE_CONCEPT_DESCRIPTION: 'conceptdescriptions/DELETE',
};

export default actions;
