import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import Layout from 'layouts';

import useIsCurrentCompanyOwner from './hooks/company/useIsCurrentCompanyOwner';

import { defaultPaths } from './helpers';
import {
  onlyUser,
  allAccess,
  onlyAdministrator,
} from './helpers/roles';

const { initialPath, error404 } = defaultPaths;

const routes = [
  // Billing
  {
    path: '/dashboard',
    Component: lazy(() => import('views/dashboard')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/concept-descriptions',
    Component: lazy(() => import('views/concepts/descriptions')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/billing/draft/:id?',
    Component: lazy(() => import('views/billing/Eraser')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/billing/stamped/:id?',
    Component: lazy(() => import('views/billing/Stamped')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/billing/canceled',
    Component: lazy(() => import('views/billing/Canceled')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/billing/pending',
    Component: lazy(() => import('views/billing/Pending')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/billing/ppd/:id?',
    Component: lazy(() => import('views/billing/PPD')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/billing/complements/:id?',
    Component: lazy(() => import('views/billing/Complements')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/billing/ppd/:issuerID/:receiverID',
    Component: lazy(() => import('views/billing/ManyPPD')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/bank/account-statements',
    Component: lazy(() => import('views/bank/AccountStatements')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/bank/associate-movements',
    Component: lazy(() => import('views/bank/AssociateMovements')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/bank/my-accounts',
    Component: lazy(() => import('views/bank/MyAccounts')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/clients/:id?',
    Component: lazy(() => import('views/client/Clients')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/conciliaciones/abonos',
    Component: lazy(() => import('views/conciliaciones/Abonos')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/issuers',
    Component: lazy(() => import('views/issuer/Issuer')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/issuers/:id/:section',
    Component: lazy(() => import('views/issuer/Issuer/Details')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/issuer/buy-stamps/:id?',
    Component: lazy(() => import('views/issuer/Issuer/BuyStamps/index')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/bankaccounts/:type/:id',
    Component: lazy(() => import('views/catalog/BankAccounts')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/config',
    Component: () => (<Redirect to="/config/information" />),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/config/:section',
    Component: lazy(() => import('views/config')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/config/webhook/:id',
    Component: lazy(() => import('views/config/Webhooks/Logs')),
    exact: true,
    access: [...allAccess],
  },
  // {
  //   path: '/config/webhook/:id/details',
  //   Component: lazy(() => import('views/config/Webhooks/LogDetails')),
  //   exact: true,
  //   access: [...allAccess],
  // },
  {
    path: '/me',
    Component: () => (<Redirect to="/me/profile" />),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/me/:section',
    Component: lazy(() => import('views/user')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/stamps/buy',
    Component: lazy(() => import('views/issuer/Issuer/Payment')),
    exact: true,
    onlyOwner: true,
    access: [...allAccess],
  },
  {
    path: '/administrator/:section',
    Component: lazy(() => import('views/administrador')),
    exact: true,
    access: [onlyAdministrator],
  },
  {
    path: '/accounts-receivable/solicitude/:id?',
    Component: lazy(() => import('views/accounts/receivable/Solicitude')),
    exact: true,
    access: [...allAccess],
  },
  {
    path: '/accounts-receivable/billing/:id?',
    Component: lazy(() => import('views/accounts/receivable/Invoice')),
    exact: true,
    access: [...allAccess],
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/magic-login',
    Component: lazy(() => import('pages/auth/magic-login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/forgot-password/:hash',
    Component: lazy(() => import('pages/auth/change-password')),
    exact: true,
  },
  {
    path: '/auth/register/:hash?',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: error404,
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
];

const mapStateToProps = ({ settings, user }) => ({
  user: user.db,
  routerAnimation: settings.routerAnimation,
});

const Router = ({ history, routerAnimation, user }) => {
  const isOwner = useIsCurrentCompanyOwner();
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to={initialPath} />} />
                    {routes.map(({
                      path, Component, exact, access, onlyOwner,
                    }) => (
                      (
                        (!access || (access?.includes(user?.role?.name || onlyUser) && !onlyOwner))
                        || (!onlyOwner || (onlyOwner && isOwner))
                      ) && <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => (
                          <div className={routerAnimation}>
                            <Suspense fallback={null}>
                              <Component />
                            </Suspense>
                          </div>
                        )}
                      />
                    ))}
                    <Redirect to={error404} />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
