import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';

const catalogUseCFDI = new Crud('catalogueusocfdi');

export function* GET_ALL_USE_CFDI() {
  const info = yield catalogUseCFDI.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_USE_CFDI,
      payload: info,
      loading: false,
    });
  }
}

const catalogRegimenFiscal = new Crud('catalogueregimenfiscal');

export function* GET_ALL_REGIMEN_FISCAL() {
  const info = yield catalogRegimenFiscal.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_REGIMEN_FISCAL,
      payload: info,
      loading: false,
    });
  }
}

const catalogCurrency = new Crud('cataloguecurrency');

export function* GET_ALL_CURRENCY() {
  const info = yield catalogCurrency.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_CURRENCY,
      payload: info,
      loading: false,
    });
  }
}

const catalogFormaPago = new Crud('catalogueformapago');

export function* GET_ALL_FORMA_PAGO() {
  const info = yield catalogFormaPago.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_FORMA_PAGO,
      payload: info,
      loading: false,
    });
  }
}

const catalogMetodoPago = new Crud('cataloguemetodopago');

export function* GET_ALL_METODO_PAGO() {
  const info = yield catalogMetodoPago.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_METODO_PAGO,
      payload: info,
      loading: false,
    });
  }
}

const catalogTipoComprobante = new Crud('cataloguetipocomprobante');

export function* GET_ALL_TIPO_COMPROBANTE() {
  const info = yield catalogTipoComprobante.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_TIPO_COMPROBANTE,
      payload: info,
      loading: false,
    });
  }
}

const catalogImpuesto = new Crud('catalogueimpuesto');

export function* GET_ALL_IMPUESTO() {
  const info = yield catalogImpuesto.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_IMPUESTO,
      payload: info,
      loading: false,
    });
  }
}

const catalogTipoRelacion = new Crud('cataloguetiporelacion');

export function* GET_ALL_TIPO_RELACION() {
  const info = yield catalogTipoRelacion.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_TIPO_RELACION,
      payload: info,
      loading: false,
    });
  }
}

const catalogTasaOCuota = new Crud('cataloguetasaocuota');

export function* GET_ALL_TASA_CUOTA() {
  const info = yield catalogTasaOCuota.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_TASA_CUOTA,
      payload: info,
      loading: false,
    });
  }
}

const catalogueclaveunidad = new Crud('catalogueclaveunidad');

export function* GET_ALL_TIPOS_UNIDADES() {
  const info = yield catalogueclaveunidad.Get('type');
  if (info) {
    yield put({
      type: actions.GET_ALL_TIPOS_UNIDADES,
      payload: info,
      loading: false,
    });
  }
}

const cataloguebanks = new Crud('cataloguebanks');

export function* GET_ALL_BANKS() {
  const info = yield cataloguebanks.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_BANKS,
      payload: info,
      loading: false,
    });
  }
}

const cataloguemotivecanceled = new Crud('cataloguemotivecanceled');

export function* GET_ALL_MOTIVE_CANCELED() {
  const info = yield cataloguemotivecanceled.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_MOTIVE_CANCELED,
      payload: info,
      loading: false,
    });
  }
}

const catalogueexportaciones = new Crud('catalogueexportaciones');

export function* GET_ALL_EXPORTACIONES() {
  const info = yield catalogueexportaciones.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_EXPORTACIONES,
      payload: info,
      loading: false,
    });
  }
}

const catalogueobjetoimp = new Crud('catalogueobjetoimp');

export function* GET_ALL_OBJETO_IMP() {
  const info = yield catalogueobjetoimp.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_OBJETO_IMP,
      payload: info,
      loading: false,
    });
  }
}

const cataloguemeses = new Crud('cataloguemeses');

export function* GET_ALL_MESES() {
  const info = yield cataloguemeses.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_MESES,
      payload: info,
      loading: false,
    });
  }
}

const catalogueperiodicidad = new Crud('catalogueperiodicidad');

export function* GET_ALL_PERIODICIDAD() {
  const info = yield catalogueperiodicidad.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_PERIODICIDAD,
      payload: info,
      loading: false,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_USE_CFDI, GET_ALL_USE_CFDI),
    takeEvery(actions.FETCH_ALL_REGIMEN_FISCAL, GET_ALL_REGIMEN_FISCAL),
    takeEvery(actions.FETCH_ALL_CURRENCY, GET_ALL_CURRENCY),
    takeEvery(actions.FETCH_ALL_FORMA_PAGO, GET_ALL_FORMA_PAGO),
    takeEvery(actions.FETCH_ALL_METODO_PAGO, GET_ALL_METODO_PAGO),
    takeEvery(actions.FETCH_ALL_TIPO_COMPROBANTE, GET_ALL_TIPO_COMPROBANTE),
    takeEvery(actions.FETCH_ALL_IMPUESTO, GET_ALL_IMPUESTO),
    takeEvery(actions.FETCH_ALL_TIPO_RELACION, GET_ALL_TIPO_RELACION),
    takeEvery(actions.FETCH_ALL_TASA_CUOTA, GET_ALL_TASA_CUOTA),
    takeEvery(actions.FETCH_ALL_TIPOS_UNIDADES, GET_ALL_TIPOS_UNIDADES),
    takeEvery(actions.FETCH_ALL_BANKS, GET_ALL_BANKS),
    takeEvery(actions.FETCH_ALL_MOTIVE_CANCELED, GET_ALL_MOTIVE_CANCELED),
    takeEvery(actions.FETCH_ALL_EXPORTACIONES, GET_ALL_EXPORTACIONES),
    takeEvery(actions.FETCH_ALL_OBJETO_IMP, GET_ALL_OBJETO_IMP),
    takeEvery(actions.FETCH_ALL_MESES, GET_ALL_MESES),
    takeEvery(actions.FETCH_ALL_PERIODICIDAD, GET_ALL_PERIODICIDAD),
  ]);
}
