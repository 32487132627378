import _ from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import actions from './actions';

const cleanGrupos = {
  company: {},
  receiverId: {},
  receiver: {},
  issuerId: {},
  issuer: {},
  stamp: {},
};

const callbackInvoice = invoice => ({
  ...invoice,
  key: invoice.id,
  conceptos: _.get(invoice, 'conceptos.concepto', [])
    .map(({ impuestos, ...resto }) => {
      const retenciones = _.get(impuestos, 'retenciones.retencion', []);
      const traslados = _.get(impuestos, 'traslados.traslado', []);
      return {
        ...resto,
        impuestos: {
          retenciones: retenciones.map(({ key, ...rest }) => ({ ...rest, key: key || uuid() })),
          traslados,
        },
      };
    }),
  impuestos: _.get(invoice, 'impuestos.impuesto', {}),
  clientTaxId: _.get(invoice, 'receiver.taxId', ''),
  clientPostalCode: _.get(invoice, 'receiver.postalCode', ''),
  issuerTaxId: _.get(invoice, 'issuer.taxId', ''),
  fechaExpedicion: moment(_.get(invoice, 'fechaExpedicion')),
});

const callbackInvoicePago = (invoice) => {
  const { pago = {} } = invoice;
  const newInvoice = {
    ...invoice,
    id: pago?.id,
    realID: invoice?.id,
  };
  return callbackInvoice(newInvoice);
};

const setGroupInvoice = (groupInvoice, count, omit) => [...(groupInvoice.length > 1 ? [{
  type: 'group',
  title: `Grupo ${count}`,
  key: uuid(),
  id: uuid(),
  ...cleanGrupos,
  group: groupInvoice.reduce((acc, el) => [...acc, el.id], []),
}] : []), ...(groupInvoice.length > 1 ? groupInvoice.map(invoice => ({
  type: 'subgroup',
  group: groupInvoice.reduce((acc, el) => [...acc, el.id], []),
  ...invoice,
})) : groupInvoice), ...(
  !omit ? [{
    type: 'divider',
    key: uuid(),
    id: uuid(),
    ...cleanGrupos,
  }] : []
)];

const cleanInvoice = { count: 0, data: [], loading: false };

const initialState = {
  close: false,
  disabled: false,
  disabledCancel: false,
  invoices: {
    draft: cleanInvoice,
    stamp: cleanInvoice,
    cancel: cleanInvoice,
    ppd: cleanInvoice,
    complements: cleanInvoice,
  },
  invoice: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_INVOICE:
      return initialState;
    case actions.DISABLED_INVOICE:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.DISABLED_INVOICE_CANCEL:
      return {
        ...state,
        disabledCancel: action.payload,
      };
    case actions.CLOSED_INVOICE:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_INVOICES:
      return {
        ...state,
        invoices: {
          ...initialState.invoices,
          draft: {
            ...initialState.invoices.draft,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_INVOICES_STAMP:
      return {
        ...state,
        invoices: {
          ...initialState.invoices,
          stamp: {
            ...initialState.invoices.stamp,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_INVOICES_CANCEL:
      return {
        ...state,
        invoices: {
          ...initialState.invoices,
          cancel: {
            ...initialState.invoices.cancel,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_INVOICES_PPD:
      return {
        ...state,
        invoices: {
          ...initialState.invoices,
          ppd: {
            ...initialState.invoices.ppd,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_INVOICES_COMPLEMENTS:
      return {
        ...state,
        invoices: {
          ...initialState.invoices,
          complements: {
            ...initialState.invoices.complements,
            loading: action.payload,
          },
        },
      };
    case actions.LOADING_INVOICE:
      return {
        ...state,
        invoice: {
          ...initialState.invoice,
          loading: action.payload,
        },
      };
    case actions.CLEAN_INVOICES:
      return {
        ...state,
        invoices: {},
        loading: initialState.invoices,
      };
    case actions.CLEAN_INVOICE:
      return {
        ...state,
        invoice: initialState.invoice,
      };
    case actions.GET_INVOICE:
      return {
        ...state,
        invoice: {
          ...action.payload,
          data: callbackInvoice(action.payload.data || {}),
        },
      };
    case actions.GET_ALL_INVOICE:
    {
      let data = [];
      let groupInvoice = [];
      let count = 0;
      (action.payload.data || []).forEach((invoice) => {
        const {
          metodoPagoID,
          formaPagoID,
          regimenFiscalID,
          usoCfdiID,
          currencyID,
          invoiceTypeID,
          receiverId,
          issuerId,
          tipoRelacionID,
        } = invoice;
        const newInvoice = callbackInvoice(invoice);

        if (groupInvoice.length === 0) {
          groupInvoice = [{ ...newInvoice }];
        } else if (
          groupInvoice[0].metodoPagoID === metodoPagoID
          && groupInvoice[0].formaPagoID === formaPagoID
          && groupInvoice[0].regimenFiscalID === regimenFiscalID
          && groupInvoice[0].usoCfdiID === usoCfdiID
          && groupInvoice[0].currencyID === currencyID
          && groupInvoice[0].invoiceTypeID === invoiceTypeID
          && groupInvoice[0].receiverId === receiverId
          && groupInvoice[0].issuerId === issuerId
          && groupInvoice[0].tipoRelacionID === tipoRelacionID
        ) {
          groupInvoice = [...groupInvoice, { ...newInvoice }];
        } else {
          if (groupInvoice.length > 1) count += 1;
          const newData = setGroupInvoice(groupInvoice, count);
          groupInvoice = [{ ...newInvoice }];
          data = [...data, ...newData];
        }
      });
      if (groupInvoice.length > 1) count += 1;
      data = [...data, ...(setGroupInvoice(groupInvoice, count, true))];
      // console.log(data);
      return {
        ...state,
        invoices: {
          ...state.invoices,
          draft: {
            ...action.payload,
            loading: false,
            data,
          },
        },
      };
    }
    case actions.GET_ALL_INVOICE_STAMP:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          stamp: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []).map(callbackInvoice),
          },
        },
      };
    case actions.GET_ALL_INVOICE_CANCEL:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          cancel: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []).map(callbackInvoice),
          },
        },
      };
    case actions.GET_ALL_INVOICE_PPD:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          ppd: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []).map(callbackInvoice),
          },
        },
      };
    case actions.GET_ALL_INVOICE_PPD_COMPLEMENTS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          complements: {
            ...action.payload,
            loading: false,
            data: (action.payload.data || []).map(callbackInvoicePago),
          },
        },
      };
    default:
      return state;
  }
}
