import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  bankaccounts: {
    count: 0, data: [], byID: {}, loading: false,
  },
  bankaccount: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_BANK_ACCOUNT:
      return initialState;
    case actions.DISABLED_BANK_ACCOUNT:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_BANK_ACCOUNT:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_BANK_ACCOUNTS:
      return {
        ...state,
        bankaccounts: {
          ...initialState.bankaccounts,
          loading: true,
        },
      };
    case actions.LOADING_BANK_ACCOUNT:
      return {
        ...state,
        bankaccount: {
          ...initialState.bankaccount,
          loading: true,
        },
      };
    case actions.CLEAN_BANK_ACCOUNTS:
      return {
        ...state,
        bankaccounts: {},
        loading: initialState.bankaccounts,
      };
    case actions.CLEAN_BANK_ACCOUNT:
      return {
        ...state,
        bankaccount: initialState.bankaccount,
      };
    case actions.GET_BANK_ACCOUNT:
      return {
        ...state,
        bankaccount: action.payload,
      };
    case actions.GET_ALL_BANK_ACCOUNT:
      return {
        ...state,
        bankaccounts: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action?.payload?.data || []), 'id'),
          data: (action?.payload?.data || []).map(({
            receiver, issuer, ...bankaccount
          }) => ({
            ...bankaccount,
            key: bankaccount.id,
          })),
        },
      };
    default:
      return state;
  }
}
