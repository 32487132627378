import React from 'react';
import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';

import DeleteItem from '../../genericComponents/CustomAnimations/DeleteItem';

const deleteWaiting = { content: <DeleteItem text="message.delete.waiting" />, footer: null };

const apikeys = new Crud('apikey');

export function* GET_ALL_APIKEY() {
  yield put({ type: actions.LOADING_APIKEYS });
  const info = yield apikeys.Get('all');
  if (info) {
    yield put({
      type: actions.GET_ALL_APIKEY,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_APIKEY({ id }) {
  yield put({ type: actions.LOADING_APIKEY });
  const info = yield apikeys.Get(`one/${id}`);
  if (info) {
    yield put({
      type: actions.GET_APIKEY,
      payload: info,
      loading: false,
    });
  }
}

export function* CREATE_APIKEY({ data }) {
  // yield put({ type: actions.CLOSED_APIKEY, payload: false });
  yield put({ type: actions.DISABLED_APIKEY, payload: true });
  const info = yield apikeys.Post(data);
  yield put({ type: actions.DISABLED_APIKEY, payload: false });
  if (info) {
    // yield put({ type: actions.CLOSED_APIKEY, payload: true });
    yield GET_ALL_APIKEY();
  }
}

export function* UPDATE_APIKEY({ data }) {
  yield put({ type: actions.CLOSED_APIKEY, payload: false });
  yield put({ type: actions.DISABLED_APIKEY, payload: true });
  const info = yield apikeys.Put(data);
  yield put({ type: actions.DISABLED_APIKEY, payload: false });
  if (info) {
    yield put({ type: actions.CLOSED_APIKEY, payload: true });
    yield GET_ALL_APIKEY();
  }
}

export function* DELETE_APIKEY({ id, modal, ok }) {
  if (modal) modal.confirm(deleteWaiting);
  const info = yield apikeys.Delete(id);
  if (modal) modal.destroy();
  if (info) yield GET_ALL_APIKEY();
  if (ok) ok();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_APIKEY, GET_ALL_APIKEY),
    takeEvery(actions.FETCH_APIKEY, GET_APIKEY),
    takeEvery(actions.CREATE_APIKEY, CREATE_APIKEY),
    takeEvery(actions.UPDATE_APIKEY, UPDATE_APIKEY),
    takeEvery(actions.DELETE_APIKEY, DELETE_APIKEY),
  ]);
}
