import React from 'react';
import { getLocale } from '../../helpers';
import {
  allAccess,
  onlyAdministrator,
} from '../../helpers/roles';

import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as Invoice } from '../../assets/icons/invoice.svg';
import { ReactComponent as InvoiceDraft } from '../../assets/icons/invoice-draft.svg';
import { ReactComponent as InvoiceStamp } from '../../assets/icons/invoice-stamp.svg';
import { ReactComponent as InvoicePpd } from '../../assets/icons/invoice-ppd.svg';
import { ReactComponent as InvoiceCancel } from '../../assets/icons/invoice-cancel.svg';
import { ReactComponent as Clients } from '../../assets/icons/clients.svg';
import { ReactComponent as Client } from '../../assets/icons/client.svg';
import { ReactComponent as Issuers } from '../../assets/icons/issuers.svg';
import { ReactComponent as Issuer } from '../../assets/icons/issuer.svg';
import { ReactComponent as Config } from '../../assets/icons/config.svg';
import { ReactComponent as Shop } from '../../assets/icons/shop.svg';
import { ReactComponent as Admin } from '../../assets/icons/admin.svg';

const color = '#84d9c3';
const props = {
  fill: color,
  stroke: color,
  width: '17pt',
  height: '17pt',
};

export default async function getMenuData() {
  return [
    {
      category: true,
      title: getLocale('menu.category.options'),
    },
    {
      title: getLocale('menu.category.dashboard'),
      key: 'menu.category.dashboard',
      // iconFe: 'fe fe-trending-up',
      icon: (
        <Dashboard {...props} />
      ),
      url: '/dashboard',
      roles: [...allAccess],
    },
    {
      title: getLocale('concepts.descriptions'),
      key: 'concepts.descriptions',
      // iconFe: 'fe fe-trending-up',
      icon: (
        <InvoiceStamp {...props} />
      ),
      url: '/concept-descriptions',
      roles: [...allAccess],
    },
    {
      title: getLocale('menu.category.billing'),
      key: 'menu.category.billing',
      // iconFe: 'fe fe-file-text',
      icon: <Invoice {...props} />,
      roles: [...allAccess],
      children: [
        {
          title: getLocale('menu.category.billing.draft'),
          key: 'draft',
          url: '/billing/draft',
          icon: <InvoiceDraft {...props} />,
          // iconFe: 'fe fe-archive',
          roles: [...allAccess],
        },
        {
          title: getLocale('menu.category.billing.stamped'),
          key: 'stamped',
          url: '/billing/stamped',
          icon: <InvoiceStamp {...props} />,
          // iconFe: 'fe fe-check-circle',
          roles: [...allAccess],
        },
        {
          title: getLocale('menu.category.billing.ppd'),
          key: 'ppd',
          url: '/billing/ppd',
          icon: <InvoicePpd {...props} />,
          // iconFe: 'fe fe-clock',
          roles: [...allAccess],
        },
        {
          title: getLocale('menu.category.billing.complements'),
          key: 'complements',
          url: '/billing/complements',
          icon: <InvoicePpd {...props} />,
          // iconFe: 'fe fe-clock',
          roles: [...allAccess],
        },
        {
          title: getLocale('menu.category.billing.canceled'),
          key: 'canceled',
          url: '/billing/canceled',
          icon: <InvoiceCancel {...props} />,
          // iconFe: 'fe fe-x-circle',
          roles: [...allAccess],
        },
      ],
    },
    {
      title: getLocale('menu.category.accounts.receivable'),
      key: 'menu.category.accounts.receivable',
      // iconFe: 'fe fe-file-text',
      icon: <Invoice {...props} />,
      roles: [...allAccess],
      children: [
        {
          title: getLocale('menu.category.solicitude'),
          key: 'solicitude',
          url: '/accounts-receivable/solicitude',
          icon: <InvoiceDraft {...props} />,
          // iconFe: 'fe fe-archive',
          roles: [...allAccess],
        },
        {
          title: getLocale('menu.category.billing'),
          key: 'billing',
          url: '/accounts-receivable/billing',
          icon: <InvoiceDraft {...props} />,
          // iconFe: 'fe fe-archive',
          roles: [...allAccess],
        },
      ],
    },
    // {
    //   category: true,
    //   title: getLocale('menu.category.bank'),
    // },
    // {
    //   title: getLocale('menu.category.bank'),
    //   key: 'menu.category.bank',
    //   iconFe: 'fe fe-dollar-sign',
    //   children: [
    //     {
    //       title: getLocale('menu.category.bank.accountStatements'),
    //       key: 'accountStatements',
    //       url: '/bank/account-statements',
    //       iconFe: 'fe fe-file-text',
    //     },
    //     {
    //       title: getLocale('menu.category.bank.myAccounts'),
    //       key: 'myAccounts',
    //       url: '/bank/my-accounts',
    //       iconFe: 'fe fe-credit-card',
    //     },
    //     {
    //       title: getLocale('menu.category.bank.associateInvoiceMovements'),
    //       key: 'associateInvoiceMovements',
    //       url: '/bank/associate-movements',
    //       iconFe: 'fe fe-repeat',
    //     },
    //   ],
    // },
    // {
    //   category: true,
    //   title: getLocale('menu.category.clients'),
    // },
    {
      title: getLocale('menu.category.clients'),
      key: 'menu.category.clients',
      // iconFe: 'fe fe-heart',
      icon: <Clients {...props} />,
      roles: [...allAccess],
      children: [
        {
          title: getLocale('menu.category.clients.myClients'),
          key: 'myClients',
          url: '/clients',
          // iconFe: 'fe fe-users',
          icon: <Client {...props} />,
          roles: [...allAccess],
        },
      ],
    },
    {
      title: getLocale('menu.category.transmitter'),
      key: 'menu.category.transmitter',
      // iconFe: 'fe fe-navigation',
      icon: <Issuers {...props} />,
      roles: [...allAccess],
      children: [
        {
          title: getLocale('menu.category.transmitter.myIssuers'),
          key: 'myIssuers',
          url: '/issuers',
          // iconFe: 'fe fe-briefcase',
          icon: <Issuer {...props} />,
          roles: [...allAccess],
        },
      ],
    },
    {
      title: getLocale('conciliaciones.text'),
      key: 'conciliaciones.text',
      // iconFe: 'fe fe-heart',
      icon: <InvoiceDraft {...props} />,
      roles: [...allAccess],
      children: [
        {
          title: getLocale('conciliaciones.abonos.text'),
          key: 'abonos',
          url: '/conciliaciones/abonos',
          // iconFe: 'fe fe-users',
          icon: <InvoiceStamp {...props} />,
          roles: [...allAccess],
        },
      ],
    },
    {
      title: getLocale('menu.category.setting'),
      key: 'menu.category.setting',
      // iconFe: 'fe fe-settings',
      icon: <Config {...props} />,
      url: '/config/information',
      roles: [...allAccess],
    },
    {
      title: getLocale('topBar.buy.stamps'),
      key: 'topBar.buy.stamps',
      // iconFe: 'fe fe-shopping-cart',
      icon: <Shop {...props} />,
      url: '/stamps/buy',
      roles: [...allAccess],
      onlyOwner: true,
    },
    {
      title: getLocale('menu.admin.text'),
      key: 'menu.admin.text',
      // iconFe: 'fe fe-shield',
      icon: <Admin {...props} />,
      url: '/administrator/products',
      roles: [onlyAdministrator],
    },
  ];
}
