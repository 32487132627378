const actions = {
  DISABLED_COMPANY: 'company/DISABLED',
  CLOSED_COMPANY: 'company/CLOSED',
  LOADING_COMPANIES: 'company/LOADING_COMPANIES',
  LOADING_COMPANIES_ME: 'company/LOADING_COMPANIES_ME',
  LOADING_COMPANY: 'company/LOADING_COMPANY',
  CLEAN_ALL_COMPANY: 'company/CLEAN_ALL',
  CLEAN_COMPANIES: 'company/CLEAN_COMPANIES',
  CLEAN_COMPANY: 'company/CLEAN_COMPANY',
  GET_ALL_COMPANY: 'company/GET_ALL',
  FETCH_ALL_COMPANY: 'company/FETCH_ALL',
  GET_COMPANY: 'company/GET',
  FETCH_COMPANY: 'company/FETCH',
  GET_ALL_COMPANY_ME: 'company/GET_ALL_ME',
  FETCH_ALL_COMPANY_ME: 'company/FETCH_ALL_ME',
  CREATE_COMPANY: 'company/CREATE',
  UPDATE_COMPANY: 'company/UPDATE',
  DELETE_COMPANY: 'company/DELETE',
  CANCEL_COMPANY: 'company/CANCEL',
  INVITATION_COMPANY: 'company/INVITATION',
  EXIT_COMPANY: 'company/EXIT',
};

export default actions;
