const actions = {
  DISABLED_CLIENT: 'client/DISABLED',
  CLOSED_CLIENT: 'client/CLOSED',
  LOADING_CLIENTS: 'client/LOADING_CLIENTS',
  LOADING_CLIENT: 'client/LOADING_CLIENT',
  CLEAN_ALL_CLIENT: 'client/CLEAN_ALL',
  CLEAN_CLIENTS: 'client/CLEAN_CLIENTS',
  CLEAN_CLIENT: 'client/CLEAN_CLIENT',
  GET_ALL_CLIENT: 'client/GET_ALL',
  FETCH_ALL_CLIENT: 'client/FETCH_ALL',
  GET_CLIENT: 'client/GET',
  FETCH_CLIENT: 'client/FETCH',
  CREATE_CLIENT: 'client/CREATE',
  UPDATE_CLIENT: 'client/UPDATE',
  DELETE_CLIENT: 'client/DELETE',
  CREATE_CIF_CLIENT: 'client/CREATE_CIF_CLIENT',
  UPDATE_CIF_CLIENT: 'client/UPDATE_CIF_CLIENT',
  UPDATE_CONTACTS_CLIENT: 'client/UPDATE_CONTACTS_CLIENT',
};

export default actions;
