const actions = {
  GET_ALL_USE_CFDI: 'catalogs/GET_ALL_USE_CFDI',
  FETCH_ALL_USE_CFDI: 'catalogs/FETCH_ALL_USE_CFDI',
  GET_ALL_REGIMEN_FISCAL: 'catalogs/GET_ALL_REGIMEN_FISCAL',
  FETCH_ALL_REGIMEN_FISCAL: 'catalogs/FETCH_ALL_REGIMEN_FISCAL',
  GET_ALL_CURRENCY: 'catalogs/GET_ALL_CURRENCY',
  FETCH_ALL_CURRENCY: 'catalogs/FETCH_ALL_CURRENCY',
  GET_ALL_FORMA_PAGO: 'catalogs/GET_ALL_FORMA_PAGO',
  FETCH_ALL_FORMA_PAGO: 'catalogs/FETCH_ALL_FORMA_PAGO',
  GET_ALL_METODO_PAGO: 'catalogs/GET_ALL_METODO_PAGO',
  FETCH_ALL_METODO_PAGO: 'catalogs/FETCH_ALL_METODO_PAGO',
  GET_ALL_TIPO_COMPROBANTE: 'catalogs/GET_ALL_TIPO_COMPROBANTE',
  FETCH_ALL_TIPO_COMPROBANTE: 'catalogs/FETCH_ALL_TIPO_COMPROBANTE',
  GET_ALL_IMPUESTO: 'catalogs/GET_ALL_IMPUESTO',
  FETCH_ALL_IMPUESTO: 'catalogs/FETCH_ALL_IMPUESTO',
  GET_ALL_TIPO_RELACION: 'catalogs/GET_ALL_TIPO_RELACION',
  FETCH_ALL_TIPO_RELACION: 'catalogs/FETCH_ALL_TIPO_RELACION',
  GET_ALL_TASA_CUOTA: 'catalogs/GET_ALL_TASA_CUOTA',
  FETCH_ALL_TASA_CUOTA: 'catalogs/FETCH_ALL_TASA_CUOTA',
  GET_ALL_TIPOS_UNIDADES: 'catalogs/GET_ALL_TIPOS_UNIDADES',
  FETCH_ALL_TIPOS_UNIDADES: 'catalogs/FETCH_ALL_TIPOS_UNIDADES',
  GET_ALL_BANKS: 'catalogs/GET_ALL_BANKS',
  FETCH_ALL_BANKS: 'catalogs/FETCH_ALL_BANKS',
  GET_ALL_MOTIVE_CANCELED: 'catalogs/GET_ALL_MOTIVE_CANCELED',
  FETCH_ALL_MOTIVE_CANCELED: 'catalogs/FETCH_ALL_MOTIVE_CANCELED',
  GET_ALL_EXPORTACIONES: 'catalogs/GET_ALL_EXPORTACIONES',
  FETCH_ALL_EXPORTACIONES: 'catalogs/FETCH_ALL_EXPORTACIONES',
  GET_ALL_OBJETO_IMP: 'catalogs/GET_ALL_OBJETO_IMP',
  FETCH_ALL_OBJETO_IMP: 'catalogs/FETCH_ALL_OBJETO_IMP',
  GET_ALL_MESES: 'catalogs/GET_ALL_MESES',
  FETCH_ALL_MESES: 'catalogs/FETCH_ALL_MESES',
  GET_ALL_PERIODICIDAD: 'catalogs/GET_ALL_PERIODICIDAD',
  FETCH_ALL_PERIODICIDAD: 'catalogs/FETCH_ALL_PERIODICIDAD',
};

export default actions;
