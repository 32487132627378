import _ from 'lodash';
import actions from './actions';

const initialState = {
  close: false,
  disabled: false,
  paymentmethods: {
    count: 0, data: [], byID: {}, loading: false,
  },
  paymentmethod: { loading: false },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAN_ALL_PAYMENTMETHOD:
      return initialState;
    case actions.DISABLED_PAYMENTMETHOD:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.CLOSED_PAYMENTMETHOD:
      return {
        ...state,
        close: action.payload,
      };
    case actions.LOADING_PAYMENTMETHODS:
      return {
        ...state,
        paymentmethods: {
          ...initialState.paymentmethods,
          loading: true,
        },
      };
    case actions.LOADING_PAYMENTMETHOD:
      return {
        ...state,
        paymentmethod: {
          ...initialState.paymentmethod,
          loading: true,
        },
      };
    case actions.CLEAN_PAYMENTMETHODS:
      return {
        ...state,
        paymentmethods: {},
        loading: initialState.paymentmethods,
      };
    case actions.CLEAN_PAYMENTMETHOD:
      return {
        ...state,
        paymentmethod: initialState.paymentmethod,
      };
    case actions.GET_PAYMENTMETHOD:
      return {
        ...state,
        paymentmethod: action.payload,
      };
    case actions.GET_ALL_PAYMENTMETHOD:
      return {
        ...state,
        paymentmethods: {
          ...action.payload,
          loading: false,
          byID: _.keyBy((action?.payload?.data || []), 'id'),
          data: (action?.payload?.data || []).map(paymentmethod => ({
            ...paymentmethod,
            key: paymentmethod.id,
          })),
          default: _.get(_.keyBy((action?.payload?.data || []), 'principal'), 'true.id'),
        },
      };
    default:
      return state;
  }
}
