import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

import LottieControl from '../Lottie';
import lottieDeclaration from '../../assets/lottie/46710-declaration.json';

const { Title } = Typography;

const StampInvoice = ({ text }) => (
  <>
    <LottieControl data={lottieDeclaration} />
    <Title level={3} style={{ textAlign: 'center' }}>
      <FormattedMessage id={text} />
    </Title>
  </>
);

export default StampInvoice;
