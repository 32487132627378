import { all } from 'redux-saga/effects';
import user from './user/sagas';
import menu from './menu/sagas';
import settings from './settings/sagas';
import config from './config/sagas';
import issuer from './issuer/sagas';
import client from './client/sagas';
import catalogs from './catalogs/sagas';
import userDB from './userDB/sagas';
import invoice from './invoice/sagas';
import apikey from './apikey/sagas';
import company from './company/sagas';
import webhooks from './webhooks/sagas';
import bankaccounts from './bankaccounts/sagas';
import product from './product/sagas';
import paymentmethod from './paymentmethod/sagas';
import conciliaciones from './conciliaciones/sagas';
import importingsat from './importingsat/sagas';
import conceptdescriptions from './conceptdescriptions/sagas';

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    config(), // run once on app load to
    issuer(),
    client(),
    catalogs(),
    userDB(),
    invoice(),
    apikey(),
    company(),
    webhooks(),
    bankaccounts(),
    product(),
    paymentmethod(),
    conciliaciones(),
    importingsat(),
    conceptdescriptions(),
  ]);
}
