import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { notification } from 'antd';
import {
  getAuth, signInWithEmailAndPassword, signInWithCustomToken, createUserWithEmailAndPassword, signOut,
} from 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyA8N4bf_rHaR8v1Ad5Vg9h2ll0hZhJneVo',
  authDomain: 'levita-billing.firebaseapp.com',
  databaseURL: 'https://levita-billing-default-rtdb.firebaseio.com',
  projectId: 'levita-billing',
  storageBucket: 'levita-billing.appspot.com',
  messagingSenderId: '11096871061',
  appId: '1:11096871061:web:5010006a56b3767eba8b9c',
  measurementId: 'G-X70CRT43TL',
};

const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);

export const analytics = getAnalytics(app);

// export const firebaseDatabase = firebase.database()

export async function getToken() {
  try {
    const idToken = await firebaseAuth.currentUser.getIdToken(true);
    console.log(idToken);
    return idToken;
  } catch ({ message: description, code: message }) {
    notification.error({ message, description });
    return false;
  }
}

export async function login(email, password) {
  return signInWithEmailAndPassword(firebaseAuth, email, password)
    .then(() => true)
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    });
}

export async function loginWithCustomToken(token) {
  return signInWithCustomToken(firebaseAuth, token)
    .then(() => true)
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    });
}

export async function register(email, password) {
  return createUserWithEmailAndPassword(firebaseAuth, email, password)
    .then((response) => {
      if (response.user) {
        // const { uid } = response.user
        // Databasefirebase
        //   .ref('users')
        //   .child(uid)
        //   .set({
        //     role: 'admin',
        //     name,
        //   })
      }
      return true;
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    });
}

export async function currentAccount() {
  let userLoaded = false;
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser);
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        // if (user && !user.emailVerified) {
        //   user.sendEmailVerification();
        // }

        userLoaded = true;
        unsubscribe();
        const getUserData = async () => {
          if (user) {
            // console.log(user)
            // const userFieldsOld = await firebaseDatabase
            //   .ref('users')
            //   .child(user.uid)
            //   .once('value')
            //   .then(snapshot => {
            //     return snapshot.val()
            //   })
            //   .catch(console.error)
            // console.log(userFieldsOld);
            // const userFields = { name: 'Edder', role: 'admin' }
            const mergedUser = Object.assign(user, {
              id: user.uid,
              name: user.name,
              // role: user.role,
              avatar: user.photoUrl,
            });
            return mergedUser;
          }
          return user;
        };
        resolve(getUserData());
        return user;
      }, reject);
    });
  }
  return getCurrentUser(firebaseAuth);
}

export async function logout() {
  window.localStorage.removeItem('app.companyID');
  window.localStorage.removeItem('app.invoice.filter');
  return signOut(firebaseAuth).then(() => true);
}
