const actions = {
  DISABLED_WEBHOOK: 'webhook/DISABLED',
  CLOSED_WEBHOOK: 'webhook/CLOSED',
  LOADING_WEBHOOKS: 'webhook/LOADING_WEBHOOKS',
  LOADING_WEBHOOKS_LOG: 'webhook/LOADING_WEBHOOKS_LOG',
  LOADING_WEBHOOK: 'webhook/LOADING_WEBHOOK',
  LOADING_WEBHOOK_LOG: 'webhook/LOADING_WEBHOOK_LOG',
  CLEAN_ALL_WEBHOOK: 'webhook/CLEAN_ALL',
  CLEAN_WEBHOOKS: 'webhook/CLEAN_WEBHOOKS',
  CLEAN_WEBHOOK: 'webhook/CLEAN_WEBHOOK',
  GET_ALL_WEBHOOK: 'webhook/GET_ALL',
  FETCH_ALL_WEBHOOK: 'webhook/FETCH_ALL',
  GET_ALL_WEBHOOK_LOG: 'webhook/GET_ALL_LOG',
  FETCH_ALL_WEBHOOK_LOG: 'webhook/FETCH_ALL_LOG',
  GET_WEBHOOK: 'webhook/GET',
  GET_WEBHOOK_LOG: 'webhook/GET_LOG',
  FETCH_WEBHOOK: 'webhook/FETCH',
  FETCH_WEBHOOK_LOG: 'webhook/FETCH_LOG',
  CREATE_WEBHOOK: 'webhook/CREATE',
  UPDATE_WEBHOOK: 'webhook/UPDATE',
  DELETE_WEBHOOK: 'webhook/DELETE',
  STATUS_WEBHOOK: 'webhook/STATUS',
};

export default actions;
