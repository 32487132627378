import _ from 'lodash';
import actions from './actions';
import {
  catalogTipoFactor,
  getCatalogTipoFactorByID,
  catalogTipoCadena,
  getCatalogTipoCadenaByID,
  catalogFecuencia,
  getCatalogFecuencia,
  catalogStatusPago,
  getCatalogStatusPagoByID,
  catalogoPeriodType,
  getCatalogoPeriodTypeByValue,
  catalogosPeriodosOrdinariosByKey,
  catalogStatusPaymentComplement,
  getCatalogStatusPaymentComplementByID,
} from '../../helpers/catalogs';
import timezones from './timezones.json';

const timezonesNoRepeat = (timezones || []).reduce((acc, { utc }) => {
  const tmz = (utc || []).reduce((acc2, tz) => ({
    ...acc2, [tz]: tz,
  }), {});
  return { ...acc, ...tmz };
}, {});

const initialState = {
  useCFDi: { data: [], byID: {}, byCode: {} },
  regimenFiscal: {
    data: [], byID: {}, byCode: {}, byName: {},
  },
  currency: { data: [], byID: {}, byCode: {} },
  formaPago: { data: [], byID: {}, byCode: {} },
  metodoPago: { data: [], byID: {}, byCode: {} },
  tipoComprobante: { data: [], byID: {}, byCode: {} },
  impuesto: { data: [], byID: {}, byCode: {} },
  tasaOcuota: { data: [], byID: {}, byCode: {} },
  tiposUnidades: { data: [], byID: {}, byCode: {} },
  banks: { data: [], byID: {}, byCode: {} },
  motiveCanceled: { data: [], byID: {}, byCode: {} },
  exportaciones: { data: [], byID: {}, byCode: {} },
  objetoImp: { data: [], byID: {}, byCode: {} },
  meses: { data: [], byID: {}, byCode: {} },
  periodicidad: { data: [], byID: {}, byCode: {} },
  tipoRelacion: {
    data: [],
    byID: {},
    byCode: {},
    cancelada: {},
  },
  tipoFactor: {
    data: [...catalogTipoFactor],
    byID: { ...getCatalogTipoFactorByID },
  },
  tipoCadenaPago: {
    data: [...catalogTipoCadena],
    byID: { ...getCatalogTipoCadenaByID },
  },
  tipoFrecuencia: {
    data: [...catalogFecuencia],
    byID: { ...getCatalogFecuencia },
  },
  timezones: {
    data: [...timezones],
    byID: _.keyBy((timezones || []), 'value'),
    noRepeat: timezonesNoRepeat,
  },
  statusPago: {
    relationUuid: {},
    data: [...catalogStatusPago],
    byID: { ...getCatalogStatusPagoByID },
  },
  statusPaymentComplement: {
    relationUuid: {},
    data: [...catalogStatusPaymentComplement],
    byID: { ...getCatalogStatusPaymentComplementByID },
  },
  periodos: {
    data: [...catalogoPeriodType],
    byID: { ...getCatalogoPeriodTypeByValue },
    clasificacion: { ...catalogosPeriodosOrdinariosByKey },
  },
};

const getBool = campo => ['sí', 'si'].includes(typeof campo === 'string' ? campo.toLowerCase() : campo);

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_ALL_USE_CFDI:
      return {
        ...state,
        useCFDi: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          byCode: _.keyBy((action.payload.data || []), 'code'),
          data: (action.payload.data || []).map(useCFDi => ({
            ...useCFDi,
            key: useCFDi.id,
          })),
        },
      };
    case actions.GET_ALL_REGIMEN_FISCAL:
      return {
        ...state,
        regimenFiscal: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          byCode: _.keyBy((action.payload.data || []), 'code'),
          byName: (action.payload.data || []).reduce((acc, data) => (
            { ...acc, [(data.description || '').toLowerCase()]: data }
          ), {}),
          data: (action.payload.data || []).map(regimenFiscal => ({
            ...regimenFiscal,
            key: regimenFiscal.id,
          })),
        },
      };
    case actions.GET_ALL_CURRENCY:
      return {
        ...state,
        currency: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          default: _.get(_.keyBy((action.payload.data || []), 'code'), 'MXN.id'),
          data: (action.payload.data || []).map(currency => ({
            ...currency,
            key: currency.id,
          })),
        },
      };
    case actions.GET_ALL_FORMA_PAGO:
      return {
        ...state,
        formaPago: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          porDefinirID: _.get(_.keyBy((action.payload.data || []), 'code'), '99.id'),
          transferencia: _.get(_.keyBy((action.payload.data || []), 'code'), '03.id'),
          data: (action.payload.data || []).map(formaPago => ({
            ...formaPago,
            key: formaPago.id,
          })),
        },
      };
    case actions.GET_ALL_METODO_PAGO:
      return {
        ...state,
        metodoPago: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          ppdID: _.get(_.keyBy((action.payload.data || []), 'code'), 'PPD.id'),
          pueID: _.get(_.keyBy((action.payload.data || []), 'code'), 'PUE.id'),
          data: (action.payload.data || []).map(metodoPago => ({
            ...metodoPago,
            key: metodoPago.id,
          })),
        },
      };
    case actions.GET_ALL_TIPO_COMPROBANTE:
      return {
        ...state,
        tipoComprobante: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          default: _.get(_.keyBy((action.payload.data || []), 'code'), 'I.id'),
          data: (action.payload.data || []).map(tipoComprobante => ({
            ...tipoComprobante,
            key: tipoComprobante.id,
          })),
        },
      };
    case actions.GET_ALL_IMPUESTO: {
      const data = (action.payload.data || []).map(impuesto => ({
        ...impuesto,
        key: impuesto.id,
        retencion: getBool(impuesto.retencion),
        traslado: getBool(impuesto.traslado),
      }));
      return {
        ...state,
        impuesto: {
          ...actions.payload,
          data,
          byID: _.keyBy(data, 'id'),
        },
      };
    }
    case actions.GET_ALL_TIPO_RELACION: {
      const data = (action.payload.data || []).map(tipoRelacion => ({
        ...tipoRelacion,
        key: tipoRelacion.id,
      }));
      return {
        ...state,
        tipoRelacion: {
          ...actions.payload,
          data,
          byID: _.keyBy(data, 'id'),
          cancelada: data.filter(({ code }) => code === '04')[0],
        },
      };
    }
    case actions.GET_ALL_TASA_CUOTA: {
      const data = (action.payload.data || []).map(tasaOcuota => ({
        ...tasaOcuota,
        key: tasaOcuota.id,
        retencion: getBool(tasaOcuota.retencion),
        traslado: getBool(tasaOcuota.traslado),
      }));
      return {
        ...state,
        tasaOcuota: {
          ...actions.payload,
          data,
          byID: _.keyBy(data, 'id'),
        },
      };
    }
    case actions.GET_ALL_TIPOS_UNIDADES:
      return {
        ...state,
        tiposUnidades: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(tipoUnidad => ({
            ...tipoUnidad,
            key: tipoUnidad.id,
          })),
        },
      };
    case actions.GET_ALL_BANKS:
      return {
        ...state,
        banks: {
          ...actions.payload,
          byID: _.keyBy((action.payload.data || []), 'id'),
          data: (action.payload.data || []).map(bank => ({
            ...bank,
            key: bank.id,
          })),
        },
      };
    case actions.GET_ALL_MOTIVE_CANCELED:
    {
      const data = (action.payload.data || []).map(motive => ({
        ...motive,
        key: motive.id,
      }));
      return {
        ...state,
        motiveCanceled: {
          ...actions.payload,
          data,
          byID: _.keyBy((action.payload.data || []), 'id'),
          byCode: _.keyBy((action.payload.data || []), 'code'),
          relationUuid: data.filter(({ code }) => code === '01')[0],
        },
      };
    }
    case actions.GET_ALL_EXPORTACIONES:
    {
      const data = (action.payload.data || []).map(exportaciones => ({
        ...exportaciones,
        key: exportaciones.id,
      }));
      return {
        ...state,
        exportaciones: {
          ...actions.payload,
          data,
          byID: _.keyBy((action.payload.data || []), 'id'),
          byCode: _.keyBy((action.payload.data || []), 'code'),
        },
      };
    }
    case actions.GET_ALL_OBJETO_IMP:
    {
      const data = (action.payload.data || []).map(objetoImp => ({
        ...objetoImp,
        key: objetoImp.id,
      }));
      return {
        ...state,
        objetoImp: {
          ...actions.payload,
          data,
          byID: _.keyBy((action.payload.data || []), 'id'),
          byCode: _.keyBy((action.payload.data || []), 'code'),
        },
      };
    }
    case actions.GET_ALL_MESES:
    {
      const data = (action.payload.data || []).map(meses => ({
        ...meses,
        key: meses.id,
      }));
      return {
        ...state,
        meses: {
          ...actions.payload,
          data,
          byID: _.keyBy((action.payload.data || []), 'id'),
          byCode: _.keyBy((action.payload.data || []), 'code'),
        },
      };
    }
    case actions.GET_ALL_PERIODICIDAD:
    {
      const data = (action.payload.data || []).map(periodicidad => ({
        ...periodicidad,
        key: periodicidad.id,
      }));
      return {
        ...state,
        periodicidad: {
          ...actions.payload,
          data,
          byID: _.keyBy((action.payload.data || []), 'id'),
          byCode: _.keyBy((action.payload.data || []), 'code'),
        },
      };
    }
    default:
      return state;
  }
}
