import React from 'react';
import JSZip from 'jszip';
import { save } from 'save-file';
import { notification } from 'antd';
import {
  all, put, takeEvery, select,
} from 'redux-saga/effects';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../services/firebase';
import { sendLogEvent } from '../../helpers/firebase';
import actions from './actions';
import Crud from '../../services/fetch';
import { getLocale } from '../../helpers';

import StampInvoice from '../../genericComponents/CustomAnimations/StampInvoice';

const importerSat = new Crud('importer-sat');
const importerSatRequest = new Crud('importer-sat/request');

const downloadZIP = { content: <StampInvoice text="billing.generate.zip" />, footer: null };
const cloneInvoice = { content: <StampInvoice text="invoice.clone.waiting" />, footer: null };

const logEventRequest = sendLogEvent(logEvent, analytics);

export function* GET_REQUEST({ id }) {
  yield put({ type: actions.LOADING_REQUEST });
  const info = yield importerSatRequest.Get(id);
  if (info) {
    yield put({
      type: actions.GET_REQUEST,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_ALL_REQUEST({ filter }) {
  // Si existen peticiones de autoRequest no se ejecuta la función
  const importingsat = yield select(state => state?.importingsat?.requests?.autoRequest || {});
  if (Object.keys(importingsat).length > 0) {
    return;
  }

  yield put({ type: actions.LOADING_REQUESTS });
  const newFilter = new URLSearchParams(filter);
  const info = yield importerSatRequest.Get(`all?${newFilter}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_REQUEST,
      payload: info,
      loading: false,
    });
  }
}

export function* CREATE_REQUEST({ data, filter }) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  yield put({ type: actions.CLOSED_REQUEST, payload: false });
  yield put({ type: actions.DISABLED_REQUEST, payload: true });
  console.log({ data });
  const info = yield importerSatRequest.PostFD(data);
  yield put({ type: actions.DISABLED_REQUEST, payload: false });
  if (info) {
    logEventRequest('create_request_sat', {
      ...analyticEventParams,
      requestID: info.id,
    });
    yield put({ type: actions.CLOSED_REQUEST, payload: true });
    yield GET_ALL_REQUEST({ filter });
    if (info.message) notification.success({ message: info.message });
  }
}

export function* UPDATE_REQUEST({
  id, data, ok, filter, isAutoRequest,
}) {
  const user = yield select(state => state.user);
  const analyticEventParams = {
    companyID: user?.companyID,
    firebaseUserID: user?.id,
    stampiUserID: user?.db?.id,
  };

  if (!isAutoRequest) {
    yield put({ type: actions.CLOSED_REQUEST, payload: false });
    yield put({ type: actions.DISABLED_REQUEST, payload: true });
  } else {
    yield put({ type: actions.SET_AUTO_REQUEST, payload: { id, value: true } });
  }

  const info = yield importerSatRequest.PutUrlFD(id, data);

  if (!isAutoRequest) {
    yield put({ type: actions.DISABLED_REQUEST, payload: false });
  } else {
    yield put({ type: actions.SET_AUTO_REQUEST, payload: { id, value: false } });
  }

  if (info) {
    logEventRequest('update_request_sat', {
      ...analyticEventParams,
      requestID: id,
    });

    if (!isAutoRequest) yield put({ type: actions.CLOSED_REQUEST, payload: true });
    if (!isAutoRequest) yield GET_ALL_REQUEST({ filter });

    if (info.message) notification.success({ message: info.message });
  }
  // console.log({ info });
  if (ok) ok();
}

export function* GET_INVOICE_REQ({ id }) {
  yield put({ type: actions.LOADING_INVOICE_REQ });
  const info = yield importerSat.Get(id);
  if (info) {
    yield put({
      type: actions.GET_INVOICE_REQ,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_ALL_INVOICES_REQ({ filter }) {
  importerSat.Cancel();
  importerSat.SetCancelToken();
  yield put({ type: actions.LOADING_INVOICES_REQ, payload: true });
  const newFilter = new URLSearchParams(filter);
  const info = yield importerSat.Get(`all?${newFilter}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_INVOICES_REQ,
      payload: info,
      loading: false,
    });
  }
}

export function* GET_ALL_XML_SAT({
  filter, modal,
}) {
  if (modal) modal.confirm(downloadZIP);
  const newFilter = new URLSearchParams({ ...filter, omitPagination: true });
  const info = yield importerSat.Get(`all?${newFilter}`);
  if (Array.isArray(info?.data) && info?.data?.length > 0) {
    const zip = new JSZip();
    const folder = zip.folder();
    (info?.data || []).forEach((inv) => {
      const {
        issuerRfc, receiverRfc, xml, uuid,
      } = inv;
      const fileFolder = `${issuerRfc}/${receiverRfc}`;
      folder.file(`${fileFolder}/${uuid}.xml`, xml);
    });
    const generate = yield zip.generateAsync({ type: 'blob' });
    save(generate, 'sat.zip');
  } else {
    notification.warn({ message: getLocale('zip.message.error') });
  }
  if (modal) modal.destroy();
}

export function* CLONE_INVOICE_FROM_SAT({
  id, modal, ok, to,
}) {
  if (modal) modal.confirm(cloneInvoice);
  const info = yield importerSat.PostUrl(`clone/${id}`);
  if (modal) modal.destroy();
  if (ok) ok();
  if (info) {
    if (to) {
      notification.success({ message: info?.data?.message });
      to(info?.data?.id);
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_REQUEST, GET_REQUEST),
    takeEvery(actions.FETCH_ALL_REQUEST, GET_ALL_REQUEST),
    takeEvery(actions.CREATE_REQUEST, CREATE_REQUEST),
    takeEvery(actions.UPDATE_REQUEST, UPDATE_REQUEST),
    takeEvery(actions.FETCH_INVOICE_REQ, GET_INVOICE_REQ),
    takeEvery(actions.FETCH_ALL_INVOICES_REQ, GET_ALL_INVOICES_REQ),
    takeEvery(actions.GET_ALL_XML_SAT, GET_ALL_XML_SAT),
    takeEvery(actions.CLONE_INVOICE_FROM_SAT, CLONE_INVOICE_FROM_SAT),
  ]);
}
