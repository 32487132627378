import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

import LottieControl from '../Lottie';
import lottieDelete from '../../assets/lottie/60039-send-mail-and-delivered.json';

const { Title } = Typography;

const DeleteItem = ({ text }) => (
  <>
    <LottieControl data={lottieDelete} />
    <Title level={3} style={{ textAlign: 'center' }}>
      <FormattedMessage id={text} />
    </Title>
  </>
);

export default DeleteItem;
