import user from './user/actions';
import menu from './menu/actions';
import settings from './settings/actions';
import config from './config/actions';
import issuer from './issuer/actions';
import client from './client/actions';
import catalogs from './catalogs/actions';
import userDB from './userDB/actions';
import invoice from './invoice/actions';
import apikey from './apikey/actions';
import company from './company/actions';
import webhooks from './webhooks/actions';
import bankaccounts from './bankaccounts/actions';
import product from './product/actions';
import paymentmethod from './paymentmethod/actions';
import conciliaciones from './conciliaciones/actions';
import importingsat from './importingsat/actions';
import conceptdescriptions from './conceptdescriptions/actions';

export default {
  ...user,
  ...menu,
  ...settings,
  ...config,
  ...issuer,
  ...client,
  ...catalogs,
  ...userDB,
  ...invoice,
  ...apikey,
  ...company,
  ...webhooks,
  ...bankaccounts,
  ...product,
  ...paymentmethod,
  ...conciliaciones,
  ...importingsat,
  ...conceptdescriptions,
};
