const actions = {
  DISABLED_CONCILIACION: 'conciliaciones/DISABLED',
  CLOSED_CONCILIACION: 'conciliaciones/CLOSED',
  LOADING_CONCILIACIONES: 'conciliaciones/LOADING_CONCILIACIONES',
  LOADING_CONCILIACION: 'conciliaciones/LOADING_CONCILIACION',
  CLEAN_ALL_CONCILIACIONES: 'conciliaciones/CLEAN_ALL',
  CLEAN_CONCILIACIONES: 'conciliaciones/CLEAN_CONCILIACIONES',
  CLEAN_CONCILIACION: 'conciliaciones/CLEAN_CONCILIACION',
  GET_ALL_CONCILIACIONES: 'conciliaciones/GET_ALL',
  FETCH_ALL_CONCILIACIONES: 'conciliaciones/FETCH_ALL',
  GET_CONCILIACION: 'conciliaciones/GET',
  FETCH_CONCILIACION: 'conciliaciones/FETCH',
  UPDATE_CONCILIACION: 'conciliaciones/UPDATE',
  DELETE_CONCILIACION: 'conciliaciones/DELETE',
  ADD_CONCILIACION_CARGO: 'conciliaciones/ADD_CONCILIACION_CARGO',
  CONCILIACION_UPDATE_PUES: 'conciliaciones/CONCILIACION_UPDATE_PUES',
};

export default actions;
